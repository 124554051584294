import { Component, OnInit } from '@angular/core';
import * as echarts from 'echarts';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/themes/theme.service';

@Component({
  selector: 'app-salesexecutivedashboard',
  templateUrl: './salesexecutivedashboard.component.html',
  styleUrls: ['./salesexecutivedashboard.component.scss']
})
export class SalesexecutivedashboardComponent implements OnInit {
  userType = "SALES";
  poType;
  userId;
  buyerDashboardInfo:any=[];
  allorders:any;
  arrivingtoday;
  shipmentsmissedduedate;
  shipmentsintransit;
  posnotacknowledeged;
  allinwardspo;
  isDarkTheme;
  tobenotified;
  graphColor
  constructor(private themeService: ThemeService,private adminService: AdminService, private authservice: AuthService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.poType = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).userRoledcorg
    this.userId = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email;
    this.loadWidgetsData();
  }
  loadWidgetsData() {
    const dis = this;
    this.spinner.show()
    this.adminService.getSalesDashboardWidgets(this.poType, this.userId, this.userType).subscribe(resp => {
    this.spinner.hide();
      if (resp['status'] == "Fail") {
        return;
      }
      // this.buyerDashboardInfo.push(resp)
      this.arrivingtoday=resp['shipmentsArrivingTodayCount'];
            this.shipmentsmissedduedate=resp['shipmentsMissedDueDateCount']
            this.shipmentsintransit=resp['shipmentsInTransitCount']
            this.tobenotified=resp['shipmentsToBeNotifiedCount']
            this.posnotacknowledeged=resp['posNotAcknowledgedCount']
            this.allinwardspo=resp['allInwardPOsCount']
            this.allorders=resp['allOrdersCount']
      let data=[
        ['score', 'amount', 'product'],
        [ this.allorders,  this.allorders, 'All Orders'],
        [ this.allinwardspo,  this.allinwardspo, 'All Inwards PO s'],
        [ this.posnotacknowledeged,  this.posnotacknowledeged, 'POs not acknowledged'],
        [this.tobenotified, this.tobenotified, 'To be Notified'],
        [this.shipmentsintransit, this.shipmentsintransit, 'Shipments In-Transit'],
        [ this.shipmentsmissedduedate,  this.shipmentsmissedduedate, 'Shipments missed Due Date'],
        [this.arrivingtoday, this.arrivingtoday, 'Arriving Today'],
        
        
      ]
      this.buyerDashboardInfo = data
      
      document.getElementById('main').style.display = 'block';
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;
      this.themeService.themeChanged.subscribe((res: any) => {
        this.isDarkTheme = res;
        if(this.isDarkTheme !== 'light'){
          this.graphColor ='#ddd'
          option = {
            dataset: {
              source:
                this.buyerDashboardInfo
            },
            // grid: { containLabel: true },
            grid: { containLabel: true, show: false, left: 10, top: 10, right: "10%", },
            xAxis: { name: '%' },
            yAxis: {
              type: 'category',
            },
            
            color: [this.graphColor],
            // visualMap: {
             
            //   inRange: {
            //     color: ['#65B581']
            //   }
            // },
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                type: 'bar',
                encode: {
                  x: 'amount',
                  y: 'product'
                }
              }
            ]
          };
          option && myChart.setOption(option);
         
        }else if(this.isDarkTheme === 'light'){
          this.graphColor = '#0E5197'
          option = {
            dataset: {
              source:
                this.buyerDashboardInfo
            },
            // grid: { containLabel: true },
            grid: { containLabel: true, show: false, left: 10, top: 10, right: "10%", },
            xAxis: { name: '%' },
            yAxis: {
              type: 'category',
            },
            
            color: [this.graphColor],
            // visualMap: {
             
            //   inRange: {
            //     color: ['#65B581']
            //   }
            // },
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                type: 'bar',
                encode: {
                  x: 'amount',
                  y: 'product'
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      })
    });

  }

}
