<div class="container">
    <div class="d-flex justify-content-center align-self-center vh">
        <img class="bg bg-image" src="assets/images/BG-RW1.jpg" alt="background">
        <div class="card ps">
            <div class="d-flex">
                <div class="col-md-6" style="padding: 0px 0px 0px 50px;display:flex">
                    <div class="text-center imageCenter">
                        <img src="assets/images/SCC-lOGO.png" class="card-img rounded img-fluid mt-4"
                            alt="img">
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card-body">
                        <div class="float-left font-weight-bold cf3"> &nbsp; &nbsp; &nbsp; &nbsp; Please login to your
                            account</div>
                        <form #login="ngForm" autocomplete="off" class="text-center mt-4"
                            (ngSubmit)="submit(login)">
                            <div class="form-group mt-4">
                                <input type="text" name="email" placeholder="User Name" class="col-md-10 mt-4 pdz"
                                    #email="ngModel" [(ngModel)]="model.user" required (change)="inputChange($event)">
                                &nbsp; &nbsp;

                                <div class="text-danger " *ngIf="email.invalid &&(login.submitted)">
                                    <small class="font-weight-bold float-left ml-5 "> Please Enter User Name</small>
                                </div>
                            </div>

                            <div class="form-group mt-4">
                                <input type="password" name="confirmP" class="col-md-10 pdz" value=""
                                    placeholder="Password" #confirmP="ngModel" [(ngModel)]="model.password" required>
                                &nbsp; &nbsp;
                                <!-- <div id="popover-content" style="display: none;" class="mh">
                                    <ul>
                                        <li>Atleast 6 characters and not more than 30 characters</li>
                                        <li>Atleast one upper case character (A-Z)</li>
                                        <li>Atleast one lower case character (a-z)</li>
                                        <li>Atleast one number (0-9)</li>
                                        <li>Atleast one special character out of these ( !, @, #, $, ^, &, *, ~ )</li>
                                    </ul>
                                </div> -->
                                <div class="text-danger " *ngIf="confirmP.invalid &&(login.submitted)">
                                    <small class="font-weight-bold float-left ml-5"> Please Enter Password</small>
                                </div>
                                <div class="text-danger " *ngIf="confirmP.errors?.pattern">
                                    <small class="font-weight-bold float-left ml-5">Please Enter Valid Password &nbsp;
                                        &nbsp;

                                    </small>
                                </div>
                            </div>

                            
                            <div class="form-group mt-4 setWidth">
                            <ng-multiselect-dropdown [placeholder]="'Select Organisation'" class= "multiselect" name="selectOrg" #org="ngModel"
                                [(ngModel)]="orgDetails" [settings]="dropdownSettings" [data]="orgData"
                                (onSelect)="onOrganisationSelect($event)"
                                (onDeSelect)="onOrganisationDeSelect($event)"
                                 required>
                                 <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->

                            </ng-multiselect-dropdown>
                            <!-- <small class="text-danger"
                                *ngIf="org.invalid && (org.touched || login.submitted)">Please

                                Select Organisation</small> -->
                                <div class="text-danger " *ngIf="org.invalid &&(login.submitted)">
                                    <small class="font-weight-bold float-left ml-3 ">Please Select Organisation</small>
                                </div>
                            </div>
                            <div class="form-group mt-4 setWidth">
                            <ng-multiselect-dropdown [placeholder]="'Select User Type'" class= "multiselect" name="selectUsertype" #usertype="ngModel"
                            [(ngModel)]="userTypeDetails" [settings]="dropdownSettingsUserType" [data]="userTypeData"
                            (onSelect)="onUserTypeSelect($event)"
                            (onDeSelect)="onUserTypeDeSelect($event)"
                             required>
                             <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->

                        </ng-multiselect-dropdown>
                                <div class="text-danger " *ngIf="usertype.invalid &&(login.submitted)">
                                    <small class="font-weight-bold float-left ml-3">Please Select User Type</small>
                                </div>
                        </div>
                            
                            <div class="row mt-4 ">
                                <div class="sml">
                                    <button type="submit" class="btn btn-dark  mt-4 submit "> LOGIN</button>
                                    <button type="button" class="btn btn-dark  mt-4 reset bgr"
                                    (click)="reset()">RESET</button>
                                </div>
                               
                            </div>

                        </form>

                    </div>
                </div>

            </div>
            <div class="d-flex" style="position: relative;top:90px">
                <div class="col text-center">
                    <a href="https://www.realvariable.com/" target="_blank" style="font-weight: bold;">Powered by <img src="assets/images/RV-Logo_New-Blk.png" style="width: 10%;" ></a>
                </div>
            </div>
        </div>
        
    </div>
   
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale" [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>