import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/themes/theme.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent implements OnInit,ICellRendererAngularComp {
  params;
  label: string;
  label1: string;
  isDarkTheme;
  user: any;
  orgName;
  constructor(private authService:AuthService,private spinner:NgxSpinnerService,private service:AdminService,private themeService:ThemeService) { }
  refresh(params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    // this.label = this.params.label || null;
    // this.label1 = this.params.label1 || null;
    // throw new Error('Method not implemented.');
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }
  onAccept($event){
    console.log($event,"event",this.params,"params Accept")
if (this.isDarkTheme === 'light') {
  Swal.fire({
    title: 'Are you sure?',
    text: "You Want to accept?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'ACCEPT',
    cancelButtonText: 'CANCEL',
  }).then((result) => {
    if (result.isConfirmed) {
      this.spinner.show();
      this.service.MHSalesAcceptReject('true',this.user.email,this.user.sitecode,this.orgName,this.params.data.poRefNumber).subscribe((res:any)=>{
      this.spinner.hide();
        if(res.status === 'Success'){
          Swal.fire({
            title:'Success',
            html:'<strong>'+res.data.message+'</strong></br><b>TRXID:</b>'+res.data.transactionID,
            icon:'success'
          })
        }
        else{
          Swal.fire({
            title:'Error',
            text:res.data.message,
            icon:'error'
          })
        }
      })
    } else {

    }
  })
} else if (this.isDarkTheme === 'dark') {
  Swal.fire({
    title: 'Are you sure?',
    text: "You Want to Accept?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'ACCEPT',
    cancelButtonText: 'CANCEL',
  }).then((result) => {
    if (result.isConfirmed) {
      this.spinner.show();
      this.service.MHSalesAcceptReject('true',this.user.email,this.user.sitecode,this.orgName,this.params.data.poRefNumber).subscribe((res:any)=>{
        this.spinner.hide();
        if(res.status === 'Success'){
          Swal.fire({
            title:'Success',
            html:'<strong>'+res.data.message+'</strong></br><b>TRXID:</b>'+res.data.transactionID,
            icon:'success'
          })
        }
        else{
          Swal.fire({
            title:'Error',
            text:res.data.message,
            icon:'error'
          })
        }
      })
    } else {

    }
  })
  }}
  onReject($event){
    console.log($event,"event",this.params,"params Reject")
    if (this.isDarkTheme === 'light') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You Want to Reject?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'REJECT',
        cancelButtonText: 'CANCEL',
      }).then((result) => {
        if (result.isConfirmed) {
          this.spinner.show()
          this.service.MHSalesAcceptReject('false',this.user.email,this.user.sitecode,this.orgName,this.params.data.poRefNumber).subscribe((res:any)=>{
            this.spinner.hide();
            if(res.status === 'Success'){
              Swal.fire({
                title:'Success',
                html:'<strong>'+res.data.message+'</strong></br><b>TRXID:</b>'+res.data.transactionID,
                icon:'success'
              })
            }
            else{
              Swal.fire({
                title:'Error',
                text:res.message,
                icon:'error'
              })
            }
          })
        } else {
    
        }
      })
    } else if (this.isDarkTheme === 'dark') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You Want to Reject?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'REJECT',
        cancelButtonText: 'CANCEL',
      }).then((result) => {
        if (result.isConfirmed) {
          this.spinner.show()
          this.service.MHSalesAcceptReject('true',this.user.email,this.user.sitecode,this.orgName,this.params.data.poRefNumber).subscribe((res:any)=>{
            this.spinner.hide();
            if(res.status === 'Success'){
              Swal.fire({
                title:'Success',
                html:'<strong>'+res.data.message+'</strong></br><b>TRXID:</b>'+res.data.transactionID,
                icon:'success'
              })
            }
            else{
              Swal.fire({
                title:'Error',
                text:res.message,
                icon:'error'
              })
            }
          })
        } else {
    
        }
      })
    }
  }

  ngOnInit(): void {
    this.user = this.authService.getLoginDetails();
    this.orgName = this.user.userRoledcorg
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;
    });
  }

}
