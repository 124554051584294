
    <div class="page_heading d-flex">
        <div class="warranty col">
            <h3>SUMMARY</h3>
        </div>
        <div class="warranty col text-right">
            <strong>Organization Type: <span class="badge badge-info"
                    [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'"> {{orgType}}</span></strong> &nbsp;&nbsp;
            <strong>Logged User: <span class="badge badge-info" [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'">
                    {{loggedUser}}</span></strong> &nbsp;&nbsp;
        
        </div>
    </div>
    <div class="col mt-4">
      <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-dark reset" (click)="back()"><span
                  class="fa fa-arrow-circle-left"></span> BACK</button>
      </div>
  </div>
    <div class="mt-4 mb-4 container">
        <div>
            <ag-grid-angular #agGrid id="myGrid"
                [ngClass]="isDarkTheme==='light' ? 'ag-theme-balham': 'ag-theme-balham-dark'" [rowData]="rowData"
                domLayout="autoHeight" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true"
                [animateRows]="true" [rowSelection]="rowSelection" [enableRangeSelection]="true" [sideBar]="sideBar"
                [masterDetail]="true" [paginationPageSize]="paginationPageSize"
                [detailCellRendererParams]="detailCellRendererParams" [getRowHeight]="getRowHeight">
            </ag-grid-angular>
        </div>
    </div>


    <div id="myNav" class="overlay">
        <a href="javascript:void(0)" class="closebtn" id="closebtn" (click)="closemodal()">&times;</a>
        <div class="overlay-content" id="content">
      
          <div class="materialdata">
            <div class="side-heading">
              <div class="d-flex">
                  <h4>Shipment Details</h4>
              </div>
          </div>
            <div>
              <div class="mt-4" *ngIf="shippingData.length>0">
                <ag-grid-angular #agGrid id="myGrid"
                  [ngClass]="isDarkTheme==='light' ? 'ag-theme-balham': 'ag-theme-balham-dark'" [columnDefs]="columnDefs1"
                  [defaultColDef]="defaultColDef1" [masterDetail]="true" [rowData]="shippingData" [pagination]="pagination"
                  [paginationPageSize]="paginationPageSize" [rowHeight]="rowHeight" [autoGroupColumnDef]="autoGroupColumnDef"
                  [enableRangeSelection]="true" [enableCharts]="true" [chartThemeOverrides]="chartThemeOverrides"
                  [domLayout]="domLayout" [rowSelection]="rowSelection">
                </ag-grid-angular>
              </div>
            </div>
            <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
              <div class="btn-group mr-2" role="group" aria-label="First group">
                <button type="button" class="btn mt-4 close-btn" (click)="closemodal()">CLOSE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="myNav1" class="overlay">
        <a href="javascript:void(0)" class="closebtn" id="closebtn" (click)="closemodal1()">&times;</a>
        <div class="overlay-content" id="content">
    
            <div class="materialdata">
                <div class="side-heading">
                    <div class="d-flex">
                        <h4>ORDER DETAILS</h4>
                    </div>
                </div>
                <div class="modal-body">
                    <ul class="timeline">
    
    
                        <li>
    
                            <i class="fa fa-dot-circle-o bg-blue"></i>
                            <div class="timeline-item">
    
                                Order Generated: {{modaldata.podate}}
                            </div>
                        </li>
                        <div> &nbsp;</div>
                        <ng-container >
                            <li>
    
                                <i class="fa fa-dot-circle-o bg-blue"></i>
    
                                <div class="timeline-item">
    
                                    Order accepted: {{modaldata.poaccepteddate}}</div>
    
                            </li>
                            <div> &nbsp;</div>
    
                            <li>
    
                                <i class="fa fa-dot-circle-o bg-blue"></i>
                                <div class="timeline-item">
                                    Sales order Number: {{modaldata.salesorderno}}</div>
                            </li>
                            <div> &nbsp;</div>
                            <li>
    
                                <i class="fa fa-dot-circle-o bg-blue"></i>
                                <div class="timeline-item">
                                    ASN Details:
    
                                    <div>
                                        <ag-grid-angular #agGrid id="myGrid"
                                            [ngClass]="isDarkTheme==='light' ? 'ag-theme-balham': 'ag-theme-balham-dark'" [rowData]="store"
                                            domLayout="autoHeight" [columnDefs]="columnDefs2" [defaultColDef]="defaultColDef" [pagination]="true"
                                            [animateRows]="true" [rowSelection]="rowSelection" [enableRangeSelection]="true" [sideBar]="sideBar"
                                            [masterDetail]="true" [paginationPageSize]="paginationPageSize"
                                            [detailCellRendererParams]="detailCellRendererParams" [getRowHeight]="getRowHeight">
                                        </ag-grid-angular>
                                    </div>
    
    
                                </div>
                            </li>
                            <div> &nbsp;</div>
                        </ng-container>
                        
                        <li>
      
                            <i class="fa fa-circle-o"></i>
                            <div class="timeline-item">
                              
                
                
                
                
                
                
                            </div>
                          </li>
    
    
                    </ul>
    
    
                </div>
                <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div class="btn-group mr-2" role="group" aria-label="First group">
                        <button type="button" class="btn mt-4 close-btn" (click)="closemodal1()">CLOSE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale" [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>