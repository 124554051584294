<div class="page_heading d-flex">
    <div class="warranty col">
        <h3>PO UPLOAD</h3>
    </div>
    <div class="warranty col text-right">
        <strong>Organization Type: <span class="badge badge-info"
                [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'"> {{orgType}}</span></strong> &nbsp;&nbsp;
        <strong>Logged User: <span class="badge badge-info" [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'">
                {{loggedUser}}</span></strong> &nbsp;&nbsp;

    </div>
</div>
<div class="mt-4 mb-4 container">
    <!-- <div class="side-heading">
    <div class="d-flex">
        <h4>PO UPLOAD</h4>
    </div>
</div> -->
    <div class="row">
        <div class="mt-4">
            <div class="col-sm-10">
                <a href="assets/images/PoUploadFileFormat.xlsx" download="SamplePOFileFormat.xlsx" class="btn download"
                    role="button"><img src="../../../assets/images/download-solid.svg" style="width: 20px;" alt="excel">
                    Download Sample Template</a>
            </div>
            <div class=" input-group input-file pt-3" style="padding-left: 15px;">
                <input type="file" class="form-control" style="padding:3px"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.xls,.xlsx"
                    placeholder='Choose a file...' (change)="onChange($event)" name="fileUpload"
                    #invoiceFullPath="ngModel" [(ngModel)]="model.fileUpload" #fileupload
                    (click)="fileupload.value = null" value="" />
                <span class="input-group-btn">
                    <button (click)="reset()" class="btn btn-dark  ml-2 reset" type="button">RESET</button>
                </span>
                <span class="input-group-btn">
                    <button (click)="onUploadFile()" class="btn btn-dark  ml-2 submit" type="button">SUBMIT</button>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="isSubmit">
        <div class="mt-5 side-heading">
            <div class="d-flex">
                <h4>PO UPLOAD LOG</h4>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12 row container justify-content-start">
                <div class="col-md-2 justify-content-center">
                    <button type="button" class="btn btn-primary">No of Po's Uploaded <span
                            class="badge">{{total_po}}</span></button>
                </div>
                <div class="col-md-2 justify-content-center"> <button type="button" class="btn btn-success">No of
                        Updated Po's <span class="badge">{{updated_po}}</span></button>
                </div>
                <div class="col-md-2 justify-content-center"> <button type="button" class="btn btn-danger">NO of Failure
                        Po's <span class="badge">{{failed_po}}</span></button>
                </div>
            </div>
        </div>

        <div class="mt-4">
            <ag-grid-angular #agGrid id="myGrid"
                [ngClass]="isDarkTheme==='light' ? 'ag-theme-balham': 'ag-theme-balham-dark'" [rowData]="rowData"
                domLayout="autoHeight" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true"
                (gridReady)="onGridReady($event)" [animateRows]="true" [rowSelection]="rowSelection"
                [enableRangeSelection]="true" [sideBar]="sideBar" [paginationPageSize]="paginationPageSize">
            </ag-grid-angular>
        </div>
    </div>
</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale" [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>