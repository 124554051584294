import { Component, OnInit } from '@angular/core';
import * as echarts from 'echarts';
import {  NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/themes/theme.service';
@Component({
  selector: 'app-buyerdashboard',
  templateUrl: './buyerdashboard.component.html',
  styleUrls: ['./buyerdashboard.component.scss']
})
export class BuyerdashboardComponent implements OnInit {
  userType = "BUYER";
  poType;
  userId;
  buyerDashboardInfo:any=[];
  allorders:any;
  shipmentsarrivingtoday;
  tobenotified;
  inTransit;
  Missingdates;
  posNotactioned;
  isDarkTheme;
  graphColor
  constructor(private themeService: ThemeService,private adminService: AdminService, private authservice: AuthService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.poType = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).userRoledcorg
    this.userId = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email;
    this.loadWidgetsData();
  }
  loadWidgetsData() {
    const dis = this;
    this.spinner.show()
    this.adminService.getBuyerDashboardWidgets(this.poType, this.userId, this.userType).subscribe(resp => {
    this.spinner.hide();
      if (resp['status'] == "Fail") {
        return;
      }
      // this.buyerDashboardInfo.push(resp)
      console.log(this.buyerDashboardInfo,"dashboard")
      this.allorders = resp['allOrdersCount'];
      this.shipmentsarrivingtoday = resp['shipmentsArrivingTodayCount']
      this.tobenotified = resp['shipmentsToBeNotifiedCount']
      this.inTransit = resp['shipmentsInTransitCount']
      this.Missingdates = resp['shipmentsMissedDueDateCount']
      let data=[
        ['score', 'amount', 'product'],
        [ this.Missingdates,  this.Missingdates, 'Missing Due Date'],
        [this.inTransit, this.inTransit, 'Shipments In Transit'],
        [this.tobenotified, this.tobenotified, 'To Be Notified'],
        [ this.shipmentsarrivingtoday,  this.shipmentsarrivingtoday, 'Shipments Arriving Today'],
        [this.allorders, this.allorders, 'All Orders'],
        
        
      ]
      this.buyerDashboardInfo = data
      
      document.getElementById('main').style.display = 'block';
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;
      this.themeService.themeChanged.subscribe((res: any) => {
        this.isDarkTheme = res;
        if(this.isDarkTheme !== 'light'){
          this.graphColor ='#ddd'
          option = {
            dataset: {
              source:
                this.buyerDashboardInfo
            },
            // grid: { containLabel: true },
            grid: { containLabel: true, show: false, left: 10, top: 10, right: "10%", },
            xAxis: { name: '%' },
            yAxis: {
              type: 'category',
            },
            
            color: [this.graphColor],
            // visualMap: {
             
            //   inRange: {
            //     color: ['#65B581']
            //   }
            // },
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                type: 'bar',
                encode: {
                  x: 'amount',
                  y: 'product'
                }
              }
            ]
          };
          option && myChart.setOption(option);
         
        }else if(this.isDarkTheme === 'light'){
          this.graphColor = '#0E5197'
          option = {
            dataset: {
              source:
                this.buyerDashboardInfo
            },
            // grid: { containLabel: true },
            grid: { containLabel: true, show: false, left: 10, top: 10, right: "10%", },
            xAxis: { name: '%' },
            yAxis: {
              type: 'category',
            },
            
            color: [this.graphColor],
            // visualMap: {
             
            //   inRange: {
            //     color: ['#65B581']
            //   }
            // },
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                type: 'bar',
                encode: {
                  x: 'amount',
                  y: 'product'
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      })
    });

  }
}
