<div class="container">
    <div class="row justify-content-center mt-4">
        <div class="col">
            <div class="row">
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                            <div class="col">
                                <span>SHIPMENTS</span>
                                <h2>DUE TODAY</h2>
                            </div>
                            
                        </div>
                       
                        <div class="row dashboard-count-info">
                            <div class="  col">
                                <h1 style="font-size: 35px; font-weight: bold;">
                                    {{duetoday}}
                                </h1>
                            </div>

                        </div>
                        <a routerLink="dealer-info" [queryParams]="{'status': 'duetoday'}" class="small-box-footer" *ngIf="duetoday != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                           <div class="col">
                            <span>SHIPMENTS</span>
                            <h2>MISSING DUE DATE</h2>
                           </div>
                           
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                    {{shipmentsmissingduedate}}
                                </h1>
                            </div>

                        </div>
                        <a routerLink="dealer-info" [queryParams]="{'status': 'shipmentsmissingduedate'}" class="small-box-footer"  *ngIf="shipmentsmissingduedate != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                           <div class="col">
                            <span>SHIPMENTS</span>
                            <h2>DUE IN NEXT 7 DAYS</h2>
                           </div>
                            
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                   {{dueinnextsevendays}}
                                </h1>
                            </div>

                        </div>
                        <a routerLink="dealer-info" [queryParams]="{'status': 'dueinnextsevendays'}" class="small-box-footer" *ngIf="dueinnextsevendays != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>

            </div>
            <div class="row mt-3">
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                            <div class="col">
                                <span>ALL</span>
                                <h2>INWARDS PO</h2>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info  col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                    {{allinwardspo}}
                                </h1>
                            </div>

                        </div>
                        <a routerLink="dealer-info" [queryParams]="{'status': 'allinwardspo'}" class="small-box-footer" *ngIf="allinwardspo != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                            <div class="col">
                                <span>SHIPMENTS</span>
                                <h2>IN TRANSIT</h2>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info  col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                   {{intransit}}
                                </h1>
                            </div>
 
                        </div>
                        <a routerLink="dealer-info" [queryParams]="{'status': 'intransit'}" class="small-box-footer" *ngIf="intransit != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                            <div class="col">
                                <span>ALL</span>
                                <h2>SHIPMENTS</h2>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info  col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                   {{allshipments}}
                                </h1>
                            </div>
 
                        </div>
                        <a routerLink="dealer-info" [queryParams]="{'status': 'allshipments'}" class="small-box-footer" *ngIf="allshipments != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
            </div>
            <div class="mt-4  order_performance">
               <div class="d-flex">
                   <h3 class="order">ORDER PERFORMANCE</h3>
               </div>
               <div class="col">
                <div id="main" style="width: auto;height: 300px;" class="mt-3" ></div>
               </div>
            </div>
        </div>
    </div>
</div> 
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale"
    [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>
