<nav class="navbar navbar-expand-lg navbar-light" [ngClass]="ischecked === 'light' ? 'header2':'header'">
  <h5 class="my-0 font-weight-normal p-3 mr-3 p-md-0">
    <div>
      <img style="width: 160px" src="assets/images/scc-1.png" alt="logo" />
    </div>
  </h5>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <ng-container *appViewIfRolesIn="['RSEBUYER']">
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="" value="Dashboard" (click)="activateTab1('Dashboard')">
            DASHBOARD
          </a>

        </li>
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="import-po" value="ImportPo" (click)="activateTab1('ImportPo')">
            IMPORT PO
          </a>

        </li>
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="consolidatePo" value="ConsolidatePo" (click)="activateTab1('ConsolidatePo')">
            CONSOLIDATE PO
          </a>

        </li>
      </ng-container>
      <ng-container *appViewIfRolesIn="['MHSALES']">
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="" value="Dashboard" (click)="activateTab1('Dashboard')">
            DASHBOARD
          </a>

        </li>
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="mh-sales-notification" value="Notifications" (click)="activateTab1('Notifications')">
            NOTIFICATIONS
          </a>

        </li>

      </ng-container>
      <ng-container *appViewIfRolesIn="['CONTRACTORMANFSALES','SUPPLIERSALES']">
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="" value="Dashboard" (click)="activateTab1('Dashboard')">
            DASHBOARD
          </a>

        </li>
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="mh-sales-notification" value="Notifications" (click)="activateTab1('Notifications')">
            NOTIFICATIONS
          </a>

        </li>

      </ng-container>

      <ng-container *appViewIfRolesIn="['MHBUYER']">

        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="" value="Dashboard" (click)="activateTab1('Dashboard')">
            DASHBOARD
          </a>

        </li>
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="mh-create-po" value="Notifications" (click)="activateTab1('Notifications')">
            CREATE-PO
          </a>

        </li>

        <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
            true}">
            <a routerLink="notifications">
              <i class="fa fa-book"></i> <span>Notifications</span>
          
            </a>
          </li> -->

      </ng-container>

      <ng-container *appViewIfRolesIn="['CONTRACTORMANFBUYER']">

        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="" value="Dashboard" (click)="activateTab1('Dashboard')">
            DASHBOARD
          </a>

        </li>
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="cm-create-po" value="Notifications" (click)="activateTab1('Notifications')">
            CREATE-PO
          </a>

        </li>

        <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
            true}">
            <a routerLink="notifications">
              <i class="fa fa-book"></i> <span>Notifications</span>
          
            </a>
          </li> -->

      </ng-container>

      <ng-container *appViewIfRolesIn="['SUPPLIERFULL']">
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="" value="Dashboard" (click)="activateTab1('Dashboard')">
            DASHBOARD
          </a>

        </li>
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="create-asn" value="Notifications" (click)="activateTab1('Notifications')">
            CREATE SHIPMENT NOTICE
          </a>

        </li>

      </ng-container>
      <ng-container *appViewIfRolesIn="['CONTRACTORMANFFULL']">
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="" value="Dashboard" (click)="activateTab1('Dashboard')">
            DASHBOARD
          </a>

        </li>
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="asn-cm" value="Notifications" (click)="activateTab1('Notifications')">
            CREATE SHIPMENT NOTICE
          </a>

        </li>

      </ng-container>
      <ng-container *appViewIfRolesIn="['RSEADMIN','DEALERADMIN']">
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="" value="Dashboard" (click)="activateTab1('Dashboard')">
            HOME
          </a>

        </li>
        <!-- <li class="nav-item" >
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="mh-sales-notification" value="Notifications" (click)="activateTab1('Notifications')" >
           REGISTER SITE
          </a>
  
        </li> -->

      </ng-container>
      <ng-container *appViewIfRolesIn="['CONTRACTORMANFADMIN']">
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="cm-admin" value="Dashboard" (click)="activateTab1('Dashboard')">
            HOME
          </a>

        </li>

      </ng-container>
      <ng-container *appViewIfRolesIn="['MANFACTURINGHUBADMIN']">
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="" value="Dashboard" (click)="activateTab1('Dashboard')">
            HOME
          </a>

        </li>
      </ng-container>
      <ng-container *appViewIfRolesIn="['SUPPLIERADMIN']">
        <li class="nav-item">
          <a class="nav-link tabColor1 fontSize cursorDefault text-uppercase" routerLinkActive='active'
            [routerLinkActiveOptions]="{exact:
            true}" routerLink="" value="Dashboard" (click)="activateTab1('Dashboard')">
            HOME
          </a>

        </li>
      </ng-container>
    </ul>
    <ul class="navbar-nav ">
      <li class="nav-item tabmode">
        <a class="nav-link " href="#" value="Boq" routerLinkActive="active">
          <span>{{tabmode}}</span>
          <label class="switch">
            <div [ngClass]="ischecked === 'light' ? 'hlf1':'hlf2'" id="hlf">
              <input type="checkbox" (change)="toggle($event)" />
              <span class="slider round"></span>
            </div>
          </label>
        </a>
        
      </li>

      <li class="nav-item tabmode">
        
        <a class="dropdown mr-1 nav-link" href="#" routerLinkActive="active" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

          <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="tabmode"><span style="cursor: pointer;"><em class="fa fa-user-circle-o" aria-hidden="true"
                  style="font-size: 25px; color: #fff;"> </em> 
                  <!-- <span style="color: #fff; font-size: 12px !important;font-weight: bold;display: table-caption;padding: 0px 10px;">{{loggedUSer}}</span> -->
                </span>
                 

            </div>
          </div>
          <div class="dropdown-menu dropdown-menu-width dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <div class="row">
              <div class="col-md-2">
                <button class="btn-xl btn-rounded2 btn-secondary btn ml-2">
                  <em class="fa fa-user" aria-hidden="true" *ngIf="!firstLetter"></em>
                  {{firstLetter}}
                </button>
              </div>
              <div class="col-md-10 pl-4 mt-2" style="font-size: 12px;">
                {{loggedUserName}}
                <p class="h6">{{userDetails}}</p>
              </div>
            </div>

            <div class="dropdown-divider"></div>
            <div class="row dropdown-menu-width p-2 changepassword">
              <div class="col-md-2"><em class="fa fa-key ml-2 " aria-hidden="true"></em>
              </div>
              <div class="col-md-10" style="font-size: 12px;">

                <a routerLink="/change-password">Change Password</a>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <div class="row dropdown-menu-width p-2 logout" (click)="Logout()" style="cursor: pointer;">
              <div class="col-md-2"><em class="fa fa-sign-out ml-2 " aria-hidden="true"></em>
              </div>
              <div class="col-md-10 logoutdiv" style="font-size: 12px;">
                Logout
              </div>
            </div>
          </div>
        </a>
        
      </li>
      
    </ul>

  </div>
</nav>