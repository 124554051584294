
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ThemeService } from 'src/app/themes/theme.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationStart, Router } from "@angular/router";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  ischecked;
  userDetails: any;
  loggedUserName: string = 'Logged in User';
  logoPath: string;
  tabmode: any;
  navtab: any
  permissions: any = [];
  firstLetter: string;
  imagePath
  loggedUSer
  constructor(private authService : AuthService, private router:Router,private themeService: ThemeService, ) { }

  ngOnInit(): void {
  //  this.imagePath = 
    this.tabmode = 'Light Mode'
    this.ischecked = 'dark'
    sessionStorage.setItem('tabmode', this.tabmode)
    this.navtab = 'active'
    let account: any;
    let x = this.authService.getLoginDetails()
    this.userDetails = x.name
this.loggedUSer = JSON.parse(sessionStorage.getItem('_UserLoggedDatascc')).email
  }
  
  activateTab1(tabName){
    console.log(tabName,"tabName")
  }
  Logout(){
    this.router.navigate(['/login'])
    this.authService.logout()
  }
  toggle(event: any) {
    const active = this.themeService.getActiveTheme();
    if (active.name === 'light') {
      this.themeService.setTheme('dark');
      this.themeService.setTheme2('dark');
      this.tabmode = 'Dark Mode';
      document.body.style.backgroundColor = "#1d1d1d";
      this.ischecked = active.name;
    } else {
      this.themeService.setTheme('light');
      this.themeService.setTheme2('light');
      this.tabmode = 'Light Mode';
      document.body.style.backgroundColor = "#f6f6f6";
      let ele = document.querySelector('#hlf');
      this.ischecked = active.name;
    }
  }
}
