<div class="page_heading d-flex">
    <div class="warranty col">
        <h3>CREATE SHIPMENT NOTICE</h3>
    </div>
    <div class="warranty col text-right">
        <strong>Organization Type: <span class="badge badge-info"
                [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'"> {{orgType}}</span></strong> &nbsp;&nbsp;
        <strong>Logged User: <span class="badge badge-info" [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'">
                {{loggedUser}}</span></strong> &nbsp;&nbsp;
    
    </div>
</div>

<div class="container row mt-4 justify-content-start" style="margin-left:2px">
    <div class="col-md-2 justify-content-center">
        <button type="button" class="btn btn-primary">Shipments Due Today</button>
    </div>
    <div class="col-md-2 justify-content-center"> <button type="button" class="btn btn-success"
            (click)="onPastDue()">Shipments Past Due</button>
    </div>
    <div class="col-md-2 justify-content-center"> <button type="button" class="btn btn-danger">Shipments in 7
            days</button>
    </div>
</div>
<div *ngIf="showData" class="container mb-4">
    <div class="mt-4">
        <ag-grid-angular #agGrid id="myGrid"
            [ngClass]="isDarkTheme==='light' ? 'ag-theme-balham': 'ag-theme-balham-dark'" [rowData]="rowData"
            domLayout="autoHeight" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true"
            (gridReady)="onGridReady($event)" [animateRows]="true" [rowSelection]="rowSelection"
            [enableRangeSelection]="true" [sideBar]="sideBar" [paginationPageSize]="paginationPageSize"
            [rowMultiSelectWithClick]="true" [suppressRowClickSelection]="true">
            <!-- [suppressRowClickSelection]="true" -->
        </ag-grid-angular>
    </div>
    <div class="mt-4">
        <form #asncreate="ngForm" id="asn" (ngSubmit)="onSubmit(asncreate)">
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label for="" >Port of Arrival</label>
                        <ng-multiselect-dropdown [placeholder]="Select" class="multiselect" name="port_of_arrival"
                            #poa="ngModel" [(ngModel)]="selectedPortArrival" [settings]="portArrivalSettings"
                            [data]="portArrivalData" (onSelect)="onPortOfArrivalSelect($event)" [ngClass]="isDarkTheme === 'light' ? 'multiselect select1':'multiselect1 select2'" required>
                            <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->
                        </ng-multiselect-dropdown>

                        <div class="text-danger " *ngIf="poa.invalid &&(asncreate.submitted)">
                            <small class=" float-left">Please Select Port of Arrival</small>
                        </div>
                    </div>
                </div>

                <div class="col-3">
                    <div class="form-group">
                        <label for="" >Port of Departure</label>
                        <ng-multiselect-dropdown [placeholder]="Select" class="multiselect" name="port_of_departure"
                            #pod="ngModel" [(ngModel)]="selectedPortDepature" [settings]="portDepartureSettings"
                            [data]="portDepartureDropdown" (onSelect)="onPortOfDepartureSelect($event)" [ngClass]="isDarkTheme === 'light' ? 'multiselect select1':'multiselect1 select2'" required>
                            <!--  -->
                        </ng-multiselect-dropdown>

                        <div class="text-danger " *ngIf="pod.invalid &&(asncreate.submitted)">
                            <small class=" float-left">Please Select Port of Departure</small>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                        <label for="" class="float-left">Lead Time (days)</label>
                        <input type="number" class="form-control" name="leading_days" [(ngModel)]="leadingDays" placeholder="Lead Time (days)"
                            #ld="ngModel" disabled>

                        <!-- <div class="text-danger " *ngIf="ld.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter
                                    Quantity</small>
                            </div> -->
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                        <label for="" class="float-left">Shipments Date</label>
                        <input type="date" class="form-control" name="shipments_date" [(ngModel)]="shipmentsDate"
                            #date="ngModel" required>

                        <div class="text-danger " *ngIf="date.invalid &&(asncreate.submitted)">
                            <small class="float-left">Please Enter Shipments Date</small>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                        <label for="" class="float-left">ASN #</label>
                        <input type="text" class="form-control" name="asn_number" [(ngModel)]="asnNumber" #asn="ngModel"
                            required>

                        <div class="text-danger " *ngIf="asn.invalid &&(asncreate.submitted)">
                            <small class="float-left">Please Enter ASN #</small>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                        <label for="" class="float-left">Document Date</label>
                        <input type="text" class="form-control"
                                name="document_date" 
                                [(ngModel)]="documentDate" #date="ngModel" disabled>
                        <!--<div class="text-danger " *ngIf="date.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Shipments Date</small>
                            </div> -->
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                        <label for="" class="float-left">Packing Slip #</label>
                        <input type="text" class="form-control" name="ps_number" [(ngModel)]="psNumber" #psn="ngModel"
                            required>

                        <div class="text-danger " *ngIf="psn.invalid &&(asncreate.submitted)">
                            <small class="float-left">Please Enter Packing Slip #</small>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                        <label for="" class="float-left">Bill of Lading #</label>
                        <input type="text" class="form-control" name="bol_number" [(ngModel)]="bolNumber" #bol="ngModel"
                            required>

                        <div class="text-danger " *ngIf="bol.invalid &&(asncreate.submitted)">
                            <small class="float-left">Please Enter Bill of Lading #</small>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                        <label for="" class="float-left">Airway Bill #</label>
                        <input type="text" class="form-control" name="abnumber" [(ngModel)]="abNumber" #abn="ngModel"
                            required>

                        <div class="text-danger " *ngIf="abn.invalid &&(asncreate.submitted)">
                            <small class="float-left">Please Enter Airway Bill #</small>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <label for=""class="float-left">Attach Bill of Lading</label>
                    <div class=" input-group input-file">
                        <input type="file" class="form-control" style="padding:3px"
                                            accept=".pdf,.docx,.doc"
                                            placeholder='Choose a file...' (change)="onChange($event)" name="fileUpload"
                                            #invoiceFullPath="ngModel" [(ngModel)]="model.fileUpload" #fileupload (click)="fileupload.value = null" value="" required/>
                                            <!-- <span class="input-group-btn">
                                                <button (click)="reset()" class="btn btn-dark  ml-2 reset"
                                                    type="button">RESET</button>
                                            </span>, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.xls,.xlsx
                                        <span class="input-group-btn">
                                            <button (click)="onUploadFile()" class="btn btn-dark  ml-2 submit"
                                                type="button">SUBMIT</button>
                                        </span> -->
                    </div>
                </div>
            </div>
            <div>
                <div class="mt-4 side-heading">
                    <div class="d-flex">
                        <!--d-flex-->
                        <h4>LOGISTICS</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" >Logistics Name</label>
                            <ng-multiselect-dropdown [placeholder]="Select" class="multiselect" name="logistics_name"
                                #lsn="ngModel" [(ngModel)]="selectedLogisticsName" [settings]="logisticsSettings"
                                [data]="logisticsNameDropdown" (onSelect)="onLogisticsNameSelect($event)" [ngClass]="isDarkTheme === 'light' ? 'multiselect select1':'multiselect1 select2'" required>
                                <!--  -->
                            </ng-multiselect-dropdown>

                            <div class="text-danger " *ngIf="lsn.invalid &&(asncreate.submitted)">
                                <small class=" float-left">Please Select Logistics Name</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="float-left">Request Pick-up Date</label>
                            <input type="date" class="form-control" name="requestPickupdate1" [(ngModel)]="requestPickupdate1"
                                #reqPD="ngModel" required>

                            <div class="text-danger " *ngIf="reqPD.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Request Pick-up Date</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="float-left">Request Lead Time (days)</label>
                            <input type="text" class="form-control" name="req_lead_days" [(ngModel)]="reqLeadDays"
                                #rld="ngModel" required>

                            <div class="text-danger " *ngIf="rld.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Request Lead Time (days)</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="mt-4 side-heading">
                    <div class="d-flex">
                        <!--d-flex-->
                        <h4>Third Party Warehouse</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" >Third party Warehouse</label>
                            <ng-multiselect-dropdown [placeholder]="Select" class="multiselect" name="tpw_name"
                                #tpw="ngModel" [(ngModel)]="tpmName" [settings]="thirdPartySettings"
                                [data]="tpwdata"  [ngClass]="isDarkTheme === 'light' ? 'multiselect select1':'multiselect1 select2'" required>
                                <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->
                            </ng-multiselect-dropdown>

                            <div class="text-danger " *ngIf="lsn.invalid &&(asncreate.submitted)">
                                <small class=" float-left">Please Select Logistics Name</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="float-left">Estimated Arrival</label>
                            <input type="date" class="form-control" name="estimatedarrival" [(ngModel)]="model.estimatedarrival"
                                #reqPD="ngModel" required>

                            <div class="text-danger " *ngIf="reqPD.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Estimated Arrival Date</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="float-left">Request Storage days</label>
                            <input type="text" class="form-control" name="reqStorageDays" [(ngModel)]="model.reqStorageDays"
                                #rld1="ngModel" required>

                            <div class="text-danger " *ngIf="rld1.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Storage days</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="float-left">Request Release Date</label>
                            <input type="date" class="form-control" name="requestPickDate" [(ngModel)]="model.requestPickDate"
                                #reqPD1="ngModel" required>

                            <div class="text-danger " *ngIf="reqPD1.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Request Release Date</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="" class="float-left">Request Storage volume</label>
                            <input type="text" class="form-control" name="reqStorageVolume" [(ngModel)]="model.reqStorageVolume"
                                #rld11="ngModel" required>

                            <div class="text-danger " *ngIf="rld11.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Request Storage volume</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    <div class="justify-content-center">
        <button type="submit" class="btn btn-dark  mt-4 submit" [disabled]="asncreate.invalid">CONFIRM SHIPMENT NOTICE</button>
    </div>
    </form>
</div>

</div>
<div id="myNav" class="overlay">
    <a href="javascript:void(0)" class="closebtn" id="closebtn" (click)="closemodal()">&times;</a>
    <div class="overlay-content" id="content">

        <div class="materialdata">
            <div class="side-heading">
                <div class="d-flex">
                    <h4>PACKAGE INFO DETAILS</h4>
                </div>
            </div>
            <div class="text-left mt-3">
                <div class="modal-title"><span>Add Package-Info - </span></div>
                <!-- {{item_name}} -->
                <form #addInfo="ngForm" (ngSubmit)="submit(addInfo)">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="">Height</label>
                                <input type="text" class="form-control" placeholder="Enter Height"
                                name="Height" [(ngModel)]="model1.height" placeholder="Height"
                                #Height="ngModel" required>
                                

                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="">Select</label>
                                <ng-multiselect-dropdown [placeholder]="'Select'"  name="MHeight" [(ngModel)]="model3.height"   #MHeight="ngModel"
                                    [settings]="selectedSettings" [data]="selectedData"
                                    [ngClass]="isDarkTheme === 'light' ? 'multiselect select1':'multiselect1 select2'" required>
                                </ng-multiselect-dropdown>
                               
                            </div>

                        </div>
                        


                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="">Width</label>
                                <input type="text" class="form-control" placeholder="Enter Width"
                                name="Width" [(ngModel)]="model1.width" 
                                #Width="ngModel" required>
                                

                            </div>
                        </div>
                        <!-- <div class="col">
                            <div class="form-group">
                                <label for="">Select</label>
                                <ng-multiselect-dropdown [placeholder]="'Select'"  name="MHeight" [(ngModel)]="model3.height"   #MHeight="ngModel"
                                    [settings]="selectedSettings" [data]="selectedData"
                                    
                                    [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" required>
                                </ng-multiselect-dropdown>
                               
                            </div>

                        </div> -->
                        
                        <div class="col">
                            <div class="form-group">
                                <label for="">Select</label>
                                <ng-multiselect-dropdown [placeholder]="'Select'" name="MHeight"
                                    [(ngModel)]="model3.height"   #MHeight="ngModel"
                                    [settings]="selectedSettings" [data]="selectedData"
                                    (onSelect)="onItemSelect1($event)"
                                    (onDeSelect)="onItemDeSelect1($event)"
                                    [disabled]="disabled"
                                    [ngClass]="isDarkTheme === 'light' ? 'multiselect select1':'multiselect1 select2'" required>
                                </ng-multiselect-dropdown>
                                

                            </div>

                        </div>

                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="">Length</label>
                                <input type="text" class="form-control" name="Length" [(ngModel)]="model1.length" placeholder="Length"
                                #Length="ngModel" required>
                                

                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="">Select</label>
                                <ng-multiselect-dropdown [placeholder]="'Select'"  name="MHeight" [(ngModel)]="model3.height"   #MHeight="ngModel"
                                    [settings]="selectedSettings" [data]="selectedData"
                                    
                                    [ngClass]="isDarkTheme === 'light' ? 'multiselect select1':'multiselect1 select2'" required>
                                </ng-multiselect-dropdown>
                               
                            </div>

                        </div>
                        


                    </div>
                    <div class="row">
                       
                        <div class="col">
                            <div class="form-group">
                                <label for="">Certificate Type</label>
                                <ng-multiselect-dropdown [placeholder]="'Select'"  name="toOrgID" [(ngModel)]="model.certificateType" #toOrgID="ngModel"
                                    [settings]="CertificateTypeDropdown" [data]="CertificateType"
                                    [ngClass]="isDarkTheme === 'light' ? 'multiselect select1':'multiselect1 select2'" required>
                                </ng-multiselect-dropdown>
                               
                            </div>

                        </div>
                        
                        <div class="col">
                            <div class="form-group">
                                <label for="">Certificate Authority</label>
                                <ng-multiselect-dropdown [placeholder]="'Select'"  name="toOrgID" [(ngModel)]="model.certificateAuthority" #toOrgID="ngModel"
                                    [settings]="CertificateAuthorityDropDown" [data]="CertificateAuthority"
                                    
                                    [ngClass]="isDarkTheme === 'light' ? 'multiselect select1':'multiselect1 select2'" required>
                                </ng-multiselect-dropdown>
                               
                            </div>

                        </div>
                        

                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                          <label>Issue Date</label>
                       
                          <input type="date"  name="issuedate1" [(ngModel)]="issuedate"
                          #issuedate1="ngModel" class="form-control" id="txtDate"  required>
                       
                      </div>
                      <div class="col-sm-6">
                        <label>Uplaod Certificate</label>
                        <div class=" input-group input-file invoiceUploadRow">
                          <input  type="file" class="form-control"  (change)="onuploadcertificate($event)"  name="uploadcertificate1" [(ngModel)]="uploadcertificate"
                          #uploadcertificate1="ngModel" accept=".pdf,.docx,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.xls,.xlsx"
                            placeholder='Choose a file...'  required/>
                  
                  
                  
                        </div>
                    </div>
                       
                      </div>
                      <!-- <div class="row">
                        <div class="form-group">
                          <label for="loadingno" class="col-sm-2 control-label">Generate</label>
                          <div class="col-sm-6">
                  <input type="radio"  name="qcode" value="qcode" 
                  [(ngModel)]="mode">Qr Code<br>
                        
                         
                          
                          </div>
                       
                        </div>
                       </div> -->
                       <div class="d-flex mt-4">
                        <label for="selectedBy">Generate:</label>
                        <div class="form-group col">
                            
                            <div class="form-check form-check-inline">
                                <label class="radio-inline">
                                    <input type="radio"name="qcode" value="qcode" 
                                    [(ngModel)]="mode">Qr Code
                                </label>
                            </div>
            
            
            
                        </div>
                    </div>
                    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        <div class="btn-group mr-2" role="group" aria-label="First group">
                            <button class="btn btn-dark mt-4 submit" type="submit"
                            >SUBMIT</button>
                        </div>
                        <div class="btn-group mr-2" role="group" aria-label="First group">
                            <button type="button" class="btn btn-dark  mt-4 close-btn" (click)="closemodal()">CLOSE</button>
                        </div>
        
        
                    </div>
                </form>
            </div>
            <div>
                
            </div>
           
        </div>
    </div>
</div>
<div id="imgNav" class="overlay">
    <a href="javascript:void(0)" class="closebtn" id="closebtn" (click)="closemodal2()">&times;</a>
    <div class="overlay-content" id="content">
        <div class="container">
            <img class="center" style="height: 250px;width: 250px;" src = "{{imgPath}}" alt="QRCode" >
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale"
    [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>