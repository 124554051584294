import { Theme } from './symbols';

export const lightTheme: Theme = {
    name: 'light',
    properties: {
        '--background': '#f6f7f9',
        '--on-background': '#6c757d',
        '--primary': 'white',
        '--on-primary': '#000',
        '--border': '#ddd',
        '--color': '#0E5197',
        '--success': '#0085d3',
        '--light': 'rgb(247 247 247)',
        '--lightgray': '#0085d3',
        '--grey': '#adada',
        '--lightgrey': '#0085d3',
        '--bg-color': 'white',
        // '--dashboard': '#28284c',
        '--dashboard': '#f1f1f1',
        '--dashboard-color': '#262626',
        '--count-color': '#3a3a7a',
        '--footer': '#343a40',
        '--footer-border': 'none',
        '--on-border': '#28284d',
        '--text-color': 'black',
        '--drop-down': 'white',
        '--wa-background': 'rgba(0, 0, 0, 0.03)',
        '--info-color': '#74767a',
        '--input-border': '#ced4da',
        '--button-text-color': '#fff',
        '--change-button': '#0E5197',
        '--excel-color': '#1D1D1D',
        '--tab-color': '#fff',
        '--button-background': '#0E5197',
        '--timeline-item-background':'#fff',
        '--timeline-item-color':'black',
        
        
    }
};
