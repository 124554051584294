import { AuthService } from './../services/auth.service';
import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { Role } from '../enums/role.enum';

@Directive({
  selector: '[appViewIfRolesIn]'
})
export class ViewIfRolesInDirective {

  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef, private authService: AuthService) { }

  @Input() set appViewIfRolesIn(roleStrs: any) {
    if (typeof roleStrs === 'undefined') {
      this.viewContainer.clear();
      return;
    }
    if (roleStrs.length === 0) {
      this.viewContainer.clear();
      return;
    }
    const isLoggedIn = this.authService.getLoginDetails();
    if (!isLoggedIn) {
      this.viewContainer.clear();
      return;
    }
    const roles: Role[] = [];
    roleStrs.forEach(val => roles.push(Role[val]));
    const role = this.authService.getRole();
    console.log(role,"roles")
    if (roles.indexOf(role) === -1) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
