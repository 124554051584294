import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ConsolidatepoService } from 'src/app/services/consolidatepo.service';
import { ThemeService } from 'src/app/themes/theme.service';
import { SplitShipments } from 'src/app/classes/model-shipments'
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-cm-buyer-create-po-preview',
  templateUrl: './cm-buyer-create-po-preview.component.html',
  styleUrls: ['./cm-buyer-create-po-preview.component.scss']
})
export class CmBuyerCreatePoPreviewComponent implements OnInit {
  @ViewChild('shipmentsSplit') myForm: NgForm;
  selectedpo: any = []
  selectedNeedByDate: any
  LoggedUserDetails: any;
  columnDefs
  defaultColDef;
  columnDefs1;
  pagination = true;
  paginationPageSize;
  rowData: any = [];
  shippingData: any = [];
  poType;
  userId;
  status1;
  domLayout: any;
  rowSelection;
  autoGroupColumnDef;
  maxBlocksInCache;
  sideBar;
  userType = "BUYER";
  isDarkTheme;
  detailCellRendererParams;
  getRowHeight;
  gridApi: any;
  poDetails: any = [];
  modelShipments: any[];
  // modelShipments=[]
  shipments: any[]
  deliverysitesettings = {}
  selectedDeliverySiteCode = []
  raiseConsolidate = []
  raiseConsolidateSettings = {}
  raiseConsolidateData = []
  siteCode = []
  siteCodeSettings = {}
  siteCodeData = []
  poNumber: any;
  respShipments = []
  respSplitShipments = []
  // currentDate : Date =new Date();
  selectedNeedByDateShow: any;
  popupdata: any;
  popupdatalineno: any;
  selectedItemName: any;
  poConsolidateDetails: any[];
  loggedUser;
  orgType
  poNum;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService, private consolidatePOService: ConsolidatepoService, private themeService: ThemeService, private toastr: ToastrService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.hide()
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;
    });
   
    this.orgType = sessionStorage.getItem("orgName")
    if(JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'cm1-buyer@rvbl.com'){
      this.loggedUser = 'TIER1 SUPPLIER1 BUYER'
    }
    // this.selectedNeedByDateShow=moment(this.selectedNeedByDate).format('DD-MM-YYYY')
    // this.currentDate=this.selectedNeedByDate
    this.raiseConsolidateSettings = {
      singleSelection: true,
      idField: 'ParticipantID',
      textField: 'ParticipantName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    };
    this.deliverysitesettings = {
      singleSelection: true,
      idField: 'SiteCode',
      textField: 'SiteName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    };
    this.siteCodeSettings = {
      singleSelection: true,
      idField: 'siteCode',
      textField: 'siteName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    };
    this.LoggedUserDetails = this.authService.getLoginDetails()
    let routeData = this.activatedRoute.snapshot.queryParams
    // console.log(routeData)
    this.selectedpo = JSON.parse(routeData.consolidate)
    this.selectedNeedByDate = routeData.needbydate
    this.selectedItemName = routeData.itemName
    
    console.log(this.selectedpo, "spo")
    // console.log(this.selectedpo,this.selectedNeedByDate,this.selectedItemName,"ssssssss")
    if (this.selectedNeedByDate !== undefined && this.selectedNeedByDate !== null) {
      this.selectedNeedByDateShow = moment(routeData.needbydate,'DD-MM-YYYY').subtract(3, 'days').format('DD-MM-YYYY')
      // console.log(this.selectedNeedByDate)
      this.getPODataByDate()
      // this.getPoConsolidationDetails(this.selectedpo)
    }
    else {
      this.getPODataByItemName(this.selectedpo)
      // this.getPoConsolidationDetails(this.selectedpo)
    }

    this.modelShipments = [{
      lineNo: '',
      componentName: '',
      uom: '',
      quantity: '',
      needbydate: '',
      deliverysite: '',
      serialNum: 0,
    }]
    this.getDeliverySites()
    // this.consolidatepos(this.selectedpo,this.selectedNeedByDate)
    this.raiseConsolidatePO()

    // this.showPOs()
  }


  drop(e: any) {

    console.log(e)
    alert(e)
  }
  getPoConsolidationDetails(poDetails: any) {
    this.poConsolidateDetails = []
    console.log(poDetails,this.selectedpo, "po1")
    // for (let i = 0; i < this.selectedpo.length; i++) {
      let x = this.poDetails.filter((x: any) => x.shipmenRefNumber === this.selectedpo + '-1-0')
      console.log(x,"x data")
      this.poConsolidateDetails.push(x[0])
    // }
    // this.poConsolidateDetails.push()
    console.log(this.poConsolidateDetails, "poconsoldetails")
  }
  getDeliverySites() {
    let obj = {
      "orgName": this.LoggedUserDetails.userRoledcorg
    }
    this.spinner.show()
    this.consolidatePOService.getDeliverySites(obj).subscribe((resp: any) => {
      this.spinner.hide()
      if (resp.status === 'Success') {
        this.selectedDeliverySiteCode = resp.data
        console.log(this.selectedDeliverySiteCode)
      }
      else {

        if (this.isDarkTheme === 'light') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            text: resp.message,
            width: 500
          });
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({

            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + resp.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',




          });
        }
      }
    }, (err) => {
      this.spinner.hide()

      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        });
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + err.error.errorMessage + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',




        });
      }
    })
  }
  getPODataByItemName(po) {
    let obj = {
      orgName: this.LoggedUserDetails.userRoledcorg,
      itemName: this.selectedItemName,
      needByDate: ''
    }
    this.spinner.show()
    this.consolidatePOService.cmPoList(obj).subscribe((resp: any) => {
      this.spinner.hide()
      if (resp.status === 'Success') {
        this.poDetails = resp.data
        this.poDetails.filter(item=>{
          console.log(po,"po")
          if(item.poNumber === po){
            this.selectedNeedByDateShow = moment(item.needByDate, "DD-MM-YYYY").subtract(3, 'days').format('DD-MM-YYYY')
          }
        })
        
        this.getPoConsolidationDetails(this.poDetails)
      } else {

        if (this.isDarkTheme === 'light') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            text: resp.message,
            width: 500
          });
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({

            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + resp.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',




          });
        }
      }
    }, (err) => {
      this.spinner.hide()

      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        });
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + err.error.errorMessage + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',




        });
      }
    })
  }
  getPODataByDate() {
    let obj = {
      orgName: this.LoggedUserDetails.userRoledcorg,
      itemName: '',
      needByDate: moment(this.selectedNeedByDate).format("DD-MM-YYYY")
    }
    this.spinner.show()
    this.consolidatePOService.cmPoList(obj).subscribe((resp: any) => {
      this.spinner.hide()
      if (resp.status === 'Success') {
        this.poDetails = resp.data
        this.getPoConsolidationDetails(this.poDetails)
      } else {

        if (this.isDarkTheme === 'light') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            text: resp.message,
            width: 500
          });
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({

            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + resp.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',




          });
        }
      }
    }, (err) => {
      this.spinner.hide()

      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        });
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + err.error.errorMessage + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',




        });
      }
    })
  }
  closemodal() {
    document.getElementById('myNav').style.width = '0';
    // let form= document.getElementById('splitShipments') as HTMLFormElement
    // form.reset()
    // this.modelShipments=[]
    console.log(this.poDetails, "close po")
  }

  closemodalF(form: any) {
    document.getElementById('myNav').style.width = '0';
    // let form= document.getElementById('splitShipments') as HTMLFormElement
    // form.reset()
    // this.modelShipments=[]
    console.log(this.poDetails, "close po")
    this.myForm.form.get('quantity').clearValidators();
    this.myForm.form.get('needbydate').clearValidators();
    this.myForm.form.get('deliverysite').clearValidators();
  }
  // consolidatepos(selectedpos,needbydate){
  //   this.spinner.show()
  //     let obj={
  //     buyerID:this.LoggedUserDetails.email,
  //     buyerName: this.LoggedUserDetails.name,
  //     buyerOrg: this.LoggedUserDetails.userRoledcorg,
  //     needByDate:moment(needbydate).format('DD-MM-YYYY'),
  //     poSite: this.LoggedUserDetails.sitecode,
  //     poreferences: selectedpos
  //   }
  //  this.consolidatePOService.validateConsolidatePO(obj).subscribe((resp:any)=>{
  //   this.spinner.hide()
  //   if(resp.status==='Success'){
  //     console.log("success")
  //     this.poDetails=[]
  //     this.poDetails.push(resp.message)
  //     console.log(this.poDetails)
  //     // this.showPOs()
  //   }else{
  //     // this.toastr.error(resp.message);
  //     Swal.fire({ 
  //       heightAuto: false,
  //       icon: 'error',
  //       text: resp.message,
  //       width: 500
  //     });
  //     return;
  //   }
  //  },(err)=>{
  //   this.spinner.hide()
  //   // this.toastr.error();
  //   Swal.fire({ 
  //     heightAuto: false,
  //     icon: 'error',
  //     text: err.error.errorMessage,
  //     width: 500
  //   });
  //   return;
  //  })
  // }

  onDeliverySiteSelect(e: any, i: any) {
    // console.log(e)
    // this.modelShipments[i].deliverysite=e
    // console.log(this.modelShipments[i].deliverysite)
  }
  addItem(index) {
    // console.log(this.modelShipments)
    this.modelShipments.push({
      lineNo: '',
      componentName: '',
      uom: '',
      quantity: '',
      needbydate: '',
      deliverysite: '',
      serialNum: ++index,
    })
    // console.log(this.modelBranch);
    this.spinner.hide()
  }
  deleteItem(index) {
    this.spinner.show()
    // console.log(this.modelBranch[index],this.modelBranch[index].serialNum,"#$%^&*")
    // console.log("modl data", this.modelBranch)
    // this.selectedItemName[this.modelShipments[index].serialNum]=[]
    // this.BranchAreaSelect[this.modelBranch[index].serialNum]=[]
    // this.modelBranch[index].serialNum.ASM_CODE=[]
    for (let i = 0; i < this.modelShipments.length; i++) {
      if (this.modelShipments[i].serialNum === this.modelShipments[index].serialNum) {
        this.modelShipments[i].itemNameSelect = []
      }
    }
    // this.modelBranch[this.modelBranch[index].serialNum].ASM_CODE = [];
    // this.modelBranch[this.modelBranch[index].serialNum].STATE = [];
    // this.modelBranch[this.modelBranch[index].serialNum].DISTRICT = [];
    // this.modelBranch[this.modelBranch[index].serialNum].AREA = [];

    console.log('index while deleting:', index);
    console.log('beforeRemoving', this.modelShipments);
    this.modelShipments.splice(index, 1);
    console.log('remainingModel', this.modelShipments);
    this.spinner.hide()
    console.log(this.modelShipments.length, "len")
  }
  // submitsplit(form:NgForm){
  //   console.log(this.poDetails,"podetails")
  //   // console.log(this.modelShipments)

  //   if(form.invalid){
  //     console.log("form",form)
  //     return
  //   }
  //   console.log(this.modelShipments)
  //   let splittingQuantity = this.modelShipments.reduce((a, b)=>{
  //   	return a + b.quantity
  //   },0)
  //   console.log(splittingQuantity,"sq")
  //   if(this.popupdata[0].quantity.toString()!==splittingQuantity.toString()){
  //     Swal.fire({ 
  //       heightAuto: false,
  //       icon: 'error',
  //       text: 'Total quantity should be '+ this.popupdata[0].quantity,
  //       width: 500
  //     });
  //     return;
  //   }
  //   this.modelShipments.map((x:any)=>{
  //     if(moment(x.needbydate).format('DD-MM-YYYY')> this.selectedNeedByDateShow){
  //       Swal.fire({
  //         heightAuto: false,
  //         icon: 'error',
  //         text:'Need by date should be less than or equal to of this date : '+ this.selectedNeedByDateShow,
  //         width: 500
  //       });
  //       return;
  //     }
  //   })
  //   let item = this.popupdata[0].itemName
  //   for(let i=0;i<this.poDetails[0].lineItems.length;i++){
  //     console.log(this.poDetails[0].lineItems[i].lineno,"2")
  //     if(this.poDetails[0].lineItems[i].itemName===item && this.poDetails[0].lineItems[i].lineno===this.popupdatalineno){
  //       this.poDetails[0].lineItems[i].splittingData=this.modelShipments
  //     }
  //   }
  //   Swal.fire({
  //     title: 'Success',
  //     icon: 'success',
  //     text: "Validated Succesfully",
  //     // showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'OK'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.closemodal()
  //     }
  //   })
  // }
  SubmitConsolidatePo(form: NgForm) {
    console.log(this.poDetails, "submit")
    if (form.invalid) {
      return;
    }
    console.log(this.modelShipments, "1")
    let dates = this.modelShipments.map((x: any) => x.needbydate)
    console.log(dates)
    let r, c = 0
    for (let i = 0; i < dates.length; i++) {
      if (moment(dates[i]).format('DD-MM-YYYY') > this.selectedNeedByDateShow) {


        if (this.isDarkTheme === 'light') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            confirmButtonText: 'OK',
            text: 'Need by date should be less than or equal to of this date : ' + this.selectedNeedByDateShow,
            width: 500
          }).then((result) => {
            if (result.isConfirmed) {
              r = 1
              return;
            }
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({

            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + 'Need by date should be less than or equal to of this date : ' + this.selectedNeedByDateShow + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
          }).then((result) => {
            if (result.isConfirmed) {
              r = 1
              return;
            }
          });
        }
      } else { c++ }
      if (r === 1) {
        return;
      }
    }
    if (c === this.modelShipments.length) {
      let lineItems = []
      for (let i = 0; i < this.modelShipments.length; i++) {
        lineItems.push({
          "lineno": this.modelShipments[i].lineNo,
          "componentName": this.modelShipments[i].componentName,
          "uom": this.modelShipments[i].uom,
          "quantity": this.modelShipments[i].quantity,
          "needByDate": moment(this.modelShipments[i].needbydate).format("DD-MM-YYYY"),
          "deliverySite": this.modelShipments[i].deliverysite[0].SiteCode
        })
      }
      let poconsolidation = []
      for (let i = 0; i < this.poConsolidateDetails.length; i++) {
        poconsolidation.push({
          "poNumber": this.poConsolidateDetails[i].poNumber,
          "poRefNumber": this.poConsolidateDetails[i].poRefNumber,
          "shipmenRefNumber": this.poConsolidateDetails[i].shipmenRefNumber
        })
      }
      let obj = {
        "buyerDetails": {
          "buyerID": this.LoggedUserDetails.email,
          "buyerName": this.LoggedUserDetails.name,
          "buyerOrg": this.LoggedUserDetails.userRoledcorg,
          "poSite": this.siteCode[0].siteCode
        },
        "posendto": {
          "poNumber": this.poNumber,
          "toOrgID": this.raiseConsolidate[0].ParticipantID,
          "toOrgName": this.raiseConsolidate[0].ParticipantName,
          "toOrgSiteCode": this.siteCode[0].siteCode
        },
        "lineItems": lineItems,
        "poconsolidation": poconsolidation
      }
      // console.log(obj,"obj")
      console.log(obj, "2")
      this.spinner.show()
      this.consolidatePOService.createPOcontractManufacturer(obj).subscribe((resp: any) => {
        this.spinner.hide()
        if (resp.status === 'Success') {
          

          if (this.isDarkTheme === 'light') {
            Swal.fire({
              title: 'Success',
              html: '<strong>' + resp.data.message + '</strong></br><b>TRXID:</b>' + resp.data.transactionID,
              icon: 'success'
            }).then(() => {
              this.backRoute()
            })
          } else if (this.isDarkTheme === 'dark') {
            Swal.fire({
              title: 'Success',
              html: '<strong>' + resp.data.message + '</strong></br><b>TRXID:</b>' + resp.data.transactionID,
              icon: 'success',
              background: '#333',
              iconColor: '#fff',
            }).then(() => {
              this.backRoute()
            })
            
          }
        }
        else {
          if (this.isDarkTheme === 'light') {
            Swal.fire({
              heightAuto: false,
              icon: 'error',
              text: resp.message,
              width: 500
            });
          } else if (this.isDarkTheme === 'dark') {
            Swal.fire({
              heightAuto: false,
              icon: 'error',
              html: '<strong>' + resp.message + '</strong>',
              width: 500,
              background: '#333',
              iconColor: '#fff',
            });
           
            
          }
        }
      }, (err) => {
        this.spinner.hide()
        

        if (this.isDarkTheme === 'light') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            text: err.error.errorMessage,
            width: 500
          });
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            html: '<strong>' + err.error.errorMessage + '</strong>',
            width: 500,
            background: '#333',
            iconColor: '#fff',
          });
         
          
        }
      })
    }

    // this.modelShipments.map((x:any)=>{
    // if(moment(x.needbydate).format('DD-MM-YYYY')> this.selectedNeedByDateShow){
    //   Swal.fire({
    //   heightAuto: false,
    //   icon: 'error',
    //   confirmButtonText:'OK',
    //   text:'Need by date should be less than or equal to of this date : '+ this.selectedNeedByDateShow,
    //   width: 500
    //   }).then((result)=>{
    //     if(result.isConfirmed){
    //       r=1
    //       return;
    //     }
    //   })
    //   }
    //   if(r===1){
    //     return;
    //   }
    // })

  }
  backRoute() {
    this.router.navigate(['cm-create-po'])
  }
  raiseConsolidatePO() {
    this.spinner.show()
    this.consolidatePOService.getVendorList().subscribe((resp: any) => {
      this.spinner.hide()
      if (resp.status === 'Success') {
        this.raiseConsolidateData = resp.data
      } else {
       
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            text: resp.message,
            width: 500
          });
  
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            html: '<strong>' + resp.message + '</strong>',
            width: 500,
            background: '#333',
            iconColor: '#fff',
          });
         
          
        }
      }
    }, (err) => {

      this.spinner.hide()
     

      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        });

      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          html: '<strong>' + err.error.errorMessage + '</strong>',
          width: 500,
          background: '#333',
          iconColor: '#fff',
        });
       
        
      }
    })
  }
  onmanufactureSelect(e: any) {
    console.log(e)
    let obj = {
      "dcOrg": e.ParticipantID
    }
    this.spinner.show()
    this.consolidatePOService.getVendorSiteByVendorCode(obj).subscribe((resp: any) => {
      this.spinner.hide()
      if (resp.status === 'Success') {
        this.siteCodeData = resp.data
      }
      else {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            text: resp.message,
            width: 500
          });
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            html: '<strong>' + resp.message + '</strong>',
            width: 500,
            background: '#333',
            iconColor: '#fff',
          });
         
          
        }
      }
    }, (err) => {
      this.spinner.hide()
      
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        });
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          html: '<strong>' + err.error.errorMessage + '</strong>',
          width: 500,
          background: '#333',
          iconColor: '#fff',
        });
       
        
      }
    }
    )
  }
  onmanufactureDeSelect(e: any) {
    this.siteCodeData = []
    this.siteCode = []
  }
  finalSubmitConsolidatePO() {
    this.spinner.show()
    let lineitemDetails = this.poDetails[0].lineItems
    console.log(lineitemDetails)
    this.respSplitShipments = []
    this.respShipments = []
    this.respShipments = []
    for (let i = 0; i < lineitemDetails.length; i++) {
      let lineDetails = lineitemDetails[i]
      for (let j = 0; j < lineitemDetails[i].splittingData.length; j++) {
        let obj = {
          itemName: lineitemDetails[i].itemName,
          needByDate: lineitemDetails[i].splittingData[j].needbydate,
          primaryUom: lineitemDetails[i].splittingData[j].uom,
          quantity: lineitemDetails[i].splittingData[j].quantity.toString(),
          secondaryUom: lineitemDetails[i].secondaryUom,
          shiptoLocation: lineitemDetails[i].splittingData[j].deliverysite[0] === 'RSE-Vizag' ? lineitemDetails[i].splittingData[j].deliverysite[0] = 'SITE007' : lineitemDetails[i].splittingData[j].deliverysite[0]
        }
        for (let k = 0; k < lineitemDetails[i].shipping[0].shipmentNumbers.length; k++) {
          let obj2 = {
            needByDate: lineitemDetails[i].splittingData[j].needbydate,
            poRefNumber: lineDetails.shipping[0].shipmentNumbers[k].poRefNumber,
            quantity: lineitemDetails[i].splittingData[j].quantity.toString(),
            shipmenRefNumber: lineDetails.shipping[0].shipmentNumbers[k].shipmenRefNumber,
            shiptoLocation: lineitemDetails[i].splittingData[j].deliverysite[0] === 'RSE-Vizag' ? lineitemDetails[i].splittingData[j].deliverysite[0] = 'SITE007' : lineitemDetails[i].splittingData[j].deliverysite[0]
          }
          this.respShipments.push(obj2)
        }
        this.respSplitShipments.push(obj)
      }
    }
    let dataobj = {
      "buyerID": this.LoggedUserDetails.email,
      "buyerName": this.LoggedUserDetails.name,
      "buyerOrg": this.LoggedUserDetails.userRoledcorg,
      "poSite": this.LoggedUserDetails.sitecode,
      "poreferences": this.selectedpo,
      "needByDate": moment(this.selectedNeedByDate).format('DD-MM-YYYY'),
      "shipments": this.respShipments,
      "splitshipments": this.respSplitShipments,
      "posendto": {
        "manfHubName": this.raiseConsolidate[0].ParticipantName,
        "manfHubID": this.raiseConsolidate[0].ParticipantID,
        "poNumber": this.poNumber,
        "siteCode": this.siteCode[0].siteCode
      }
    }
    this.consolidatePOService.finalsubmitconsolidatepo(dataobj).subscribe((resp: any) => {
      this.spinner.hide()
      if (resp.status === 'Success') {
        

        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Success',
            html: '<strong>' + resp.message[0].message + '</strong></br><b>TRXID:</b>' + resp.message[0].transactionID,
            icon: 'success'
          }).then(() => {
            this.backRoute()
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            title: 'Success',
            html: '<strong>' + resp.message[0].message + '</strong></br><b>TRXID:</b>' + resp.message[0].transactionID,
            icon: 'success',
            background: '#333',
            iconColor: '#fff',
          }).then(() => {
            this.backRoute()
          })
         
         
          
        }
      } else {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            text: resp.message,
            width: 500
          });
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            html: '<strong>' + resp.message + '</strong>',
            width: 500,
            background: '#333',
            iconColor: '#fff',
          });
         
          
        }
      }
    }, (err) => {
      this.spinner.hide()
     
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        });
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          html: '<strong>' + err.error.errorMessage + '</strong>',
          width: 500,
          background: '#333',
          iconColor: '#fff',
        });
       
        
      }
    })


  }
}
