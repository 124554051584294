import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService{

  constructor(private http: HttpClient) { 
    super()
  }
  getBuyerDashboardWidgets(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'dashboards/buyerDashboard';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  salesallShipmentsCount(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/salesDashboard/allShipments';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  BuyerallShipmentsCount(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/buyerDashboard/allShipments';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  BuyershipmentsArrivingToday(poType:string,userId:string,userType:string){
    const url = this.baseUrl + 'reports/buyerDashboard/shipmentsArrivingToday';
    return this.http.get(url,{params:{poType:poType,userId:userId,userType:userType}});
  }
  BuyershipmentsToBeNotified(poType:string,userId:string,userType:string){
    const url = this.baseUrl + 'reports/buyerDashboard/shipmentsToBeNotified';
    return this.http.get(url,{params:{poType:poType,userId:userId,userType:userType}});
  }
  BuyershipmentIntransit(poType:string,userId:string,userType:string){
    const url = this.baseUrl + 'reports/buyerDashboard/shipmentsInTransit';
    return this.http.get(url,{params:{poType:poType,userId:userId,userType:userType}});
  }
  BuyershipmentsMissedDueDate(poType:string,userId:string,userType:string){
    const url = this.baseUrl + 'reports/buyerDashboard/shipmentsMissedDueDate';
    return this.http.get(url,{params:{poType:poType,userId:userId,userType:userType}});
  }
  MHSalesNotificationTable(acceptance:string,orgName:string){
    const url = this.baseUrl + 'mh/fetchpos';
    return this.http.get(url,{params:{acceptance:acceptance,orgName:orgName}})
  }
  MHSalesAcceptReject(acceptance:string,acceptedBy:string,acceptedSite:string,orgName:string,poRefNumber:string){
    const url = this.baseUrl + 'mh/acceptrejectpos';
    return this.http.put(url,{acceptance:acceptance,acceptedBy:acceptedBy,acceptedSite:acceptedSite,orgName:orgName,poRefNumber:poRefNumber})
  }
  getSalesDashboardWidgets(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'dashboards/salesDashboard';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  salesshipmentsArrivingToday(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/salesDashboard/shipmentsArrivingToday';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  salesmissingduedate(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/salesDashboard/shipmentsMissedDueDate';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  salesshipmentsInTransit(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/salesDashboard/shipmentsInTransit';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  salesshipmentsToBeNotified(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/salesDashboard/shipmentsToBeNotified';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  salesposNotAcknowledged(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/salesDashboard/posNotAcknowledged';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  salesallInwardPOs(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/salesDashboard/allInwardPOs';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  CMSalesNotificationTable(acceptance:string,orgName:string){
    const url = this.baseUrl + 'cm/fetchpos';
    return this.http.get(url,{params:{acceptance:acceptance,orgName:orgName}})
  }
  CMSalesAcceptReject(acceptance:string,acceptedBy:string,acceptedSite:string,orgName:string,poRefNumber:string){
    const url = this.baseUrl + 'cm/acceptrejectpos';
    return this.http.put(url,{acceptance:acceptance,acceptedBy:acceptedBy,acceptedSite:acceptedSite,orgName:orgName,poRefNumber:poRefNumber})
  }
  SuppSalesNotificationTable(acceptance:string,orgName:string){
    const url = this.baseUrl + 'supp/fetchpos';
    return this.http.get(url,{params:{acceptance:acceptance,orgName:orgName}})
  }
  SuppSalesAcceptReject(acceptance:string,acceptedBy:string,acceptedSite:string,orgName:string,poRefNumber:string){
    const url = this.baseUrl + 'supp/acceptrejectpos';
    return this.http.put(url,{acceptance:acceptance,acceptedBy:acceptedBy,acceptedSite:acceptedSite,orgName:orgName,poRefNumber:poRefNumber})
  }
  getFullDashboardWidgets(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'dashboards/fulfilmentDashboard';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  FullshipmentsDueInNextNDays(poType: string, userId: string, userType: string, nDays: string) {
    const url = this.baseUrl + 'reports/fulfilmentDashboard/shipmentsDueInNextNDays';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType, nDays: nDays} });
  }
  FullshipmentsMissedDueDate(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/fulfilmentDashboard/shipmentsMissedDueDate';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  FullshipmentsInTransit(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/fulfilmentDashboard/shipmentsInTransit';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  Fullallshipments(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/fulfilmentDashboard/allShipments';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  PortofArrival(){
    const url = this.baseUrl + 'ports/portofarrival';
    return this.http.get(url);
  }
  PortofDeparure(){
    const url = this.baseUrl + 'ports/portofdeparture';
    return this.http.get(url);
  }
  Logistics(){
    const url = this.baseUrl + 'misc/orgs/logistics';
    return this.http.get(url);
  }
  ASNshipments(orgName:string){
    const url = this.baseUrl + 'supp/shipments';
    return this.http.get(url,{params:{orgName:orgName}})
  }
  LeadingDays(arrival:string,departure:string){
    const url = this.baseUrl + 'ports/leaddays';
    return this.http.get(url,{params:{arrival:arrival,departure:departure}})
  }
  getfiledownload(file: string) {
    return this.baseUrl + 'download?filePath=' + file;
    }
  dealersList() {
    const url = this.baseUrl + 'potracking/dealerNames';
    return this.http.get(url);
  }
  getDealerPO(poType: string, customerName: string) {
    const url = this.baseUrl + 'fetchPosByPOTypeAndCustomer';
    return this.http.get(url, { params: {poType: poType, customerName: customerName} });
  }
  getPOTracking(poType: string, poNumber: string) {
    const url = this.baseUrl + 'potracking';
    return this.http.get(url, { params: {poType: poType, poNumber: poNumber} });
  }
  getTrackinglines(poType: string, poNumber: string) {
    const url = this.baseUrl + 'potracking/poDetails';
    return this.http.get(url, { params: {poType: poType, poNumber: poNumber} });
  }
  getCmASN(orgName: string, shipmetFlag: string) {
    const url = this.baseUrl + 'cm/asnList';
    return this.http.get(url, { params: {orgName: orgName, shipmetFlag: shipmetFlag} });
  }
  getArrival() {
    const url = this.baseUrl + 'ports/portofarrival';
    return this.http.get(url);
  }
  putPackageInfoCM(obj: Object) {

    
    const url = this.baseUrl + 'cm/addpackageinfo';
  
    return this.http.put(url, obj);
  }
  
  createAsnSupplier(obj:any){
  //   const inputArgs = JSON.stringify(obj['inputArgs']);
  // console.log(inputArgs);
  // const data = new FormData();
  // data.set('inputArgs', inputArgs);
  // data.set('file', obj['file']);
    const url = this.baseUrl + 'supp/createasn';
    return this.http.post(url,obj);
  }
  addPackageInfo(obj:any){
    const url = this.baseUrl + 'supp/addpackageinfo';
    return this.http.put(url,obj);
  }
  postCmASNvalidate(obj: Object) {
    const url = this.baseUrl + 'cm/createasn/validate';
    return this.http.post(url, obj);
  }
  uploadCMASN(obj: Object) {
    const url = this.baseUrl + 'cm/createasn';
    return this.http.post(url, obj);
  }
  getPOlistfromMH(obj:any){
    let url= this.baseUrl + "fetchPosByPOType"
    return this.http.get(url,{params:obj})
  }
  getDetailsOfPO(obj:any){
    let url= this.baseUrl+"potracking"
    return this.http.get(url,{params:obj})
  }
  plantCodeValidations(data:any){
    const url='https://cron.api.ext.tier1.realwarranty.tech'
    return this.http.post(url + '/cron/plantCodeValidation',data)
  }
  loginToken(data:any){
    let formBody: any = [];
    for (let property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);

    }
    formBody = formBody.join("&");
    const url = 'https://auth.tier1.realwarranty.tech/auth/realms/RW_tiernode/protocol/openid-connect/token'
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    // console.log(params, params.toString(), "params")
    return this.http.post(url, formBody, options)
  }
  getPOlist(poType: string) {
    const url = this.baseUrl + 'fetchPosByPOType';
    return this.http.get(url, { params: {poType: poType} });
  }
  getsuppadminDashboardWidgets(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'dashboards/supplierDashboard';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  SuppadminshipmentsDueInNextNDays(poType: string, userId: string, userType: string, nDays: string) {
    const url = this.baseUrl + 'reports/supplierDashboard/shipmentsDueInNextNDays';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType, nDays: nDays} });
  }
  SuppadminshipmentsMissedDueDate(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/supplierDashboard/shipmentsMissedDueDate';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  SuppadminshipmentsInTransit(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/supplierDashboard/shipmentsInTransit';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  Suppadmininwardpos(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/supplierDashboard/allInwardPOs';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
  Suppadminallshipments(poType: string, userId: string, userType: string) {
    const url = this.baseUrl + 'reports/supplierDashboard/allShipments';
    return this.http.get(url, { params: {poType: poType, userId: userId, userType: userType} });
  }
}
