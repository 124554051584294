import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ICellRendererParams, SelectionChangedEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import {ConsolidatepoService} from 'src/app/services/consolidatepo.service'
import { ThemeService } from 'src/app/themes/theme.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-cm-buyer-create-po',
  templateUrl: './cm-buyer-create-po.component.html',
  styleUrls: ['./cm-buyer-create-po.component.scss']
})
export class CmBuyerCreatePoComponent implements OnInit {
  itemNameSelect=[]
  itemNameSettings={}
  itemNameData=[]
  needbydate:any;
  poDetails: any;
  showPODetails:boolean=false;
  columnDefs
  defaultColDef;
  columnDefs1;
  pagination = true;
  paginationPageSize;
  rowData: any = [];
  shippingData: any = [];
  poType;
  userId;
  status1;
  domLayout: any;
  rowSelection;
  autoGroupColumnDef;
  maxBlocksInCache;
  sideBar;
  userType = "BUYER";
  isDarkTheme;
  detailCellRendererParams;
  getRowHeight;
  gridApi: any;
  selectedRowsData=[];
  LoggedUserDetails: any;
  loggedUser
  orgType
  constructor(private router: Router,private authService : AuthService,private consolidatePOService : ConsolidatepoService,private themeService: ThemeService,private toastr: ToastrService, private spinner : NgxSpinnerService) {
    this.domLayout = 'autoHeight';

    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.rowSelection = 'multiple'

    this.autoGroupColumnDef = {
      icons: {
        menu: '<em class="fa fa-shower"/>',
        filter: '<em class="fa fa-long-arrow-alt-up"/>',
        columns: '<em class="fa fa-snowflake"/>',
        sortAscending: '<em class="fa fa-sort-alpha-up"/>',
        sortDescending: '<em class="fa fa-sort-alpha-down"/>',
      },
      headerCheckboxSelection: true,
      width: 300,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
      checkbox: true,
    },
    },
    
      this.paginationPageSize = 10;
    this.maxBlocksInCache = 1;
    this.pagination = true;
    this.sideBar = {
      toolPanels: [

        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },


        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },


      ],

    };
   }

  ngOnInit(): void {
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;
    });

    this.orgType = sessionStorage.getItem("orgName")
    if(JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'cm1-buyer@rvbl.com'){
      this.loggedUser = 'TIER1 SUPPLIER1 BUYER'
    }
    this.LoggedUserDetails = this.authService.getLoginDetails()
    this.itemNameSettings = {
      singleSelection: true,
      idField: 'itemId',
      textField: 'itemName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    };
    // this.itemNameData=[
    //   {itemId: "LaptopModel M", itemName: "LaptopModel M"},
    //   {itemId: "Ceiling Fan Remote",itemName: "Ceiling Fan Remote"}
    // ]
    // console.log(this.itemNameData)
    this.loadItemDetails()
    this.showPOs()
  }
  loadItemDetails(){
    let obj={
      orgName:this.LoggedUserDetails.userRoledcorg
    }
    this.spinner.show()
    this.consolidatePOService.getItemNameList(obj).subscribe((resp:any)=>{
      this.spinner.hide()
      if(resp.status==='Success'){
        this.itemNameData=resp.data
        // console.log(this.itemNameData,"id2")
      }else{
        this.spinner.hide()
        
        if (this.isDarkTheme === 'light'){
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            text: resp.message,
            width: 500
          });
        }else if (this.isDarkTheme === 'dark') {
          Swal.fire({
    
            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>"+  resp.message + "</p>",
            width: 500,
            background : '#333',
            iconColor:'#fff',
            
            
    
    
          });
        }
      }
    },(err)=>{
      this.spinner.hide()
      
      if (this.isDarkTheme === 'light'){
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        });
      }else if (this.isDarkTheme === 'dark') {
        Swal.fire({
  
          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>"+  err.error.errorMessage + "</p>",
          width: 500,
          background : '#333',
          iconColor:'#fff',
          
          
  
  
        });
      }
    })
  }
  showPOs() {
    this.columnDefs = [
      //  {
      //   headerName: 'SELECT', 
      //   field: '', 
      //   filter: 'agSetColumnFilter',
      //   cellRenderer: 'agGroupCellRenderer',
      //   chartDataType: 'category',
      //   headerTooltip: 'SELECT',
      //   checkboxSelection:true
      // },
      {
        headerName: 'PO NUMBER',
        field: 'poNumber',
        filter: 'agSetColumnFilter',
        checkboxSelection:true,
        // cellRenderer: 'agGroupCellRenderer',
        // cellRendererParams: {
        //   checkbox: true,
        // },    
        // chartDataType: 'category',
        headerTooltip: 'PO NUMBER',
        tooltipField: 'poNumber',
        // checkboxSelection:true
      },
      {
        headerName: 'LINE NO',
        field: 'lineno',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'LINE NO',
        tooltipField: 'lineno',

      },
      {
        headerName: 'SHIP NO', 
        field: 'shipmentNumber', 
        filter: true, 
        headerTooltip: 'SHIP NO',
        tooltipField: 'shipmentNumber',
      },
      {
        headerName: 'ITEM NAME', field: 'itemName',
        headerTooltip: 'ITEM NAME',
        tooltipField: 'itemName',

      },
      {
        headerName: 'UOM', field: 'primaryUom',
        headerTooltip: 'UOM',
        tooltipField: 'primaryUom',

      },
      {
        headerName: 'QUANTITY', field: 'quantity',
        headerTooltip: 'QUANTITY',
        tooltipField: 'quantity',
      },
      {
        headerName: 'NEED BY DATE', field: 'needByDate',
        headerTooltip: 'NEED BY DATE',
        tooltipField: 'needByDate',
      },
     
    ];
    // this.detailCellRendererParams = {
    //   detailGridOptions: {
    //     columnDefs: [
    //       {
    //         headerName: 'LINE #', 
    //         field: 'lineno',
    //         headerTooltip: 'LINE #',
    //         tooltipField: 'lineno',
    //         onCellClicked: this.popupopen.bind(this),
    //         cellRenderer: (params: ICellRendererParams) => {
    //           return `<strong><a class="text-center d-flex" >${params.value} </a></strong>`;

    //         },
    //       },
    //       {
    //         headerName: 'ITEM NAME', field: 'itemName',
    //         headerTooltip: 'ITEM NAME',
    //         tooltipField: 'itemName',

    //       },
    //       {
    //         headerName: 'UOM', field: 'primaryUom',
    //         headerTooltip: 'UOM',
    //         tooltipField: 'primaryUom',

    //       },
    //       {
    //         headerName: 'QUANTITY', field: 'quantity',
    //         headerTooltip: 'QUANTITY',
    //         tooltipField: 'quantity',
    //       },
    //     ],
    //     defaultColDef: {
    //       flex: 1, searchable: false, floatingFilter: false, sortable: true, resizable: true, enableRangeSelection: true, popupParent: document.body
    //     },

    //   },

    //   getDetailRowData(params) {
    //     params.successCallback(params.data.lineItems);

    //   },

    // };
    this.getRowHeight = params => {
      const defRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
      if (params.node.detail) {
        const offset = 100;
        const detailRowHeight =
          params.data.lineItems.length * defRowHeight;
        var gridSizes = params.api.getSizesForCurrentTheme();
        return detailRowHeight + gridSizes.headerHeight + offset;
      } else {
        return defRowHeight;
      }
    }
    this.rowData = this.poDetails
  }
  closemodal() {
    document.getElementById('myNav').style.width = '0';
  }
  submitClick(form:NgForm){
   if(form.invalid){
     return;
   }
  //  if(this.itemNameSelect[0]==='undefined'){
  //    this.itemNameSelect[0]=''
  //  }
   let obj={
    orgName:this.LoggedUserDetails.userRoledcorg ,
    itemName: this.itemNameSelect[0]!==undefined?this.itemNameSelect[0]:'',
    needByDate: this.needbydate!==''?moment(this.needbydate).format("DD-MM-YYYY"):''
   }
   this.spinner.show()
   this.consolidatePOService.cmPoList(obj).subscribe((resp:any)=>{
    this.spinner.hide()
    if(resp.status==='Success'){
      this.poDetails = resp.data
      console.log(this.poDetails)
      this.showPODetails=true;
      this.showPOs()
    }else{
      this.showPODetails=false;
      this.rowData=[]
      // this.toastr.error()
      
      if (this.isDarkTheme === 'light'){
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: resp.message,
          width: 500
        }); 
      }else if (this.isDarkTheme === 'dark') {
        Swal.fire({
  
          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>"+  resp.message + "</p>",
          width: 500,
          background : '#333',
          iconColor:'#fff',
          
          
  
  
        });
      }
    }
   },(err)=>{
     this.spinner.hide()
     this.showPODetails=false;
     this.rowData=[]
    //  this.toastr.error(err.error.errorMessage)
    
    if (this.isDarkTheme === 'light'){
      Swal.fire({
        heightAuto: false,
        icon: 'error',
        text: err.error.errorMessage,
        width: 500
      });
    }else if (this.isDarkTheme === 'dark') {
      Swal.fire({

        heightAuto: false,
        icon: 'error',
        html: "<p style='color:#fff'>"+  err.error.errorMessage + "</p>",
        width: 500,
        background : '#333',
        iconColor:'#fff',
        
        


      });
    }
   })


  }



validateClick(){
  if(this.selectedRowsData.length>0){
    // this.spinner.show()
    console.log(this.selectedRowsData)
    // let shipref = this.selectedRowsData.map((x:any)=>x.shipmenRefNumber)
let shipref= this.selectedRowsData.filter(item =>{
  return item.shipmenRefNumber
})
    console.log(shipref,"po numer")
    if(this.needbydate===''){
      this.router.navigate(['cm-create-po-preview'],{queryParams:{consolidate:JSON.stringify(shipref[0].poNumber),itemName:this.itemNameSelect[0]}})

    }
    if(this.itemNameSelect.length===0){
      this.router.navigate(['cm-create-po-preview'],{queryParams:{consolidate:JSON.stringify(shipref[0].poNumber),needbydate:this.needbydate}})

    }
 
  }
  else{
    
    if (this.isDarkTheme === 'light'){
      Swal.fire({
        heightAuto: false,
        icon: 'error',
        text: 'Please Select Atleast One Order',
        width: 500
      });
    }else if (this.isDarkTheme === 'dark') {
      Swal.fire({

        heightAuto: false,
        icon: 'error',
        html: "<p style='color:#fff'>"+  'Please Select Atleast One Order' + "</p>",
        width: 500,
        background : '#333',
        iconColor:'#fff',
        
        


      });
    }
    return;
  }
}
onSelectionChanged(event: SelectionChangedEvent) {
  let x = event.api.getSelectedNodes()
  this.selectedRowsData = x.map((a:any)=>a.data)
}
onItemNameSelect(e:any){
  console.log(this.itemNameSelect)
  this.needbydate=''
}
onDateSelect(e:any){
  this.itemNameSelect=[]
}
}
