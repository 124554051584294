import { Theme } from './symbols';

export const darkTheme: Theme = {
    name: 'dark',
    properties: {
        '--background': '#1d1d1d',
        '--on-background': '#444',
        '--primary': '#1d1d1d',
        '--on-primary': '#fff',
        '--border': '#333',
        '--color': '#afafaf',
        '--success': '#61da34',
        '--light': '#555',
        '--lightgray': '#ddd',
        '--grey': '#555',
        '--lightgrey': '#444',
        '--bg-color': '#a7a7a7',
        '--dashboard': '#333',
        '--dashboard-color': '#ddd',
        '--count-color': '#fff',
        '--footer': '#333',
        '--footer-border': '#44',
        '--on-border': '#00a6d6',
        '--text-color': '#00a6d6',
        '--drop-down': '#333',
        '--wa-background': '#444',
        '--info-color': '#aaa',
        '--input-border': '#555',
        '--button-text-color': '#fff',
        '--change-button': '#070708',
        '--excel-color' : '#ffff',
        '--tab-color': '#212529',
        '--button-background': '#444',
        '--timeline-item-background':'#1d1d1d',
        '--timeline-item-color':'#fff'

    }
};