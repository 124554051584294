import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from "ag-grid-enterprise";

if (environment.production) {
  enableProdMode();
}
// LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-26_September_2021_[v2]_MTYzMjYxMDgwMDAwMA==21a283793f17eccec4ab3af40486dbd6");
LicenseManager.setLicenseKey('CompanyName=Real Variable Digital AssetServices Private Limited,LicensedGroup=Real Variable,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-021380,ExpiryDate=29_October_2022_[v2]_MTY2Njk5ODAwMDAwMA==9a3da259e240fddabc9cfdc61e23a589')
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
