import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ConsolidatepoService extends BaseService{

  constructor(private http:HttpClient) { 
    super()
  }
  getConsolidatePoDetails(data:any){
    let url= this.baseUrl + "rse/uploadedpos";
    return this.http.get(url,{params:data})
  }
  validateConsolidatePO(data:any){
    let url= this.baseUrl +'rse/consolidatepos';
    return this.http.post(url,data)
  }
  getManufacturers(){
    let url= this.baseUrl+'misc/orgs/manufacturers'
    return this.http.get(url)
  }
  getSiteByManufacturer(data:any){
    let url= this.baseUrl + 'misc/orgs/sites'
    return this.http.get(url,{params:data})
  }
  finalsubmitconsolidatepo(data:any){
    let url= this.baseUrl + 'rse/submitConsolidatedpos'
    return this.http.post(url,data)
  }
  // mh buyer create po
  createPOList(data:any){
    let url= this.baseUrl + "mh/fetchpos"
    return this.http.get(url,{params:data})
  }
  consolidateMHPOs(data:any){
    let url= this.baseUrl + 'mh/consolidatempos'
    return this.http.post(url,data)
  }
  getDeliverySiteList(data:any){
    let url= this.baseUrl + 'misc/inventorySites'
    return this.http.get(url,{params:data})
  }
  getContractManufacturerList(){
    let url= this.baseUrl + 'misc/orgs/contractmanufacturers'
    return this.http.get(url)
  }
  getSitesBasedOnContractManufactures(data:any){
    let url= this.baseUrl + 'misc/orgs/sites'
    return this.http.get(url,{params:data})
  }
  finalSubmitMHBuyerPo(data:any){
    let url= this.baseUrl + 'mh/submitPO'
    return this.http.post(url,data)
  }
  // contract manufacturer
  getItemNameList(data:any){
    let url= this.baseUrl + 'cm/itemNames'
    return this.http.get(url,{params:data})
  }
  cmPoList(data:any){
    let url= this.baseUrl +'cm/filtermhpos'
    return this.http.get(url,{params:data})
  }
  getDeliverySites(data:any){
    let url= this.baseUrl+'misc/inventorySites'
    return this.http.get(url,{params:data})
  }
  getVendorList(){
    let url= this.baseUrl+"misc/orgs/suppliers"
    return this.http.get(url)
  }
  getVendorSiteByVendorCode(data:any){
    let url= this.baseUrl + "misc/orgs/sites"
    return this.http.get(url,{params:data})
  }
  createPOcontractManufacturer(data:any){
    let url= this.baseUrl+'cm/createpurchaseorder/'
    return this.http.post(url,data)
  }
}
