import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  baseUrl = 'http://202.65.145.178:3000/';
  constructor() { }
}
