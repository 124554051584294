import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/themes/theme.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{
  isDarkTheme
  constructor( private spinner: NgxSpinnerService, private router: Router,private themeService: ThemeService,private authService:AuthService ) {
   
  }
  async ngOnInit() {
    
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;
    });
    let role = this.authService.getLoginDetails().partyType
    console.log(role,"role")
    if(role==="CONTRACTORMANFADMIN"){
      this.router.navigate(['cm-admin'])
    }

  }
 
}
