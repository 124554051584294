import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import {RsePoDetails} from 'src/app/models/rse-po-details';
import { AuthService } from 'src/app/services/auth.service';
import {RseImportpoServiceService} from 'src/app/services/rse-importpo-service.service';
import { ThemeService } from 'src/app/themes/theme.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-rse-import-po',
  templateUrl: './rse-import-po.component.html',
  styleUrls: ['./rse-import-po.component.scss']
})
export class RseImportPoComponent implements OnInit {
  user:any;

  model = new RsePoDetails()
  public fileobject: File;
  bulkUploadArray: any = [];
  account:any;
  resultData:any=[];
  total_po = 0;
  updated_po = 0;
  failed_po = 0;
  loggedUser;
  gridColumnApi;
  columnDefs;
  rowData:any=[];
  defaultColDef
  gridApi;
  detailCellRendererParams;
  rowHeight;
  pagination = true;
  paginationPageSize;
  sideBar;
  isDarkTheme: string;
  isSubmit=false;
  rowSelection;
  orgType;
  constructor(private spinner:NgxSpinnerService, private themeService: ThemeService,private service:RseImportpoServiceService,private authService:AuthService) {
    this.columnDefs=[
      {
        headerName: 'S.NO',
        field: 'sno',
        tooltipField: 'sno',
        headerTooltip: 'S.NO',
      },
      {
        headerName:'ERROR',
        headerTooltip: 'ERROR',
        field:'error',
        cellRenderer: (params: ICellRendererParams) => {
          if(!params.value){
            return 'No'
          }
          else{
            return 'Yes'
          }
          // return `<span class="fakeLink">${params.value}</span>`;
        },
        tooltipField: 'error',
      },
      {
        headerName:'MESSAGE',
        headerTooltip: 'MESSAGE',
        field:'message',
        cellRenderer: (params: ICellRendererParams) => {
          if(!params.data.error){
            return `<span class="fa fa-check fa-2x" aria-hidden="true" style="font-size:12px; color:green"></span>`+" "+params.value
          }
          else{
            return `<span class="fa fa-close fa-2x" aria-hidden="true" style="font-size:12px; color:red"></span>`+" "+params.value
          }
          // return `<span class="fakeLink">${params.value}</span>`;
        },
        tooltipField: 'message',
      },
      {
        headerName:'TRANSACTION ID',
        headerTooltip: 'TRANSACTION ID',
        field:'transactionID',
        tooltipField: 'transactionID',
      }
    ]
    this.rowSelection = 'single'
    this.paginationPageSize = 10;
    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.sideBar = {
      toolPanels: [

        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },


        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },


      ],

    };
   }

  ngOnInit(): void {
    this.user = this.authService.getLoginDetails();
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;

    }); 
    this.loggedUser = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).name;
    this.orgType = sessionStorage.getItem("orgName")
 }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
  }
  reset(){
    this.resultData=[];
    this.rowData=[];
    this.total_po = 0;
    this.updated_po = 0;
    this.failed_po = 0;
    this.model.fileUpload=null;
    this.fileobject=null;
    this.isSubmit=false;
  }
  onChange(event) {
    this.bulkUploadArray =[];
    this.fileobject = event.target.files[0];
  }
  onUploadFile() {
    if (this.model.fileUpload === undefined||this.model.fileUpload === null) {
      return
    }
    const formData = new FormData();
    this.spinner.show();
    this.total_po = 0;
    this.updated_po = 0;
    this.failed_po = 0;
    this.bulkUploadArray =[];
    formData.set('file', this.fileobject);
    formData.set('buyername', this.user.name);
    formData.set('buyerid', this.user.email);
    formData.set('buyerorg',this.user.userRoledcorg);
    formData.set('posite', this.user.sitecode);
    // let req={
    //   'file':formData,
    //   'buyername': this.user.name,
    //   'buyerid': this.user.email,
    //   'buyerorg': this.user.userRoledcorg,
    //   'posite': this.user.sitecode
    // }
    console.log(formData)
    this.service.submitBulkUploadList(formData).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 'Success') {
        this.resultData = res.uploadLogs
        for (let i = 0; i < this.resultData.length; i++) {
          this.total_po = this.resultData.length;
          if(this.resultData[i].error === true){
            this.failed_po=this.failed_po+1;
          }
          this.updated_po = this.total_po - this.failed_po;
          this.resultData[i].sno = i+1;
        }
        this.rowData = this.resultData;
        console.log(this.total_po,'tot',this.updated_po,'table data',this.rowData,this.columnDefs)
        this.isSubmit=true;
      }else{
        // Swal.fire({
        //   heightAuto: false,
        //   icon: 'error',
        //   text: res.status.message,
        //   width: 500
        // });
        if (this.isDarkTheme === 'light'){
          Swal.fire({
  
            heightAuto: false,
            icon: 'error',
            text: res.status.message,//COntact Administrator
            width: 500,
          });
        }else if (this.isDarkTheme === 'dark') {
          Swal.fire({
  
            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>"+  res.status.message + "</p>",
            width: 500,
            background : '#333',
            iconColor:'#fff',
            confirmButtonColor:'#272727',
            
            
  
  
          });
        }
      }

    })
  }
}
