
<ng-template [appShowIfLogin]="1">
    <app-tabs ></app-tabs>
</ng-template>


<main role="main" class="flex-grow-1 light-theme" app-theme>

    <div class="d-flex flex-column h-100 main-content-area ">
        <div class="page">
            <router-outlet></router-outlet>
        </div>
    </div>
</main>
<ng-template [appShowIfLogin]="1">

    <app-footer class="footer mt-auto py-3 shadow-sm"
        [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"></app-footer>
</ng-template>