import { darkTheme } from "./themes/dark-theme";
import { lightTheme } from "./themes/light-theme";
import { ThemeModule } from "./themes/theme.module";
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabLayoutComponent } from './layouts/tab-layout/tab-layout.component';
import { TabsComponent } from './layouts/tabs/tabs.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import { KeycloakAngularModule} from 'keycloak-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptor } from '../app/token.interceptor'
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgxPaginationModule } from "ngx-pagination";
import { ToastrModule } from "ngx-toastr";
import { NgxJsonViewModule } from "ng-json-view";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { NgOtpInputModule } from "ng-otp-input";
import { LoginPageComponent } from './components/login-page/login-page.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgbModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SalesexecutivedashboardComponent } from './components/salesexecutivedashboard/salesexecutivedashboard.component';
import { BuyerdashboardComponent } from './components/buyerdashboard/buyerdashboard.component';
import { RseImportPoComponent } from './components/rse-import-po/rse-import-po.component';
import { Token } from './interceptors/token';
import { AuthService } from "./services/auth.service";
import { BuyerinfoComponent } from './components/buyerinfo/buyerinfo.component';
import { MhSalesNotificationComponent } from './components/mh-sales-notification/mh-sales-notification.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { ConsolidatePoComponent } from './components/consolidate-po/consolidate-po.component';
import { ConsolidatePoPreviewComponent } from './components/consolidate-po-preview/consolidate-po-preview.component';
import { ViewIfRolesInDirective } from '././directives/view-if-roles-in.directive';
import {ShowIfLoginDirectiveDirective} from '././directives/show-if-login-directive.directive';
import { SalesexecutiveinfoComponent } from './components/salesexecutiveinfo/salesexecutiveinfo.component';
import { FulfillmentdashboardComponent } from './components/fulfillmentdashboard/fulfillmentdashboard.component';
import { FulfillmentInfoComponent } from './components/fulfillment-info/fulfillment-info.component';
import { MhBuyerCreatePoComponent } from './components/mh-buyer-create-po/mh-buyer-create-po.component';
import { MhBuyerCreatePoPreviewComponent } from './components/mh-buyer-create-po-preview/mh-buyer-create-po-preview.component'
import { CreateAsnComponent } from './components/create-asn/create-asn.component'
import { AdminscreenComponent } from './components/adminscreen/adminscreen.component';
import { CmBuyerCreatePoComponent } from './components/cm-buyer-create-po/cm-buyer-create-po.component';
import { CmBuyerCreatePoPreviewComponent } from './components/cm-buyer-create-po-preview/cm-buyer-create-po-preview.component'
import { AsncmComponent } from './components/asncm/asncm.component';
import { CmAdminComponent } from './components/cm-admin/cm-admin.component';
import { MhAdminComponent } from './components/mh-admin/mh-admin.component';
import { AdmindashboardComponent } from './components/admindashboard/admindashboard.component';
import { DealerInfoComponent } from './components/dealer-info/dealer-info.component'
@NgModule({
  declarations: [
    AppComponent,
    TabLayoutComponent,
    TabsComponent,
    FooterComponent,
    DashboardComponent,
    LoginPageComponent,
    SalesexecutivedashboardComponent,
    BuyerdashboardComponent,
    RseImportPoComponent,
    BuyerinfoComponent,
    ConsolidatePoComponent,
    ConsolidatePoPreviewComponent,
    MhSalesNotificationComponent,
    CustomTooltipComponent,

    ConsolidatePoComponent,
    ViewIfRolesInDirective,
    ShowIfLoginDirectiveDirective,
    SalesexecutiveinfoComponent,
    FulfillmentdashboardComponent,
    FulfillmentInfoComponent,
    MhBuyerCreatePoComponent,
    MhBuyerCreatePoPreviewComponent,
    CreateAsnComponent,
    AdminscreenComponent,
    CmBuyerCreatePoComponent,
    CmBuyerCreatePoPreviewComponent,
    AsncmComponent,
    CmAdminComponent,
    MhAdminComponent,
    AdmindashboardComponent,
    DealerInfoComponent
  ],
  exports: [
    ViewIfRolesInDirective,
    ShowIfLoginDirectiveDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    KeycloakAngularModule,
    AppRoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    ThemeModule.forRoot({
      themes: [lightTheme, darkTheme],
      active: 'light'
    }),
    ToastrModule.forRoot(),
    NgxJsonViewModule,
    NgxJsonViewerModule,
    NgOtpInputModule,
    ModalModule.forRoot(),
    NgbModule,
    
    AgGridModule.withComponents([])
  ],


  providers: [
    BnNgIdleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
