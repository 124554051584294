
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BuyerinfoComponent } from './components/buyerinfo/buyerinfo.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginPageComponent } from "./components/login-page/login-page.component";
import { MhSalesNotificationComponent } from './components/mh-sales-notification/mh-sales-notification.component';
import { RseImportPoComponent } from './components/rse-import-po/rse-import-po.component';
import {LoginActivateGuard} from 'src/app/login-activate.guard'
import {ConsolidatePoComponent} from 'src/app/components/consolidate-po/consolidate-po.component'
import {ConsolidatePoPreviewComponent} from 'src/app/components/consolidate-po-preview/consolidate-po-preview.component'
import { SalesexecutiveinfoComponent } from './components/salesexecutiveinfo/salesexecutiveinfo.component';
import { FulfillmentInfoComponent } from './components/fulfillment-info/fulfillment-info.component';
import { MhBuyerCreatePoComponent } from './components/mh-buyer-create-po/mh-buyer-create-po.component';
import { MhBuyerCreatePoPreviewComponent } from './components/mh-buyer-create-po-preview/mh-buyer-create-po-preview.component';
import { CreateAsnComponent } from './components/create-asn/create-asn.component';
import { CmBuyerCreatePoComponent } from './components/cm-buyer-create-po/cm-buyer-create-po.component';
import { CmBuyerCreatePoPreviewComponent } from './components/cm-buyer-create-po-preview/cm-buyer-create-po-preview.component';
import { AsncmComponent } from './components/asncm/asncm.component';
import { CmAdminComponent } from './components/cm-admin/cm-admin.component';
import { MhAdminComponent } from './components/mh-admin/mh-admin.component';
import { DealerInfoComponent } from './components/dealer-info/dealer-info.component';
const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    
    canActivate:[LoginActivateGuard]
  },
  { path: "", redirectTo: "/dashboard", pathMatch: 'full' },
  { path: "*", redirectTo: "/dashboard", pathMatch: 'full' },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'import-po',
    component: RseImportPoComponent,
    canActivate:[LoginActivateGuard]
  },
  {
    path: 'consolidatePo',
    component: ConsolidatePoComponent,
    canActivate:[LoginActivateGuard]
  },
  {
    path: 'consolidatePoPreview',
    component: ConsolidatePoPreviewComponent,
    canActivate:[LoginActivateGuard]
  },
  {
    path:'mh-create-po',
    component:MhBuyerCreatePoComponent,
    canActivate:[LoginActivateGuard]
  },
  {
    path:'mh-create-po-preview',
    component:MhBuyerCreatePoPreviewComponent,
    canActivate:[LoginActivateGuard]
  },
  {
    path:'cm-admin',
    component:CmAdminComponent,
    canActivate:[LoginActivateGuard]
  },
  
  {
    path:'cm-create-po',
    component:CmBuyerCreatePoComponent,
    canActivate:[LoginActivateGuard]
  },
  {
    path:'cm-create-po-preview',
    component:CmBuyerCreatePoPreviewComponent,
    canActivate:[LoginActivateGuard]
  },
  {
    path: 'dealer-info', children: [
      {
        path: '',
        component: DealerInfoComponent,
        canActivate: [LoginActivateGuard], data: { roles: ['SUPPLIERADMIN'] }
      },
  
    ]
  },
  { path: 'dashboard/buyer-info', component: BuyerinfoComponent, data: { roles: ['Dealerbuyer','Rsebuyer','MHBUYER','CONTRACTORMANFBUYER'] } , canActivate:[LoginActivateGuard]},
  {  path: 'sales-info', children: [
      {
        path: '',
        component: SalesexecutiveinfoComponent,
        canActivate: [LoginActivateGuard],
        data: { roles: ['MHSALES', 'CONTRACTORMANFSALES', 'SUPPLIERSALES', 'Rsebuyer'] }
      },
  
      // {
      //   path: 'split',
      //   component: SplitComponent,
      //   canActivate: [AdminGuard],
      //   data: { roles: ['MHSALES', 'CONTRACTORMANFSALES', 'SUPPLIERSALES', 'Rsebuyer'] }
      // },
  
    ]
  },
  {
    path: 'mh-sales-notification',
    component:MhSalesNotificationComponent,
    canActivate:[LoginActivateGuard]
  },
  { path: 'fulfillment-info', component: FulfillmentInfoComponent, canActivate: [LoginActivateGuard], data: { roles: ['CONTRACTORMANFFULL', 'SUPPLIERFULL', 'MHFULL'] } },
  { path: 'buyer-info', component: BuyerinfoComponent, data: { roles: ['Dealerbuyer','Rsebuyer','MHBUYER','CONTRACTORMANFBUYER'] } , canActivate:[LoginActivateGuard]},
  {
    path:'create-asn',component:CreateAsnComponent, canActivate: [LoginActivateGuard]
  },
  { path: 'asn-cm', component: AsncmComponent, canActivate: [LoginActivateGuard], data: { roles: ['CONTRACTORMANFFULL'] } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule] 
})
export class AppRoutingModule { }
