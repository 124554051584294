import { Component, OnInit } from '@angular/core';
import * as echarts from 'echarts';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/themes/theme.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.scss']
})
export class AdmindashboardComponent implements OnInit {
  userType = "SUPPLIER";
  poType;
  userId;
  buyerDashboardInfo:any=[];
  allorders:any;
  duetoday;
  shipmentsmissingduedate;
  dueinnextsevendays;
  intransit;
  allshipments;
  isDarkTheme;
  allinwardspo;
  graphColor;
  acknowledged;
  unacknowledged;
  constructor(private themeService: ThemeService,private adminService: AdminService, private authservice: AuthService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.poType = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).userRoledcorg
    this.userId = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email;
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;

    });
    this.loadWidgetsData();
  }
  loadWidgetsData() {
    const dis = this;
    this.spinner.show()
    this.adminService.getsuppadminDashboardWidgets(this.poType, this.userId, this.userType).subscribe(resp => {
    this.spinner.hide();
      if (resp['status'] == "Fail") {
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: resp['message'],
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            title: 'Error',
            html: '<strong>' +resp['message'] + '</strong>',
            icon: 'error',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
        return;
      }
      // this.buyerDashboardInfo.push(resp)
      console.log(this.buyerDashboardInfo,"dashboard")
      this.duetoday = resp['shipmentsDueTodayCount'];
      this.shipmentsmissingduedate = resp['shipmentsMissedDueDateCount'];
      this.dueinnextsevendays = resp['shipmentsDueInNextNDaysCount'];
      this.intransit = resp['shipmentsInTransitCount'];
      this.allshipments = resp['allShipmentsCount'];
      this.allinwardspo = resp['allInwardPOsCount'];
      this.acknowledged = resp['posAcknowledgedCount'];
      this.unacknowledged = resp['posNotAcknowledgedCount'];
      let data=[
        ['score', 'amount', 'product'],
        [ this.unacknowledged,  this.unacknowledged, 'Pos Not Acknowledged'],
        [this.acknowledged, this.acknowledged, 'Pos Acknowledged'],
        [this.allinwardspo, this.allinwardspo, 'All Inward POs'],
        [ this.allshipments,  this.allshipments, 'All Shipments'],
        [this.intransit, this.intransit, 'Shipments In Transit'],
        [this.dueinnextsevendays, this.dueinnextsevendays, 'Shipments Due In Next N Days'],
        [this.shipmentsmissingduedate, this.shipmentsmissingduedate, 'Shipments Missed Due Date'],
        [this.duetoday, this.duetoday, 'Shipments Due Today'],
      ]
      this.buyerDashboardInfo = data
      
      document.getElementById('main').style.display = 'block';
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;
      this.themeService.themeChanged.subscribe((res: any) => {
        this.isDarkTheme = res;
        if(this.isDarkTheme !== 'light'){
          this.graphColor ='#ddd'
          option = {
            dataset: {
              source:
                this.buyerDashboardInfo
            },
            // grid: { containLabel: true },
            grid: { containLabel: true, show: false, left: 10, top: 10, right: "10%", },
            xAxis: { name: '%' },
            yAxis: {
              type: 'category',
            },
            
            color: [this.graphColor],
            // visualMap: {
             
            //   inRange: {
            //     color: ['#65B581']
            //   }
            // },
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                type: 'bar',
                encode: {
                  x: 'amount',
                  y: 'product'
                }
              }
            ]
          };
          option && myChart.setOption(option);
         
        }else if(this.isDarkTheme === 'light'){
          this.graphColor = '#0E5197'
          option = {
            dataset: {
              source:
                this.buyerDashboardInfo
            },
            // grid: { containLabel: true },
            grid: { containLabel: true, show: false, left: 10, top: 10, right: "10%", },
            xAxis: { name: '%' },
            yAxis: {
              type: 'category',
            },
            
            color: [this.graphColor],
            // visualMap: {
             
            //   inRange: {
            //     color: ['#65B581']
            //   }
            // },
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                type: 'bar',
                encode: {
                  x: 'amount',
                  y: 'product'
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      })
    });

  }
}
