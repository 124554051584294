<div class="page_heading d-flex">
    <div class="warranty col">
        <h3>RSE PO</h3>
    </div>
    <div class="warranty col text-right">
        <strong>Organization Type: <span class="badge badge-info"
                [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'"> {{orgType}}</span></strong> &nbsp;&nbsp;
        <strong>Logged User: <span class="badge badge-info" [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'">
                {{loggedUser}}</span></strong> &nbsp;&nbsp;
    
    </div>
</div>
<div class="mt-4 mb-4  container">

    <form #dealerForm="ngForm">
        <div class="row">
            <div class="col-3">
                <label for="">Po</label>
                <ng-multiselect-dropdown [placeholder]="'Select'" name="record" [settings]="dropdownSettings"
                    [(ngModel)]="poNumber" [data]="dealerList" (onSelect)="onItemSelect1(poNumber)"
                    (onDeSelect)="deselectdealer()" [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                    id="hlf" required>
                </ng-multiselect-dropdown>


            </div>




        </div>

    </form>

    <div class="row mt-4">
        <div class="col-4 mt-3" [hidden]="!check">
            <h4 class="font-weight-bold">MANUFACTURER HUB PO</h4>
            <div class="card card-pricing popular shadow text-left px-3 mb-2" *ngFor="let data of dealerPO; let i= index;">

                <div class="card-body warranty-card">
                    <table class="table table-borderless w-100 align-item-left" aria-describedby="desc">
                        <tr>
                            <th id="id">
                                Org Name

                            </th>
                            <td class="tb-td">:</td>
                            <td>{{orgname1 |titlecase}}</td>
                        </tr>
                        <tr>
                            <th id="id">
                                PO #
                            </th>
                            <td class="tb-td">:</td>
                            <td >
                                
                                <a   data-toggle="tooltip" data-placement="top" title="{{data}}" (click)=" loadcmpo(data,i);" class="context-menu"      [ngClass]="{'active': isClassVisible(i)  }">
                                    {{ (data.length>15)? (data | slice:0:6)+'..':(data) }}
                                  <!-- {{row}} {{row.length}}   -->
                                    <span style="float:right;margin-right: 10px;" *ngIf=isClassVisible(i)><i class="fa fa-arrow-right "></i></span>
                                  </a>
                            </td>


                        </tr>
                        <tr>
                            <th id="id">
                                PO Status
                            </th>
                            <td class="tb-td">:</td>
                            <td>
                                <a (click)="getLines(data,orgname1)" class="context-menu" data-toggle="modal"
                                    data-target="#modal-default1">{{poStatus1}}</a>
                            </td>
                        </tr>

                    </table>
                </div>

            </div>
            
        </div>
        <div class="col-4 mt-3" [hidden]="!check2">
            <h4 class="font-weight-bold">TIER1 SUPPLIER PO</h4>
            <div class="card card-pricing popular shadow text-left px-3 mb-2" *ngFor="let data of mhPO; let i= index;">

                <div class="card-body warranty-card">
                    <table class="table table-borderless w-100 align-item-left" aria-describedby="desc">
                        <tr>
                            <th id="id">
                                Org Name

                            </th>
                            <td class="tb-td">:</td>
                            <td>{{orgname2 |titlecase}}</td>
                        </tr>
                        <tr>
                            <th id="id">
                                PO #
                            </th>
                            <td class="tb-td">:</td>
                            <td >
                                
                                <a   data-toggle="tooltip" data-placement="top" title="{{data}}">
                                    {{ (data.length>15)? (data | slice:0:6)+'..':(data) }}
                                  </a>
                            </td>


                        </tr>
                        <tr>
                            <th id="id">
                                PO Status
                            </th>
                            <td class="tb-td">:</td>
                            <td>
                                <a (click)="getLines_MH(data,orgname2)" class="context-menu" data-toggle="modal"
                                    data-target="#modal-default1">{{status3}}</a>
                            </td>
                        </tr>

                    </table>
                </div>

            </div>
            
        </div>
     
    </div>

</div>
<div id="myNav" class="overlay">
    <a href="javascript:void(0)" class="closebtn" id="closebtn" (click)="closemodal()">&times;</a>
    <div class="overlay-content" id="content">

        <div class="materialdata">
            <div class="side-heading">
                <div class="d-flex">
                    <h4>PO DETAILS</h4>
                </div>
            </div>
            <div class="text-left mt-3">
                <div class="modal-title"><span>{{ponumberdisplay}} </span>- Shipment Details <span
                        style="float:right;margin-right: 20px;">{{orgnamedisplay}} </span></div>
            </div>
            <div>
                <div class="mt-4" *ngIf="rowData.length>0">
                    <ag-grid-angular #agGrid id="myGrid"
                        [ngClass]="isDarkTheme==='light' ? 'ag-theme-balham': 'ag-theme-balham-dark'"
                        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [masterDetail]="true"
                        [rowData]="rowData" [pagination]="pagination" [paginationPageSize]="paginationPageSize"
                        [rowHeight]="rowHeight" [autoGroupColumnDef]="autoGroupColumnDef" [enableRangeSelection]="true"
                        [enableCharts]="true" [chartThemeOverrides]="chartThemeOverrides" [domLayout]="domLayout"
                        [rowSelection]="rowSelection">
                    </ag-grid-angular>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                <div class="btn-group mr-2" role="group" aria-label="First group">
                    <button type="button" class="btn mt-4 close-btn" (click)="closemodal()">CLOSE</button>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale" [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>