import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class RseImportpoServiceService extends BaseService{

  constructor(private http:HttpClient) { 
    super()
  }
  submitBulkUploadList(obj:any){
    let url= this.baseUrl+"rse/";
    return this.http.post(url,obj)
  }
}
