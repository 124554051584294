<div class="page_heading">
    <div class="warranty">
        <div class="row">
            <div class="col">
                <h3>PURCHASE ORDER</h3>
            </div>
            <div class="col-3">
                <h4 class="float-right">NEED BY DATE : {{selectedNeedByDateShow}}</h4>
            </div>
            <div class="warranty col text-right">
                <strong>Organization Type: <span class="badge badge-info"
                        [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'"> {{orgType}}</span></strong> &nbsp;&nbsp;
                <strong>Logged User: <span class="badge badge-info" [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'">
                        {{loggedUser}}</span></strong> &nbsp;&nbsp;
          
            </div>

        </div>
    </div>
</div>
<div class="col">
    <div class="d-flex justify-content-end">
        <button type="button" class="btn mt-4 mr-3 close-btn" (click)="backRoute()">BACK</button>
    </div>
</div>
<div class="container">
    <div class="mt-4">
        <ag-grid-angular #agGrid id="myGrid"
            [ngClass]="isDarkTheme==='light' ? 'ag-theme-balham': 'ag-theme-balham-dark'" [rowData]="rowData"
            domLayout="autoHeight" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true"
            [animateRows]="true" [rowSelection]="rowSelection" [enableRangeSelection]="true" [sideBar]="sideBar"
            [masterDetail]="true" [paginationPageSize]="paginationPageSize"
            [detailCellRendererParams]="detailCellRendererParams" [getRowHeight]="getRowHeight">
        </ag-grid-angular>
    </div>
</div>

<div id="myNav" class="overlay">
    <a href="javascript:void(0)" class="closebtn" id="closebtn" (click)="closemodal()">&times;</a>
    <div class="overlay-content" id="content">

        <div class="materialdata">
            <!-- <h4>Shipment Details</h4> -->
            <div class="side-heading">

                <div class="d-flex">

                    <h4>SHIPMENT DETAILS</h4>

                </div>

            </div>
            <div>
                <!-- <input type="text" name="" id=""> -->

                <div class="mt-4" *ngIf="shippingData.length>0">
                    <form #shipmentsSplit="ngForm" id="splitShipments" (ngSubmit)="submitsplit(shipmentsSplit)">

                        <div class="">
                            <div class="card card-body shadow rounded my-2 pt-3 px-3"
                                [ngClass]="isDarkTheme === 'light' ? 'multiselect':'card-body-dark'"
                                *ngFor="let model1 of modelShipments; let i=index">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="" class="float-left">Shipment No</label>
                                            <input type="text" class="form-control poinput"
                                                [name]="'shpmentno'+model1.serialNum" [(ngModel)]="model1.serialNum"
                                                disabled>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="" class="float-left">UOM</label>
                                            <input type="text" class="form-control poinput" [name]="'uom'+model1.serialNum"
                                                [(ngModel)]="model1.uom" disabled>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="" class="float-left">Quantity</label>
                                            <input type="number" class="form-control poinput" [name]="'qty'+model1.serialNum"
                                                [(ngModel)]="model1.quantity" #qty="ngModel" disabled>

                                            <!-- <div class="text-danger " *ngIf="qty.invalid &&(shipmentsSplit.submitted)">
                                                    <small class="float-left">Please Enter
                                                        Quantity</small>
                                                </div> -->
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="" class="float-left">Need by Date</label>
                                            <input type="date" class="form-control poinput"
                                                [name]="'nedbydate'+model1.serialNum" [(ngModel)]="model1.needbydate"
                                                [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                                                #date="ngModel" required>

                                            <div class="text-danger " *ngIf="date.invalid &&(shipmentsSplit.submitted)">
                                                <small class="float-left">Please Enter
                                                    Need By Date</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="" class="d-flex">Delivery Site</label>
                                            <div class="form-group">
                                                <ng-multiselect-dropdown [placeholder]="'Select Delivery Site'"
                                                    class="multiselect" [name]="'selectdeliverysite'+model1.serialNum"
                                                    #ds="ngModel" [(ngModel)]="model1.deliverysite"
                                                    [settings]="deliverysitesettings"
                                                    [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                                                    [data]="selectedDeliverySiteCode"
                                                    [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                                                    (onSelect)="onDeliverySiteSelect($event,i)" required>
                                                    <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->

                                                </ng-multiselect-dropdown>

                                                <div class="text-danger "
                                                    *ngIf="ds.invalid &&(shipmentsSplit.submitted)">
                                                    <small class=" float-left">Please Select Delivery Site</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>
                        <!-- <div class="d-flex justify-content-start">
                                <button type="submit" class="btn btn-dark  mt-4 submit ">VALIDATE</button>
                            </div>
                            <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                <div class="btn-group mr-2" role="group" aria-label="First group">
                                    <button type="button" class="btn mt-4 close-btn" (click)="closemodalF(shipmentsSplit)">CLOSE</button>
                                </div>
                            </div> -->

                        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                            <div class="btn-group mr-2" role="group" aria-label="First group">
                                <button type="submit" class="btn btn-dark  mt-4 submit">VALIDATE</button>
                            </div>
                            <div class="btn-group mr-2" role="group" aria-label="Second group">
                                <button type="button" class="btn mt-4 close-btn"
                                    (click)="closemodalF(shipmentsSplit)">CLOSE</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>


        </div>
    </div>
</div>

<form #submitpo="ngForm" id="submitpo" class="container mt-4" (ngSubmit)="SubmitConsolidatePo(submitpo)">
    <div class="row">

        <div class="col">
            <div class="form-group">
                <label for="">Raise Consolidate PO</label>
                <ng-multiselect-dropdown [placeholder]="'Select'" class="multiselect" name="selectraiseconsolidate"
                    #rc="ngModel" [(ngModel)]="raiseConsolidate" [settings]="raiseConsolidateSettings"
                    [data]="raiseConsolidateData" (onSelect)="onmanufactureSelect($event)"
                    [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                    (onDeSelect)="onmanufactureDeSelect($event)" required>
                    <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->

                </ng-multiselect-dropdown>
                <div class="text-danger " *ngIf="rc.invalid &&(submitpo.submitted)">
                    <small class="float-left">Please Select Manufacturing Hub</small>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label for="">Site Code</label>
                <ng-multiselect-dropdown [placeholder]="'Select'" class="multiselect" name="sitecode" #sc="ngModel"
                    [(ngModel)]="siteCode" [settings]="siteCodeSettings" [data]="siteCodeData"
                    [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" required>
                    <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->

                </ng-multiselect-dropdown>
                <div class="text-danger " *ngIf="sc.invalid &&(submitpo.submitted)">
                    <small class="float-left">Please Select Site Code</small>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label for="">PO Number</label>
                <input type="text" class="form-control" name="po" [(ngModel)]="poNumber" #ponum="ngModel" required>
                <div class="text-danger " *ngIf="ponum.invalid &&(submitpo.submitted)">
                    <small class="float-left">Please Select PO Number</small>
                </div>
            </div>

        </div>
        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group mr-2" role="group" aria-label="First group">
                <button type="submit" class="btn btn-dark  mt-4 mb-4 submit ">SUBMIT</button>
            </div>


        </div>
    </div>

   

</form>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale" [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>