<div class="page_heading d-flex">
    <div class="warranty col">
        <h3>CONSOLIDATE BY</h3>
    </div>
    <div class="warranty col text-right">
        <strong>Organization Type: <span class="badge badge-info"
                [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'"> {{orgType}}</span></strong> &nbsp;&nbsp;
        <strong>Logged User: <span class="badge badge-info" [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'">
                {{loggedUser}}</span></strong> &nbsp;&nbsp;
    
    </div>
</div>
<div class="container">
    <form name="form" #needByDate="ngForm" (ngSubmit)="submitClick(needByDate)">
        <div class="row mt-3">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">Need by Date</label>
                    <input type="date" class="form-control" name="needbydate" id="NeedByDate" [(ngModel)]="needbydate"
                        #date="ngModel" (change)="onDateSelect($event)"
                        [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" ngModel>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">Item Name</label>
                    <ng-multiselect-dropdown [placeholder]="'Select'" class="multiselect" name="itemName" #ds="ngModel"
                        [(ngModel)]="itemNameSelect" [settings]="itemNameSettings" [data]="itemNameData"
                        [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                        (onSelect)="onItemNameSelect($event)">
                        <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->

                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                <div class="btn-group mr-2" role="group" aria-label="First group">
                    <button type="submit" class="btn btn-dark  mt-4 mb-4 submit ">SUBMIT</button>
                </div>
    
    
            </div>

        </div>
    </form>
</div>
<div class="pageHead" *ngIf="showPODetails">
    <div class="warranty">
        <h3>CREATE PURCHASE ORDER</h3>
    </div>
</div>
<div class="container" *ngIf="showPODetails">
    <div>
        <ag-grid-angular #agGrid id="myGrid"
            [ngClass]="isDarkTheme==='light' ? 'ag-theme-balham': 'ag-theme-balham-dark'" [rowData]="rowData"
            domLayout="autoHeight" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true"
            [animateRows]="true" [rowSelection]="rowSelection" [enableRangeSelection]="true" [sideBar]="sideBar"
            [masterDetail]="true" [paginationPageSize]="paginationPageSize"
            [detailCellRendererParams]="detailCellRendererParams" [getRowHeight]="getRowHeight"
            (selectionChanged)="onSelectionChanged($event)">
        </ag-grid-angular>
    </div>
</div>
<div class="row float-right setMargin" *ngIf="showPODetails">
    <div class="col-sm-12 col-lg-2 col-md-2">
        <div class="form-group">
            <button type="submit" class="btn btn-dark mt-4 submit" (click)="validateClick()">ADD COMPONENTS</button>
        </div>
    </div>
</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale" [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>