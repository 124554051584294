import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ICellRendererParams, SelectionChangedEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import {ConsolidatepoService} from 'src/app/services/consolidatepo.service'
import { ThemeService } from 'src/app/themes/theme.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-consolidate-po',
  templateUrl: './consolidate-po.component.html',
  styleUrls: ['./consolidate-po.component.scss']
})
export class ConsolidatePoComponent implements OnInit {
  needbydate:any;
  poDetails: any;
  showPODetails:boolean=false;
  columnDefs
  defaultColDef;
  columnDefs1;
  pagination = true;
  paginationPageSize;
  rowData: any = [];
  shippingData: any = [];
  poType;
  userId;
  status1;
  domLayout: any;
  rowSelection;
  autoGroupColumnDef;
  maxBlocksInCache;
  sideBar;
  userType = "BUYER";
  isDarkTheme;
  detailCellRendererParams;
  getRowHeight;
  gridApi: any;
  selectedRowsData=[];
  LoggedUserDetails: any;
  loggedUser;
  orgType
  constructor(private router: Router,private authService : AuthService,private consolidatePOService : ConsolidatepoService,private themeService: ThemeService,private toastr: ToastrService, private spinner : NgxSpinnerService) {
    this.domLayout = 'autoHeight';

    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.rowSelection = 'multiple'

    this.autoGroupColumnDef = {
      icons: {
        menu: '<em class="fa fa-shower"/>',
        filter: '<em class="fa fa-long-arrow-alt-up"/>',
        columns: '<em class="fa fa-snowflake"/>',
        sortAscending: '<em class="fa fa-sort-alpha-up"/>',
        sortDescending: '<em class="fa fa-sort-alpha-down"/>',
      },
      headerCheckboxSelection: true,
      width: 300,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
      checkbox: true,
    },
    },
    
      this.paginationPageSize = 10;
    this.maxBlocksInCache = 1;
    this.pagination = true;
    this.sideBar = {
      toolPanels: [

        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },


        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },


      ],

    };
   }

  ngOnInit(): void {
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;
    });
    this.LoggedUserDetails = this.authService.getLoginDetails()
    this.showPOs()
    this.loggedUser = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).name;
    this.orgType = sessionStorage.getItem("orgName")
  }

  showPOs() {
    this.columnDefs = [
      //  {
      //   headerName: 'SELECT', 
      //   field: '', 
      //   filter: 'agSetColumnFilter',
      //   cellRenderer: 'agGroupCellRenderer',
      //   chartDataType: 'category',
      //   headerTooltip: 'SELECT',
      //   checkboxSelection:true
      // },
      {
        headerName: 'PO NUMBER',
        field: 'poNumber',
        filter: 'agSetColumnFilter',
        // checkboxSelection:true,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          checkbox: true,
        },    
        chartDataType: 'category',
        headerTooltip: 'PO NUMBER',
        tooltipField: 'poNumber',
        // checkboxSelection:true
      },
      {
        headerName: 'SALE ORDER NO',
        field: 'salesOrderNumber',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'SALE ORDER NO',
        tooltipField: 'salesOrderNumber',

      },
      {
        headerName: 'SO CREATION DATE', 
        field: 'poDate', 
        filter: true, 
        headerTooltip: 'SO CREATION DATE',
        tooltipField: 'poDate',
      },
      {
        headerName: 'CUSTOMER NAME',
        field: 'customerName',
        filter: true, 
        headerTooltip: 'CUSTOMER NAME',
        tooltipField: 'customerName',
      },
      {
        headerName: 'CUSTOMER SITE NAME',
        field: 'customerSiteName', 
        filter: true, 
        headerTooltip: 'CUSTOMER SITE NAME',
        tooltipField: 'customerSiteName',
      },
     
    ];
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: 'LINE #', 
            field: 'lineno',
            headerTooltip: 'LINE #',
            tooltipField: 'lineno',
            onCellClicked: this.popupopen.bind(this),
            cellRenderer: (params: ICellRendererParams) => {
              return `<strong><a class="text-center d-flex" >${params.value} </a></strong>`;

            },
          },
          {
            headerName: 'ITEM NAME', field: 'itemName',
            headerTooltip: 'ITEM NAME',
            tooltipField: 'itemName',

          },
          {
            headerName: 'UOM', field: 'primaryUom',
            headerTooltip: 'UOM',
            tooltipField: 'primaryUom',

          },
          {
            headerName: 'QUANTITY', field: 'quantity',
            headerTooltip: 'QUANTITY',
            tooltipField: 'quantity',
          },
        ],
        defaultColDef: {
          flex: 1, searchable: false, floatingFilter: false, sortable: true, resizable: true, enableRangeSelection: true, popupParent: document.body
        },

      },

      getDetailRowData(params) {
        params.successCallback(params.data.lineItems);

      },

    };
    this.getRowHeight = params => {
      const defRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
      if (params.node.detail) {
        const offset = 100;
        const detailRowHeight =
          params.data.lineItems.length * defRowHeight;
        var gridSizes = params.api.getSizesForCurrentTheme();
        return detailRowHeight + gridSizes.headerHeight + offset;
      } else {
        return defRowHeight;
      }
    }
    this.rowData = this.poDetails
  }
  popupopen(data) {
    console.log(data.data.shipping, "data")
    this.shippingData = data.data.shipping;
    this.columnDefs1 = [
      {
        headerName: 'SHIPMENT #',
        field: 'shipmentNumber',
        filter: 'agSetColumnFilter',
        chartDataType: 'category',
        headerTooltip: 'SHIPMENT #',
        tooltipField: 'shipmentNumber',

      },
      {
        headerName: 'QUANTITY',
        field: 'quantity',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'QUANTITY',
        tooltipField: 'quantity',

      },
   
      {
        headerName: 'NEED BY DATE', field: 'needByDate', filter: true, headerTooltip: 'NEED BY DATE',
        tooltipField: 'needByDate',
      },
      {
        headerName: 'DELIVERY SITE', field: 'shiptoLocation', filter: true, headerTooltip: 'DELIVERY SITE',
        tooltipField: 'shiptoLocation',
      },
  
    ];
    document.getElementById('myNav').style.width = '62%';
  }
  closemodal() {
    document.getElementById('myNav').style.width = '0';
  }
  submitNeedByDate(form:NgForm){
   if(form.invalid){
     return;
   }
   let obj={
     'needByDate':moment(this.needbydate).format('DD-MM-YYYY')
   }
   this.spinner.show()
   this.consolidatePOService.getConsolidatePoDetails(obj).subscribe((resp:any)=>{
    this.spinner.hide()
    if(resp.status==='Success'){
      this.poDetails = resp.data
      console.log(this.poDetails)
      this.showPODetails=true;
      this.showPOs()
    }else{
      this.showPODetails=false;
      this.rowData=[]
      if (this.isDarkTheme === 'light'){
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          text: resp.message,//COntact Administrator
          width: 500,
        });
      }else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>"+  resp.message + "</p>",
          width: 500,
          background : '#333',
          iconColor:'#fff',
          confirmButtonColor:'#272727',
          
          


        });
      }
    }
   },(err)=>{
     this.spinner.hide()
     this.showPODetails=false;
     this.rowData=[]
    
    if (this.isDarkTheme === 'light'){
      Swal.fire({

        heightAuto: false,
        icon: 'error',
        text: err.error.errorMessage,//COntact Administrator
        width: 500,
      });
    }else if (this.isDarkTheme === 'dark') {
      Swal.fire({

        heightAuto: false,
        icon: 'error',
        html: "<p style='color:#fff'>"+ err.error.errorMessage + "</p>",
        width: 500,
        background : '#333',
        iconColor:'#fff',
        confirmButtonColor:'#272727',
        
        


      });
    }
   })


  }



validateClick(){
  if(this.selectedRowsData.length>0){
    // this.spinner.show()
    let poref = this.selectedRowsData.map((x:any)=>x.poRefNumber)
    // console.log(poref)
    this.router.navigate(['consolidatePoPreview'],{queryParams:{consolidate:JSON.stringify(poref),needbydate:this.needbydate}})
 
  }
  else{
   
    if (this.isDarkTheme === 'light'){
      Swal.fire({

        heightAuto: false,
        icon: 'error',
        text: 'Please Select Atleast One Order',//COntact Administrator
        width: 500,
      });
    }else if (this.isDarkTheme === 'dark') {
      Swal.fire({

        heightAuto: false,
        icon: 'error',
        html: "<p style='color:#fff'>"+ 'Please Select Atleast One Order' + "</p>",
        width: 500,
        background : '#333',
        iconColor:'#fff',
        confirmButtonColor:'#272727',
        
        


      });
    }
    return;
  }
}
onSelectionChanged(event: SelectionChangedEvent) {
  let x = event.api.getSelectedNodes()
  this.selectedRowsData = x.map((a:any)=>a.data)
}
}
