import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ICellRendererParams } from 'ag-grid-community';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { addShipments, packageInfo, RsePoDetails } from 'src/app/models/rse-po-details';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/themes/theme.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-asncm',
  templateUrl: './asncm.component.html',
  styleUrls: ['./asncm.component.scss']
})
export class AsncmComponent implements OnInit {
  user: any;
  setFlag;
  showData = false;
  disabled = false;
  public fileobject: File;
  public fileobject1: File;
  bulkUploadArray: any = [];
  account: any;
  resultData: any = [];
  total_po = 0;
  updated_po = 0;
  failed_po = 0;
  flag = true;
  gridColumnApi;
  columnDefs;
  rowData: any = [];
  defaultColDef
  gridApi;
  imgPath
  detailCellRendererParams;
  rowHeight;
  pagination = true;
  paginationPageSize;
  sideBar;
  isDarkTheme: string;
  isSubmit = false;
  rowSelection;
  selectedData: any = []
  selectedPortArrival: any = []
  portArrivalSettings = {};
  portArrivalDropdown: any = [];
  selectedPortDepature: any = [];
  portDepartureSettings = {};
  selectedSettings = {};
  portDepartureDropdown: any = [];
  leadingDays: any;
  shipmentsDate: any;
  asnNumber: any;
  documentDate: any;
  psNumber: any;
  bolNumber: any;
  abNumber: any
  selectedLogisticsName: any = [];
  logisticsSettings = {};
  logisticsNameDropdown: any = [];
  requestPickDate: any;
  reqLeadDays: any;
  model = new RsePoDetails()
  model1 = new packageInfo()
  CertificateType: any = []
  CertificateTypeDropdown = {}
  CertificateAuthority: any = []
  CertificateAuthorityDropDown = {}
  portArrivalData: any = [];
  selected_row: any
  model3 = { length: null, width: null, height: null };
  packagemodel = { file: File, inputArgs: this.model };
  uploadcertificate: string;
  issuedate;
  mode;
  thirdPartySettings = {}
  tpwdata: any = [];
  loggedUser;
  orgType;
  constructor(private spinner: NgxSpinnerService, private themeService: ThemeService, private service: AdminService, private authService: AuthService, private admin: AdminService) {
    this.columnDefs = [
      {
        headerName: 'SELECT',
        field: '',
        headerTooltip: 'SELECT',
        checkboxSelection: params => params.data.bol === 'Not Generated'
      },
      {
        headerName: 'PACKAGE & CERTIFICATE INFO',
        headerTooltip: 'PACKAGE & CERTIFICATE INFO',
        field: 'packageInfo',
        onCellClicked: this.onAdd.bind(this),
        cellRenderer: (params: ICellRendererParams) => {
          if (params.data.packageInfo === 'Not Added') {
            return `<button style="background: #007009;border: 0px;color: #fff;text-align: center;padding: 0px 10px;font-weight: bold;color: var(--button-text-color);">ADD</button>`
          }
          else {
            return `<button style="background:#00700963;border: 0px;color: #fff;text-align: center;padding: 0px 10px;font-weight: bold;color: var(--button-text-color);"disabled>ADD</button>`
          }
        },
        tooltipField: 'ADD',
      },
      {
        headerName: 'PURCHASE ORDER',
        headerTooltip: 'PURCHASE ORDER',
        field: 'poNumber',
        tooltipField: 'poNumber',
      },
      {
        headerName: 'LINE #',
        headerTooltip: 'LINE #',
        field: 'lineno',
        tooltipField: 'lineno',
      },
      {
        headerName: 'SHIP #',
        headerTooltip: 'SHIP #',
        field: 'shipmentNumber',
        tooltipField: 'shipmentNumber',
      },
      {
        headerName: 'ITEM',
        headerTooltip: 'ITEM',
        field: 'itemName',
        tooltipField: 'itemName',
      },
      {
        headerName: 'UOM',
        headerTooltip: 'UOM',
        field: 'primaryUom',
        tooltipField: 'primaryUom',
      },
      {
        headerName: 'QUANTITY',
        headerTooltip: 'QUANTITY',
        field: 'quantity',
        tooltipField: 'quantity',
      },
      {
        headerName: 'CONSOLIDATION STATUS',
        headerTooltip: 'CONSOLIDATION STATUS',
        field: 'consildationStatus',
        tooltipField: 'consildationStatus',
      },
      {
        headerName: 'DELIVERY SITE',
        headerTooltip: 'DELIVERY SITE',
        field: 'shiptoLocation',
        tooltipField: 'shiptoLocation',
      },
      {
        headerName: 'BOL #',
        headerTooltip: 'BOL #',
        field: 'bol',
        tooltipField: 'bol',
      },
      {
        headerName: 'AWB #',
        headerTooltip: 'AWB #',
        field: 'awbno',
        tooltipField: 'awbno',
      },
      {
        headerName: 'VIEW CODE',
        headerTooltip: 'VIEW CODE',
        field: 'code',
        tooltipField: 'code',
        onCellClicked: (event: any) => {
          if (event.data.code != undefined) {
            this.viewQR(event)
          }
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value != undefined) {
            return `<strong><a class="text-center d-flex" > VIEW QR </a></strong>`
          }
          else {
            return 'Not Generated'
          }
        },
      },
    ]
    this.rowSelection = 'multiple'
    this.paginationPageSize = 10;
    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.sideBar = {
      toolPanels: [

        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },


        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },


      ],

    };
  }
  onAdd(params) {
    this.model.certificateType = null;
    this.model.certificateAuthority = null;
    this.uploadcertificate = null;
    this.packagemodel.file = null;
    this.selected_row = params.data;
    let data = [{ item_id: 'Metre', item_text: 'Metre' },
    { item_id: 'Centimetre', item_text: 'Centimetre' },
    { item_id: 'Millimetre', item_text: 'Millimetre' },
    { item_id: 'Inch', item_text: 'Inch' },
    { item_id: 'Foot', item_text: 'Foot' },
    { item_id: 'Yard', item_text: 'Yard' },

    ]
    this.selectedData = data
    this.selectedSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    let data1 = [{ item_id: 'UL Certificate (U.S)', item_text: 'UL Certificate (U.S)' },
    { item_id: 'CE Certificate (EU)', item_text: 'CE Certificate (EU)' },
    { item_id: 'FCC Certificate (International)', item_text: 'FCC Certificate (International)' },
    { item_id: 'ROHS Certificate (EU)', item_text: 'ROHS Certificate (EU)' },


    ]
    this.CertificateType = data1;
    this.CertificateTypeDropdown = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    let data2 = [{ item_id: 'Federal communications comissions', item_text: 'Federal communications comissions' },
    { item_id: 'Underwriters Laboratories', item_text: 'Underwriters Laboratories' },
    { item_id: 'Conformite Europeenne', item_text: 'Conformite Europeenne' },


    ]
    this.CertificateAuthority = data2;
    this.CertificateAuthorityDropDown = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    document.getElementById('myNav').style.width = '60%';
  }

  ngOnInit(): void {
    this.showData = false;
    this.loggedUser = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).name;
    this.orgType = sessionStorage.getItem("orgName")
    this.user = this.authService.getLoginDetails();
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;
    }); this.getArrivals();
    this.portDeparture();
    this.logistics();
    this.tpwdata = [{ 'item_id': "tpw", 'item_text': "Third Party WareHouse" }];
    this.thirdPartySettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
  }
  getArrivals() {
    this.spinner.show();
    this.service.getArrival().subscribe((res: any) => {
      this.spinner.hide();
      let ports: any = [];
      for (let i = 0; i < res.data.length; i++) {
        ports[i] = { "item_id": res.data[i].Arrival, "item_text": res.data[i].Arrival }
      }
      this.portArrivalData = ports;
      this.portArrivalSettings = {
        singleSelection: true,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        closeDropDownOnSelection: true
      };
    })
  }
  portDeparture() {
    this.spinner.show();
    this.service.PortofDeparure().subscribe((res: any) => {
      this.spinner.hide();
      let ports: any = [];
      for (let i = 0; i < res.data.length; i++) {
        ports[i] = { "item_id": res.data[i].Departure, "item_text": res.data[i].Departure }
      }
      this.portDepartureDropdown = ports;
      this.portDepartureSettings = {
        singleSelection: true,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        closeDropDownOnSelection: true
      };
    })
  }
  logistics() {
    this.spinner.show();
    this.service.Logistics().subscribe((res: any) => {
      this.spinner.hide();
      let ports: any = [];
      for (let i = 0; i < res.data.length; i++) {
        ports[i] = { "item_id": res.data[i].ParticipantID, "item_text": res.data[i].ParticipantName }
      }
      this.logisticsNameDropdown = ports;
      this.logisticsSettings = {
        singleSelection: true,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        closeDropDownOnSelection: true
      };
    })
  }
  onPastDue() {
    this.showData = true;
    this.setFlag = 'past';
    this.spinner.show();
    this.service.getCmASN(this.user.userRoledcorg, 'past').subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 'Success') {
        this.rowData = res.data
        this.documentDate = moment().format('DD-MM-YYYY');
        console.log(this.documentDate, "date")
      }
      else {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'ERROR',
            text: res.message,
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            html: '<strong>' + res.message + '</strong>',
            icon: 'error',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
      }
    })
  }
  viewQR(data: any) {

    let req = {

    }
    this.imgPath = this.service.getfiledownload(data.data.code);
    document.getElementById('imgNav').style.width = '25%';
  }
  onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    let selectedPo = this.gridApi.getSelectedRows()
    if (selectedPo.length === 0) {
      
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          text: 'Please select atleast one PO to create ASN',
          icon: 'warning'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({
          html: '<strong>' + 'Please select atleast one PO to create ASN' + '</strong>',
          icon: 'warning',
          background: '#333',
          iconColor: '#fff',
        })
        
      }
      return;
    }
    for (let i = 0; i < selectedPo.length; i++) {
      if (selectedPo[0]['poNumber'] != selectedPo[i]['poNumber']) {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            text: 'Please select same PO to create ASN',
            icon: 'warning'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            html: '<strong>' + 'Please select same PO to create ASN' + '</strong>',
            icon: 'warning',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
        return;
      }
    }
    let shipments = []
    for (let i = 0; i < selectedPo.length; i++) {
      if (selectedPo[i].packageInfo == 'Not Added') {
        const data = selectedPo[i].shipmentNumber;
       
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            text: 'Please add Package-Info' + ' - ' + data,
            icon: 'warning'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            html: '<strong>' + 'Please add Package-Info' + ' - ' + data + '</strong>',
            icon: 'warning',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
        return;
      }
      let row = { 'poRefNumber': selectedPo[i].poRefNumber, 'lineno': selectedPo[i].lineno, 'shipmentNumber': selectedPo[i].shipmentNumber, 'orgID': selectedPo[i].orgID, 'poNumber': selectedPo[i].poNumber }
      shipments.push(row);
    }
    if (this.model.fileUpload === undefined || this.model.fileUpload === null) {
    
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          text: 'Please attach bill of lading',
          icon: 'warning'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({
          html: '<strong>' + 'Please attach bill of lading' + '</strong>',
          icon: 'warning',
          background: '#333',
          iconColor: '#fff',
        })
        
      }
      return
    }

    let validate_model: any = []
    for (let i = 0; i < shipments.length; i++) {
      let poRefNumber = shipments[i]['poRefNumber'];
      let lineno = shipments[i]['lineno'];
      let shipmentNumber = shipments[i]['shipmentNumber'];
      let orgID = shipments[i]['orgID'];
      validate_model.push(new addShipments(poRefNumber, lineno, shipmentNumber, orgID))


    }
    // let objectData = {
    //   "orgName": JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).userRoledcorg,
    //   "shipments": validate_model
    // }
    // this.spinner.show()
    // this.service.postCmASNvalidate(objectData).subscribe((res: any) => {
    //   this.spinner.hide()
    //   if (res.status === 'Success') {
       
    //   }
    //   else {
    //     Swal.fire({
    //       title: 'Error',
    //       text: res.message,
    //       icon: 'error'
    //     })
    //   }
    // }, (err) => {
    //   this.spinner.hide()
    //   Swal.fire({
    //     heightAuto: false,
    //     icon: 'error',
    //     text: err.error.errorMessage,
    //     width: 500
    //   })
    // })
    let inputArgs = {}
    inputArgs = {
      "shipments": shipments,
      "asnDetails": {
        'portofArrival': this.selectedPortArrival[0].item_id,
        'portofDeparture': this.selectedPortDepature[0].item_id,
        'leadDays': this.leadingDays,
        'shipmentsDate': this.shipmentsDate,
        'asnNumber': this.asnNumber,
        'documentDate': this.documentDate,
        'packingSlipNumber': this.psNumber,
        'billofLadingNumber': this.bolNumber,
        'awbNumber': this.abNumber,
        'toOrgName': this.selectedLogisticsName.item_text,
        'toOrgID': this.selectedLogisticsName.item_id,
        'requestPickupdate': this.requestPickDate,
        'requestLeadDays': this.reqLeadDays,
        'asnCreatedDate': this.documentDate,
        'asnCreatedBy': this.user.email,
        'asnCreatedSite': this.user.sitecode,
        'asnCreatedOrgID': this.user.userRoledcorg
      },
      "tpw": {
        "tpwID": this.tpwdata[0].item_id,
        "tpwName": this.tpwdata[0].item_text,

        "reqReleaseDate": this.model.requestPickDate,
        "reqStorageVolume": this.model.reqStorageVolume,
        "reqStorageDays": this.model.reqStorageDays,
        "estimatedArrival": this.model.estimatedarrival
      }
    }
    const formData = new FormData();
    formData.set('file', this.fileobject1);
    formData.set('inputArgs', JSON.stringify(inputArgs));
    this.spinner.show()
    this.service.uploadCMASN(formData).subscribe((res: any) => {
      this.spinner.hide()
      if (res.status === 'Success') {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Success',
            html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
            icon: 'success'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            html: '<strong>' +res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
            icon: 'success',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
        form.resetForm();
        this.reset();
        this.onPastDue();//refresh
      }
      else {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: res.message,
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            html: '<strong>' +res.message+ '</strong>',
            icon: 'error',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
      }
    }, (err) => {
      this.spinner.hide()
      
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({
          html: '<strong>' +err.error.errorMessage+ '</strong>',
          icon: 'error',
          background: '#333',
          iconColor: '#fff',
        })
        
      }
    })
  }
  closemodal() {
    document.getElementById('myNav').style.width = '0';
  }
  reset() {
    // this.resultData=[];
    this.model.fileUpload = null;
    this.fileobject = null;
    this.showData = false
    // this.isSubmit=false;
  }
  submit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    let objectData = {
      'reader': this.mode,
      'poRefNumber': this.selected_row.poRefNumber,
      'shipmentRefNumber': this.selected_row.shipmentNumber,
      'fullfillmentEmpID': JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email,
      'certificateAuthority': this.model.certificateAuthority[0].item_id,
      'issuedOn': this.issuedate,
      'certificateType': this.model.certificateType[0].item_id,
      'orgName': this.user.userRoledcorg,
      'itemDimensions': {
        'length': this.model1.length + this.model3.height[0].item_id,
        'width': this.model1.width + this.model3.height[0].item_id,
        'height': this.model1.height + this.model3.height[0].item_id
      }
    }

    const req = new FormData();
    req.append('file', this.fileobject);
    req.append('inputArgs', JSON.stringify(objectData));
    console.log(req, "request datat")
    this.spinner.show()
    this.service.putPackageInfoCM(req).subscribe((res: any) => {
      this.spinner.hide()
      if (res.status === "Success") {
       
         
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          title: 'Success',
          html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
          icon: 'success'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({
          html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
          icon: 'success',
          background: '#333',
          iconColor: '#fff',
        })
        
      }
        form.reset();
        this.closemodal();
        this.onPastDue();
      }
      else {
        
            
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          title: 'Error',
          text: res.message,
          icon: 'error'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({
          html: '<strong>' + res.message + '</strong>',
          icon: 'error',
          background: '#333',
          iconColor: '#fff',
        })
        
      }
      }
    }, (err) => {
      this.spinner.hide()
     
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({
          html: '<strong>' + err.error.errorMessage + '</strong>',
          icon: 'error',
          background: '#333',
          iconColor: '#fff',
        })
        
      }
    })

  }
  onPortOfArrivalSelect(event: any) {
    console.log(event)
    this.leaddays();
  }
  onPortOfDepartureSelect(event: any) {
    this.leaddays()
  }
  onLogisticsNameSelect(event) {
    this.selectedLogisticsName = event
    console.log(this.selectedLogisticsName, "logistic")
  }
  leaddays() {
    if (this.selectedPortArrival.length == 0 || this.selectedPortDepature.length == 0) {
      console.log("if case", this.selectedPortArrival, this.selectedPortDepature)
      
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          text: "Please select both Port of Arrival & Port of Departure for Leading Days",
          icon: 'info'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({
          html: '<strong>' + "Please select both Port of Arrival & Port of Departure for Leading Days" + '</strong>',
          icon: 'info',
          background: '#333',
          iconColor: '#fff',
        })
        
      }
    }
    else {
      console.log("else case", this.selectedPortArrival, this.selectedPortDepature)
      this.service.LeadingDays(this.selectedPortArrival[0].item_id, this.selectedPortDepature[0].item_id).subscribe((res: any) => {
        if (res.data.length == 0) {
          this.leadingDays = "0"
        }
        else {
          this.leadingDays = res.data[0].LeadDays
        }
      })
    }
  }
  onuploadcertificate(e) {

    console.log(e);
    console.log('File Object:', e.target.files[0]);
    this.fileobject = e.target.files[0];

    // console.log("File Object:", this.invoiceObject5);
  }
  onChange(e) {
    this.fileobject1 = e.target.files[0];
  }
  onItemSelect1(e) { }
  closemodal2() {
    document.getElementById('imgNav').style.width = '0';
  }
  onItemDeSelect1(e) { }
}
