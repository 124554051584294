<div class="page_heading">
    <div class="warranty">
        <div class="row">
            <div class="col">
                <h3>ADD COMPONENTS</h3>
            </div>
            <div class="col-3">
                <h4 class="float-right">NEED BY DATE : {{selectedNeedByDateShow}}</h4>
            </div>
            <div class="warranty col text-right">
                <strong>Organization Type: <span class="badge badge-info"
                        [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'"> {{orgType}}</span></strong> &nbsp;&nbsp;
                <strong>Logged User: <span class="badge badge-info" [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'">
                        {{loggedUser}}</span></strong> &nbsp;&nbsp;
            
            </div>

        </div>
    </div>
</div>
<div class="col">
    <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-dark mt-4 mr-3 close-btn" (click)="backRoute()">BACK</button>
    </div>
</div>
<div class="container">
    <div>
<!-- 

        <div class="mt-4">
            <form #shipmentsSplit="ngForm" id="splitShipments" (ngSubmit)="submitsplit(shipmentsSplit)">

           
             
            </form>
        </div>
    </div>


</div> -->
<div class="">
    <form #submitpo="ngForm" id="submitpo" class="mt-4" (ngSubmit)="SubmitConsolidatePo(submitpo)">
        <div class="card card-body rounded my-2 pt-3 px-3"  [ngClass]="isDarkTheme === 'light' ? 'multiselect':'card-body-dark'"
        *ngFor="let model1 of modelShipments; let i=index">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">Line No</label>
                    <input type="text" class="form-control poinput" [name]="'line'+model1.serialNum"
                        [(ngModel)]="model1.lineNo" #line required>
                        <div class="text-danger " *ngIf="line.invalid &&(submitpo.submitted)">
                            <small class="float-left">Please Enter
                                Line No</small>
                        </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">Component Name</label>
                    <input type="text" class="form-control poinput" [name]="'componentName'+model1.serialNum"
                        [(ngModel)]="model1.componentName" #cname required>
                        <div class="text-danger " *ngIf="cname.invalid &&(submitpo.submitted)">
                            <small class="float-left">Please Enter
                                Component Name</small>
                        </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">UOM</label>
                    <input type="text" class="form-control poinput" [name]="'uom'+model1.serialNum"
                        [(ngModel)]="model1.uom" #uom required>
                        <div class="text-danger " *ngIf="uom.invalid &&(submitpo.submitted)">
                            <small class="float-left">Please Enter
                                UOM</small>
                        </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">Quantity</label>
                    <input type="number" class="form-control poinput"  [name]="'qty'+model1.serialNum"
                        [(ngModel)]="model1.quantity" #qty="ngModel" required>

                    <div class="text-danger " *ngIf="qty.invalid &&(submitpo.submitted)">
                        <small class="float-left">Please Enter
                            Quantity</small>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">Need by Date</label>
                    <input type="date" class="form-control poinput" [name]="'nedbydate'+model1.serialNum"
                        [(ngModel)]="model1.needbydate" #date="ngModel" required>

                    <div class="text-danger " *ngIf="date.invalid &&(submitpo.submitted)">
                        <small class="float-left">Please Enter
                            Need By Date</small>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">Delivery Site</label>
                    <ng-multiselect-dropdown [placeholder]="'Select Delivery Site'" class="multiselect"
                        [name]="'selectdeliverysite'+model1.serialNum" #ds="ngModel"
                        [(ngModel)]="model1.deliverysite" [settings]="deliverysitesettings"
                        [data]="selectedDeliverySiteCode" (onSelect)="onDeliverySiteSelect($event,i)"
                        [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                        required>
                        <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->

                    </ng-multiselect-dropdown>

                    <div class="text-danger " *ngIf="ds.invalid &&(submitpo.submitted)">
                        <small class=" float-left">Please Select Delivery Site</small>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <!-- <button class="btn btn-danger btn-sm float-right" type="button" *ngIf="modelBranch.length > 1"
                    (click)="deleteItem(i)">REMOVE</button> -->
                <!-- <button class="btn btn-success btn-sm setHeight" type="button"
                    (click)="addItem(model1.serialNum)">SPLIT</button> -->
               
                    <button class="bbtn btn-dark submit btn-sm setHeight" type="button" (click)="addItem(model1.serialNum)"
                        *ngIf="i === modelShipments.length - 1">ADD</button>

            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class="btn btn-dark submit btn-sm setHeight" type="button" *ngIf="modelShipments.length > 1"
                    (click)="deleteItem(i)">REMOVE</button>
            </div>
        </div>


    </div>
   
        <div class="row mt-3">
            <div class="col">
                <div class="form-group">
                    <label for="">Select Vendor</label>
                    <ng-multiselect-dropdown [placeholder]="'Select'" class="multiselect" name="selectraiseconsolidate"
                        #rc="ngModel" [(ngModel)]="raiseConsolidate" [settings]="raiseConsolidateSettings"
                        [data]="raiseConsolidateData" (onSelect)="onmanufactureSelect($event)"
                        [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                        (onDeSelect)="onmanufactureDeSelect($event)" required>
                        <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->

                    </ng-multiselect-dropdown>
                    <div class="text-danger " *ngIf="rc.invalid &&(submitpo.submitted)">
                        <small class="float-left">Please Select Manufacturing Hub</small>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="">Select Vendor Site</label>
                    <ng-multiselect-dropdown [placeholder]="'Select'" class="multiselect" name="sitecode" #sc="ngModel"
                        [(ngModel)]="siteCode" [settings]="siteCodeSettings" 
                        [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                        [data]="siteCodeData" required>
                        <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->

                    </ng-multiselect-dropdown>
                    <div class="text-danger " *ngIf="sc.invalid &&(submitpo.submitted)">
                        <small class="float-left">Please Select Site Code</small>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="">Enter PO Number</label>
                    <input type="text" class="form-control" name="po" [(ngModel)]="poNumber" #ponum="ngModel" required>
                    <div class="text-danger " *ngIf="ponum.invalid &&(submitpo.submitted)">
                        <small class="float-left">Please Select PO Number</small>
                    </div>
                </div>

            </div>
            <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                <div class="btn-group mr-2" role="group" aria-label="First group">
                    <button type="submit" class="btn btn-dark  mt-4 mb-4 submit ">SUBMIT</button>
                </div>


            </div>


        </div>
    </form>
</div>



<div>

</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale" [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>