import { Injectable, Inject, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { THEMES, ACTIVE_THEME, Theme } from './symbols';

@Injectable()
export class ThemeService {
    private themeChangedSubject = new BehaviorSubject('light');
    themeChanged = this.themeChangedSubject.asObservable();
    themeChange = new EventEmitter<Theme>();

    constructor(
        @Inject(THEMES) public themes: Theme[],
        @Inject(ACTIVE_THEME) public theme: string
    ) {
    }

    getActiveTheme() {
        const theme = this.themes.find(t => t.name === this.theme);
        if (!theme) {
            throw new Error(`Theme not found: '${this.theme}'`);
        }
        return theme;
    }

    setTheme(name: string) {
        this.theme = name;
        this.themeChange.emit(this.getActiveTheme());
    }
    setTheme2(name: string) {
        this.themeChangedSubject.next(name);
    }

}
