import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[appShowIfLogin]'
})
export class ShowIfLoginDirectiveDirective {
  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService) { }

  @Input() set appShowIfLogin(obj: any) {
    
    const dis = this;
    dis.authService.loginStateChange.subscribe(a => {
      // tslint:disable-next-line:no-shadowed-variable
      const isLoggedIn = dis.authService.getUserLogged();
      console.log(dis.authService.getUserLogged());
      if (!isLoggedIn) {
        dis.viewContainer.clear();
        return;
      }
      dis.viewContainer.createEmbeddedView(dis.templateRef);
    });
    const isLoggedIn = this.authService.getUserLogged();
    console.log(this.authService.getUserLogged());
    if (!isLoggedIn) {
      this.viewContainer.clear();
      return;
    }
    dis.viewContainer.createEmbeddedView(this.templateRef);
  }

}
