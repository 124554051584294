import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ICellRendererParams } from 'ag-grid-community';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { customerDetails } from 'src/app/models/cutomerDetails';
import { RsePoDetails } from 'src/app/models/rse-po-details';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/themes/theme.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-create-asn',
  templateUrl: './create-asn.component.html',
  styleUrls: ['./create-asn.component.scss']
})
export class CreateAsnComponent implements OnInit {
  user: any;
  showData = false;
  public fileobject: File;
  public fileobject1: File;
  bulkUploadArray: any = [];
  account: any;
  resultData: any = [];
  total_po = 0;
  updated_po = 0;
  failed_po = 0;

  gridColumnApi;
  columnDefs;
  rowData: any = [];
  defaultColDef
  gridApi;
  detailCellRendererParams;
  rowHeight;
  pagination = true;
  paginationPageSize;
  sideBar;
  isDarkTheme: string;
  isSubmit = false;
  rowSelection;

  selectedPortArrival: any = []
  portArrivalSettings = {};
  portArrivalDropdown: any = [];
  selectedPortDepature: any = [];
  portDepartureSettings = {};
  portDepartureDropdown: any = [];
  leadingDays: any;
  shipmentsDate: any;
  asnNumber: any;
  documentDate: any;
  psNumber: any;
  bolNumber: any;
  abNumber: any
  selectedLogisticsName: any = [];
  logisticsSettings = {};
  logisticsNameDropdown: any = [];
  requestPickDate: any;
  reqLeadDays: any;
  model = new RsePoDetails()
  warrantyOutboundData: any
  measureLength: any;
  measureWidth: any;
  measureHeight: any;
  measureSettings = {};
  measureDropdown: any = [];
  selectedMeasure: any = [];
  porefnum_package: any = [];
  shiprefnum_package: any = [];
  selectedHeight: any = [];
  certTypeSettings = {};
  certTypeDropdown: any = [];
  selectedCertType: any = [];
  selectedCertAuth: any = [];
  certAuthDropdown: any = [];
  certAuthSettings = {};
  issueDate: any;
  uploadCert: File;
  mode;

  imgPath = ''

  showCustomer1 = false;
  showCustomer2 = false;
  customerModel = new customerDetails()

  mindocumentDate: any;
  loggedUser;
  orgType
  constructor(private spinner: NgxSpinnerService, private themeService: ThemeService, private service: AdminService, private authService: AuthService) {
    this.columnDefs = [
      // {
      //   headerName: 'SELECT',
      //   field: '',
      //   tooltipField: 'sno',
      //   headerTooltip: 'SELECT',
      // },
      {
        headerName: 'SELECT',
        field: '',
        // headerCheckboxSelection: true,
        // filter: 'agSetColumnFilter',
        headerTooltip: 'SELECT',
        checkboxSelection: params => params.data.bol === 'Not Generated'
      },
      {
        headerName: 'PACKAGE & CERTIFICATE INFO',
        headerTooltip: 'PACKAGE & CERTIFICATE INFO',
        field: 'packageInfo',
        cellRenderer: (params: ICellRendererParams) => {
          if (params.data.packageInfo === 'Not Added') {
            return `<button style="background: #007009;border: 0px;color: #fff;text-align: center;padding: 0px 10px;font-weight: bold;color: var(--button-text-color);">ADD</button>`
          }
          else {
            return `<button style="background:#00700963;border: 0px;color: #fff;text-align: center;padding: 0px 10px;font-weight: bold;color: var(--button-text-color);"disabled>ADD</button>`
          }//#00700963
        },
        onCellClicked: (event: any) => {
          if (event.data.packageInfo === 'Not Added') {
            this.addPackageDetails(event)
          }
          else {
            // return;
          }
        },
        tooltipField: 'ADD',
      },
      {
        headerName: 'PURCHASE ORDER',
        headerTooltip: 'PURCHASE ORDER',
        field: 'poNumber',
        tooltipField: 'poNumber',
      },
      {
        headerName: 'LINE #',
        headerTooltip: 'LINE #',
        field: 'lineno',
        tooltipField: 'lineno',
      },
      {
        headerName: 'SHIP #',
        headerTooltip: 'SHIP #',
        field: 'shipmentNumber',
        tooltipField: 'shipmentNumber',
      },
      {
        headerName: 'ITEM',
        headerTooltip: 'ITEM',
        field: 'itemName',
        tooltipField: 'itemName',
      },
      {
        headerName: 'UOM',
        headerTooltip: 'UOM',
        field: 'primaryUom',
        tooltipField: 'primaryUom',
      },
      {
        headerName: 'QUANTITY',
        headerTooltip: 'QUANTITY',
        field: 'quantity',
        tooltipField: 'quantity',
        onCellClicked: (event: any) => {
          this.customerDetailsModal(event)
        },
        cellRenderer: (params: ICellRendererParams) => {
          return `<strong><a class="text-center d-flex" >${params.value} </a></strong>`
        },
        editable: false,
      },
      {
        headerName: 'DELIVERY SITE',
        headerTooltip: 'DELIVERY SITE',
        field: 'shiptoLocation',
        tooltipField: 'shiptoLocation',
      },
      {
        headerName: 'BOL #',
        headerTooltip: 'BOL #',
        field: 'bol',
        tooltipField: 'bol',
      },
      {
        headerName: 'AWB #',
        headerTooltip: 'AWB #',
        field: 'awbno',
        tooltipField: 'awbno',
      },
      {
        headerName: 'VIEW CODE',
        headerTooltip: 'VIEW CODE',
        field: 'code',
        tooltipField: 'code',
        onCellClicked: (event: any) => {
          if (event.data.code != undefined) {
            this.viewQR(event)
          }
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value != undefined) {
            return `<strong><a class="text-center d-flex" > VIEW QR </a></strong>`
          }
          else {
            return 'Not Generated'
          }
        },
      },
    ]
    this.rowSelection = 'multiple'
    this.paginationPageSize = 10;
    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.sideBar = {
      toolPanels: [

        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },


        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },


      ],

    };
  }


  ngOnInit(): void {
    if(JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'supp1-full@rvbl.com'){
      this.loggedUser = 'TIER2 SUPPLIER1 FULLFILLMENT'
    }else if(JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'supp2-full@rvbl.com'){
      this.loggedUser = 'TIER2 SUPPLIER2 FULLFILLMENT'
    }
    else{
      this.loggedUser = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).name
    }
    this.orgType = sessionStorage.getItem("orgName")
    this.showData = false;
    this.user = this.authService.getLoginDetails();
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;
    });
    this.mindocumentDate = new Date();
    this.portArrival();
    this.portDeparture();
    this.logistics();
    this.measure();
    this.certificateType()
    this.certificateAuthority()
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
  }
  portArrival() {
    this.portArrivalSettings = {
      singleSelection: true,
      idField: 'Arrival',
      textField: 'Arrival',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    }
    this.spinner.show();
    this.service.PortofArrival().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 'Success') {
        this.portArrivalDropdown = res.data;
      }
      else {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: res.mesage,
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({

            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + res.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',




          });
        }
      }
    }, (error: any) => {
      this.spinner.hide()
      
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          title: '505 Error',
          text: 'Contact Administrator',
          icon: 'error'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + 'Contact Administrator' + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
    })
  }
  portDeparture() {
    this.portDepartureSettings = {
      singleSelection: true,
      idField: 'Departure',
      textField: 'Departure',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    }
    this.spinner.show();
    this.service.PortofDeparure().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 'Success') {
        this.portDepartureDropdown = res.data;
      }
      else {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: res.mesage,
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
  
            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + res.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',
  
  
  
  
          });
        }
      }
    }, (error: any) => {
      this.spinner.hide()
      
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          title: '505 Error',
          text: 'Contact Administrator',
          icon: 'error'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + 'Contact Administrator' + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
    })
  }
  logistics() {
    this.logisticsSettings = {
      singleSelection: true,
      idField: 'ParticipantID',
      textField: 'ParticipantName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    }
    this.spinner.show();
    this.service.Logistics().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 'Success') {
        this.logisticsNameDropdown = res.data;
      }
      else {
       
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: res.mesage,
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
  
            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + res.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',
  
  
  
  
          });
        }
      }
    }, (error: any) => {
      this.spinner.hide()
     
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          title: '505 Error',
          text: 'Contact Administrator',
          icon: 'error'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + 'Contact Administrator' + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
    })
  }
  measure() {
    this.measureSettings = {
      singleSelection: true,
      idField: 'mid',
      textField: 'mfield',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    }
    this.measureDropdown = [
      { 'mid': 'M', 'mfield': 'Metre' },
      { 'mid': 'CM', 'mfield': 'Centimetre' },
      { 'mid': 'MM', 'mfield': 'Millimetre' },
      { 'mid': 'IN', 'mfield': 'Inch' },
      { 'mid': 'FT', 'mfield': 'Foot' },
      { 'mid': 'Yard', 'mfield': 'Yard' },
    ]
  }
  certificateType() {
    this.certTypeSettings = {
      singleSelection: true,
      idField: 'mid',
      textField: 'mfield',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    }
    this.certTypeDropdown = [
      { 'mid': '1', 'mfield': 'UL Certificate (U.S)' },
      { 'mid': '2', 'mfield': 'CE Certificate (EU)' },
      { 'mid': '3', 'mfield': 'FCC Certificate (International)' },
      { 'mid': '4', 'mfield': 'ROHS Certificate (EU)' },
    ]
  }
  certificateAuthority() {
    this.certAuthSettings = {
      singleSelection: true,
      idField: 'mid',
      textField: 'mfield',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    }
    this.certAuthDropdown = [
      { 'mid': '1', 'mfield': 'Federal communications comissions' },
      { 'mid': '2', 'mfield': 'Underwriters Laboratories' },
      { 'mid': '3', 'mfield': 'Conformite Europeenne' },
    ]
  }
  onCertTypeSelect(event: any) {

  }
  onMeasureSelect(event: any) {

  }
  onCertAuthoritySelect(event: any) {

  }
  onPortOfArrivalSelect(event: any) {
    console.log(event)
    this.leaddays();
  }
  onPortOfDepartureSelect(event: any) {
    this.leaddays()
  }
  onLogisticsNameSelect(event: any) {

  }
  onPastDue() {
    let data = {
      "client_id": 'rw_viewer',
      "username": 'abcspecialcomponents',
      "password": 'RVDAS@0202',
      "grant_type": "password",
      "redirect_uri": 'https://auth.tier1.realwarranty.tech/auth',

    }
    this.service.loginToken(data).subscribe((res: any) => {
      sessionStorage.setItem("warranty_token", res.access_token)
    })
    this.showData = true;
    this.spinner.show();
    this.service.ASNshipments(this.user.userRoledcorg).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 'Success') {
        this.rowData = res.data
        this.documentDate = moment().format('DD-MM-YYYY');
        console.log("today date", this.documentDate)
      }
      else {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'ERROR',
            text: res.message,
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
  
            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + res.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',
  
  
  
  
          });
        }
      }
    })
  }
  async onDueToday() {
    let data = {
      "client_id": 'rw_viewer',
      "username": 'abcspecialcomponents',
      "password": 'RVDAS@0202',
      "grant_type": "password",
      "redirect_uri": 'https://auth.tier1.realwarranty.tech/auth',

    }
    await this.service.loginToken(data).subscribe((res: any) => {
      sessionStorage.setItem("warranty_token", res.access_token)
    })
    this.showData = true;
    this.spinner.show();
    this.service.ASNshipments(this.user.userRoledcorg).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 'Success') {
        this.rowData = res.data
        this.documentDate = moment().format('DD-MM-YYYY');
        console.log("today date", this.documentDate)
      }
      else {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'ERROR',
            text: res.message,
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
  
            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + res.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',
  
  
  
  
          });
        }
      }
    })
  }
  viewQR(data: any) {

    let req = {

    }
    this.imgPath = this.service.getfiledownload(data.data.code);
    document.getElementById('imgNav').style.width = '25%';
  }
  closemodal2() {
    document.getElementById('imgNav').style.width = '0';
  }
  leaddays() {
    if (this.selectedPortArrival.length == 0 || this.selectedPortDepature.length == 0) {
      console.log("if case", this.selectedPortArrival, this.selectedPortDepature)
      
      
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          text: "Please select both Port of Arrival & Port of Departure for Leading Days",
          icon: 'info'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'info',
          html: "<p style='color:#fff'>" + "Please select both Port of Arrival & Port of Departure for Leading Days" + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
    }
    else {
      console.log("else case", this.selectedPortArrival, this.selectedPortDepature)
      this.service.LeadingDays(this.selectedPortArrival['0'].Arrival, this.selectedPortDepature['0'].Departure).subscribe((res: any) => {
        if (res.data.length == 0) {
          this.leadingDays = "0"
        }
        else {
          this.leadingDays = res.data[0].LeadDays
        }
      })
    }
  }
  addPackageDetails(data: any) {
    console.log('pack details')
    this.shiprefnum_package = data.data.shipmentNumber
    this.porefnum_package = data.data.poRefNumber
    document.getElementById('packageNav').style.width = '50%';

  }
  submitPackageDetails(form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (this.uploadCert === undefined || this.uploadCert === null) {
     
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          // title:'Select PO'
          text: 'Please attach bill of lading',
          icon: 'warning'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'warning',
          html: "<p style='color:#fff'>" + "Please attach bill of lading" + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
      return
    }
    let inputArgs = {
      'reader': this.mode,
      'poRefNumber': this.porefnum_package,
      'shipmentRefNumber': this.shiprefnum_package,
      'fullfillmentEmpID': this.user.email,
      'certificateAuthority': this.selectedCertAuth[0].mid,
      'issuedOn': this.issueDate,
      'certificateType': this.selectedCertType[0].mid,
      'orgName': this.user.userRoledcorg,
      'itemDimensions': {
        'length': this.measureLength + this.selectedMeasure[0].mid,
        'width': this.measureWidth + this.selectedMeasure[0].mid,
        'height': this.measureHeight + this.selectedMeasure[0].mid
      }
    }
    const req = new FormData();
    req.set('file', this.fileobject1);
    req.set('inputArgs', JSON.stringify(inputArgs));
    this.spinner.show()
    this.service.addPackageInfo(req).subscribe((res: any) => {
      this.spinner.hide()
      if (res.status === "Success") {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Success',
            html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
            icon: 'success'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
  
            heightAuto: false,
            icon: 'success',
            html: "<p style='color:#fff'>" + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',
  
  
  
  
          });
        }
        form.reset();
        this.closemodal();
        this.onPastDue();
      }
      else {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: res.message,
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
  
            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + res.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',
  
  
  
  
          });
        }
      }
    }, (err) => {
      this.spinner.hide()
      
      
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + err.error.errorMessage + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
    })
  }
  closemodal() {
    this.measureLength = null;
    this.measureWidth = null;
    this.measureHeight = null;
    this.uploadCert = null;
    this.selectedMeasure = [];
    this.fileobject1 = null;
    let form = document.getElementById('addpackage') as HTMLFormElement
    form.reset();
    document.getElementById('packageNav').style.width = '0';
  }
  onChange(event) {
    this.bulkUploadArray = [];
    this.fileobject = event.target.files[0];
  }
  onChange1(event) {
    this.bulkUploadArray = [];
    this.fileobject1 = event.target.files[0];
  }
  reset() {
    // this.resultData=[];
    this.model.fileUpload = null;
    this.fileobject = null;
    this.showData = false
    // this.isSubmit=false;
  }
  onSubmit(form: NgForm) {

    if (form.invalid) {
      return;
    }
    let selectedPo = this.gridApi.getSelectedRows()
    if (selectedPo.length === 0) {
     
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          // title:'Select PO'
          text: 'Please select atleast one PO to create ASN',
          icon: 'warning'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'warning',
          html: "<p style='color:#fff'>" + 'Please select atleast one PO to create ASN' + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
      return;
    }
    for (let i = 0; i < selectedPo.length; i++) {
      if (selectedPo[0]['poNumber'] != selectedPo[i]['poNumber']) {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            // title:'Select PO'
            text: 'Please select same PO to create ASN',
            icon: 'warning'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
  
            heightAuto: false,
            icon: 'warning',
            html: "<p style='color:#fff'>" + 'Please select same PO to create ASN' + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',
  
  
  
  
          });
        }
        return;
      }
    }
    let shipments = []
    for (let i = 0; i < selectedPo.length; i++) {
      if (selectedPo[i].packageInfo == 'Not Added') {
        const data = selectedPo[i].shipmentNumber;
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            // title:'Select PO'
            text: 'Please add Package-Info' + ' - ' + data,
            icon: 'warning'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
  
            heightAuto: false,
            icon: 'warning',
            html: "<p style='color:#fff'>" + 'Please add Package-Info' + ' - ' + data + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',
  
  
  
  
          });
        }
        return;
      }
      let row = { 'poRefNumber': selectedPo[i].poRefNumber, 'lineno': selectedPo[i].lineno, 'shipmentNumber': selectedPo[i].shipmentNumber, 'orgID': selectedPo[i].orgID, 'poNumber': selectedPo[i].poNumber }
      shipments.push(row);
    }
    if (this.model.fileUpload === undefined || this.model.fileUpload === null) {
     
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          // title:'Select PO'
          text: 'Please attach bill of lading',
          icon: 'warning'
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'warning',
          html: "<p style='color:#fff'>" + 'Please attach bill of lading' + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
      return
    }
    let inputArgs = {}
    inputArgs = {
      "shipments": shipments,
      "asnDetails": {
        'portofArrival': this.selectedPortArrival['0'].Arrival,
        'portofDeparture': this.selectedPortDepature['0'].Departure,
        'leadDays': this.leadingDays,
        'shipmentsDate': this.shipmentsDate,
        'asnNumber': this.asnNumber,
        'documentDate': this.documentDate,
        'packingSlipNumber': this.psNumber,
        'billofLadingNumber': this.bolNumber,
        'awbNumber': this.abNumber,
        'toOrgName': this.selectedLogisticsName[0].ParticipantName,
        'toOrgID': this.selectedLogisticsName[0].ParticipantID,
        'requestPickupdate': this.requestPickDate,
        'requestLeadDays': this.reqLeadDays,
        'asnCreatedDate': this.documentDate,
        'asnCreatedBy': this.user.email,
        'asnCreatedSite': this.user.sitecode,
        'asnCreatedOrgID': this.user.userRoledcorg
      }
    }
    const formData = new FormData();
    formData.set('file', this.fileobject);
    // inputArgs = JSON.stringify(inputArgs)
    formData.set('inputArgs', JSON.stringify(inputArgs));


    this.spinner.show()
    this.service.createAsnSupplier(formData).subscribe((res: any) => {
      this.spinner.hide()
      if (res.status === 'Success') {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Success',
            html: '<strong>' + res.data[0].message + '</strong></br><b>TRXID:</b>' + res.data[0].transactionID,
            icon: 'success'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            title: 'Success',
            html: '<strong>' + res.data[0].message + '</strong></br><b>TRXID:</b>' + res.data[0].transactionID ,
            icon: 'success',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
        form.resetForm();
        this.reset();
        // this.onPastDue();//refresh
      }
      else {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: res.message,
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            html: '<strong>' + res.message + '</strong>',
            icon: 'error',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
      }
    }, (err) => {
      this.spinner.hide()
     

      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        })
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({
          html: '<strong>' +err.error.errorMessage + '</strong>',
          icon: 'error',
          background: '#333',
          iconColor: '#fff',
        })
        
      }
    })
    let obj = [
      {
        "customerCode": this.customerModel.customer_code,
        "plantCode": this.customerModel.customer_plant_code,
        "shippingPlant": this.customerModel.customer_plant_code,
        "storageLocation": "R001",
        "goodsMovementTypeCode": 601,
        "goodsMovementStatusCode": "C",
        "documentNumber": this.customerModel.document_number,
        "documentDate": this.customerModel.document_date + 'T00:00:00.000Z',
        "referenceSDDocument": this.customerModel.ref_SDdocument,
        "items": [
          {
            "equipmentNumber": '0000000000' + this.customerModel.equipment_number,
            "materialNumber": this.customerModel.material_no,
            "serialNumber": this.customerModel.serial_no,
            "batchNumber": this.customerModel.batch_no,
            "itemName": this.customerModel.itemName,
            "deliveryQuantity": 1,
            "deliveryQuantityUOM": "EA",
            "pickingQuantity": 1,
            "pickingQuantityUOM": "EA",
            "pickStatusCode": "C",
            "inspectionLotNumber": this.customerModel.inspectionLotNo,
            "warranty": [
              {
                "masterWarrantyNumber": "2000000031",
                "warrantyType": 2,
                "warrantyDescription": "Vendor/manufacturer warranty",
                "objectNumber": "IE0000000000" + this.customerModel.equipment_number,
                "warrantyStatus": false,
                "materialNumber": '00000000000000' + this.customerModel.material_no,
                "serialNumber": "0000000000000000" + this.customerModel.serial_no,
                "warrantyStartDate": this.customerModel.warrStartDate + 'T00:00:00.000Z',
                "warrantyEndDate": this.customerModel.warrEndDate + 'T00:00:00.000Z',
                "createdOn": this.customerModel.warrStartDate + 'T00:00:00.000Z',
                "createdTime": "PT00H00M00S",
                "inherentWarranty": true,
                "passOnWarranty": true,
                "warrantyCounterLink": "AND",
                "warrantyTerms": [
                  {
                    "serviceCode": this.customerModel.serialCode,
                    "serviceDescription": this.customerModel.desp,
                    "uom": "EA",
                    "warrantyCounterDesc": "Time-dependent Warranty"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
    this.warrantyOutboundData = obj
    console.log(this.customerModel, this.warrantyOutboundData, "outbound");
    let outboundDataObj = {
      "outbounds": this.warrantyOutboundData,
      "accessToken": sessionStorage.getItem("warranty_token")
    }
    console.log(outboundDataObj, "outboundData")
    this.service.plantCodeValidations(outboundDataObj).subscribe((res: any) => {
      console.log(res, "res")
    })
  }
  closemodal1() {
    this.showCustomer1 = false;
    this.showCustomer2 = false;
    // let form = document.getElementById('customerInfo') as HTMLFormElement
    // form.reset();
    document.getElementById('customerNav').style.width = '0';
  }
  openMaterialInfo() {
    this.showCustomer1 = true;
  }
  openWarrantyInfo() {
    this.showCustomer2 = true;
  }
  customerDetailsModal(event: any) {
    console.log("customer details", event)
    // this.customerModel
    this.customerModel.itemName = event.data.itemName;
    this.customerModel.shipment_code = this.customerModel.customer_plant_code
    document.getElementById('customerNav').style.width = '50%';
  }
  submitCustomerDetails(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.customerModel.shipment_code = this.customerModel.customer_plant_code
    console.log(this.customerModel,)
    this.closemodal1()
  }
}
