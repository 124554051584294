import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Role } from '../enums/role.enum';
import { LoggedUser } from '../classes/login';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userDetails:any;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loginStateChange = new Subject();
  private STORAGE_KEY = '_UserLoggedDatascc';
  constructor() { }
  setLoginDetails(data){
    sessionStorage.setItem('_UserLoggedDatascc',JSON.stringify(data))
    this.loginStateChanged();
  }
  loginStateChanged() {
    this.loginStateChange.next();
  }
  
  getLoginDetails(){
    this.userDetails = JSON.parse(sessionStorage.getItem('_UserLoggedDatascc'))
    return this.userDetails
  }
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  removeUserDetails(){
    sessionStorage.clear()
  } 
  setToken(token){
    sessionStorage.setItem('tokenscc',token)
  }
  getToken()
  {
    return sessionStorage.getItem('token');
  }
  getRole(): Role {
    const loggedUser = JSON.parse(sessionStorage.getItem('_UserLoggedDatascc'))
    if (loggedUser === null) {
      return null;
    }
    return loggedUser.partyType as Role ;
  }
  getUserLogged() {
    const loggedUser = this.getLoggedUser();
    if (loggedUser === null) {
      return false;
    }
    return true;
  }
  getLoggedUser(): LoggedUser {
    const rawData = sessionStorage.getItem(this.STORAGE_KEY);
    console.log(rawData,"rawdata")
    if (typeof rawData === 'undefined') {
      return null;
    }
    if (rawData === null) {
      return null;
    }
    const rawJsonData = JSON.parse(rawData);
    return new LoggedUser(rawJsonData['partyType'],rawJsonData['userRoledcorg'], rawJsonData['partyIDdcorg'],rawJsonData['orgtypeid'],rawJsonData['orgtypename']
    ,rawJsonData['email'],rawJsonData['name'],rawJsonData['sitecode'] );
  }
  logout()
  {
    sessionStorage.clear()
    localStorage.removeItem(this.STORAGE_KEY);
    this.loginStateChanged();
 
  }
}
  

