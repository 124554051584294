import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/themes/theme.service';
@Component({
  selector: 'app-tab-layout',
  templateUrl: './tab-layout.component.html',
  styleUrls: ['./tab-layout.component.scss']
})
export class TabLayoutComponent implements OnInit {
  backgroundImage: string = undefined;
  isDarkTheme;
   constructor(private themeService: ThemeService, public router: Router) { }

  ngOnInit(): void {

    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;
      res='Light'
    });
  }
  
  
}
