<div class="page_heading d-flex">
    <div class="warranty col">
        <h3>CONSOLIDATE BY</h3>
    </div>
    <div class="warranty col text-right">
        <strong>Organization Type: <span class="badge badge-info"
                [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'"> {{orgType}}</span></strong> &nbsp;&nbsp;
        <strong>Logged User: <span class="badge badge-info" [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'">
                {{loggedUser}}</span></strong> &nbsp;&nbsp;

    </div>
</div>
<div class="container">
    <form name="form" #needByDate="ngForm" (ngSubmit)="submitNeedByDate(needByDate)">
        <div class="row mt-3">
            <div class="col-sm-12 col-lg-2 col-md-2">
                <div class="form-group">
                    <label for="" class="setFont">Need by Date</label>
                    <input type="date" class="form-control" name="needbydate" id="NeedByDate" [(ngModel)]="needbydate"
                        #date="ngModel" ngModel required>
                    <div *ngIf="date.invalid && (needByDate.submitted || date.touched)" class="text-danger">
                        <ng-container *ngIf="date.errors?.required"> <small> Please Select Need by Date </small>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-lg-2 col-md-2">
                <div class="form-group d-flex justify-content-center mt-1">
                    <button type="submit" class="btn btn-dark mt-4 submit">SUBMIT</button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="pageHead" *ngIf="showPODetails">
    <div class="warranty">
        <h3>ORDER SUMMARY</h3>
    </div>
</div>
<div class="container" *ngIf="showPODetails">
    <div>
        <ag-grid-angular #agGrid id="myGrid"
            [ngClass]="isDarkTheme==='light' ? 'ag-theme-balham': 'ag-theme-balham-dark'" [rowData]="rowData"
            domLayout="autoHeight" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true"
            [animateRows]="true" [rowSelection]="rowSelection" [enableRangeSelection]="true" [sideBar]="sideBar"
            [masterDetail]="true" [paginationPageSize]="paginationPageSize"
            [detailCellRendererParams]="detailCellRendererParams" [getRowHeight]="getRowHeight"
            (selectionChanged)="onSelectionChanged($event)">
        </ag-grid-angular>
    </div>
</div>
<div class="row float-right setMargin" *ngIf="showPODetails">
    <div class="col-sm-12 col-lg-2 col-md-2">
        <div class="form-group">
            <button type="submit" class="btn btn-dark mt-4 submit" (click)="validateClick()">CONSOLIDATE</button>
        </div>
    </div>
</div>

<div id="myNav" class="overlay">
    <a href="javascript:void(0)" class="closebtn" id="closebtn" (click)="closemodal()">&times;</a>
    <div class="overlay-content" id="content">

        <div class="materialdata">
            <!-- <h4>Shipment Details</h4> -->
            <div class="side-heading">

                <div class="d-flex">

                    <h4>SHIPMENT DETAILS</h4>

                </div>

            </div>
            <div>
                <div class="mt-4" *ngIf="shippingData.length>0">
                    <ag-grid-angular #agGrid id="myGrid"
                        [ngClass]="isDarkTheme==='light' ? 'ag-theme-balham': 'ag-theme-balham-dark'"
                        [columnDefs]="columnDefs1" [defaultColDef]="defaultColDef1" [masterDetail]="true"
                        [rowData]="shippingData" [pagination]="pagination" [paginationPageSize]="paginationPageSize"
                        [rowHeight]="rowHeight" [autoGroupColumnDef]="autoGroupColumnDef" [enableRangeSelection]="true"
                        [enableCharts]="true" [chartThemeOverrides]="chartThemeOverrides" [domLayout]="domLayout"
                        [rowSelection]="rowSelection">
                    </ag-grid-angular>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                <div class="btn-group mr-2" role="group" aria-label="First group">
                    <button type="button" class="btn mt-4 close-btn" (click)="closemodal()">CLOSE</button>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale" [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>