<div class="page_heading d-flex">
    <div class="warranty col">
    <h3>CREATE SHIPMENT NOTICE</h3>
    </div>
    <div class="warranty col text-right">
        <strong>Organization Type: <span class="badge badge-info"
                [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'"> {{orgType}}</span></strong> &nbsp;&nbsp;
        <strong>Logged User: <span class="badge badge-info" [ngClass]="isDarkTheme === 'light' ? 'badge1':'badge2'">
                {{loggedUser}}</span></strong> &nbsp;&nbsp;
    
    </div>
</div>

    <div class="container row mt-4 justify-content-start" style="margin-left:2px">
        <div class="col-md-2 justify-content-center" >
        <button type="button" class="btn btn-primary" (click)="onDueToday()" >Shipments Due Today</button>
        </div>
        <div class="col-md-2 justify-content-center"> <button type="button" class="btn btn-success" (click)="onPastDue()">Shipments Past Due</button>
        </div>
        <div class="col-md-2 justify-content-center"> <button type="button" class="btn btn-danger" disabled>Shipments in 7 days</button>
        </div>
    </div>
<div *ngIf="showData" class="container">
<div class="mt-4">
    <ag-grid-angular #agGrid id="myGrid" [ngClass]="isDarkTheme==='light' ? 'ag-theme-balham': 'ag-theme-balham-dark'" [rowData]="rowData" domLayout="autoHeight"
    [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"  [pagination]="true" (gridReady)="onGridReady($event)" [animateRows]="true"
    [rowSelection]="rowSelection" [enableRangeSelection]="true" [sideBar]="sideBar"
    [paginationPageSize]="paginationPageSize" [rowMultiSelectWithClick]="true" [suppressRowClickSelection]="true">
    <!-- [suppressRowClickSelection]="true" -->
  </ag-grid-angular>
</div>
<div class="mt-4 ">
    <form #asncreate="ngForm" id="asn" (ngSubmit)="onSubmit(asncreate)">

        <div class="">
            <!-- <div class="card card-body shadow rounded my-2 pt-3 px-3"> -->
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="form-group">
                            <label for="" class="">Port of Arrival</label>
                            <ng-multiselect-dropdown [placeholder]="Select" class= "multiselect" 
                            name="port_of_arrival" #poa="ngModel" [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                            [(ngModel)]="selectedPortArrival" [settings]="portArrivalSettings" [data]="portArrivalDropdown"
                            (onSelect)="onPortOfArrivalSelect($event)"
                             required>
                             <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->
                            </ng-multiselect-dropdown>
            
                                <div class="text-danger " *ngIf="poa.invalid &&(asncreate.submitted)">
                                    <small class=" float-left">Please Select Port of Arrival</small>
                                </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="form-group">
                            <label for="" class="">Port of Departure</label>
                            <ng-multiselect-dropdown [placeholder]="Select" class= "multiselect" 
                            name="port_of_departure" #pod="ngModel" [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                            [(ngModel)]="selectedPortDepature" [settings]="portDepartureSettings" [data]="portDepartureDropdown"
                            (onSelect)="onPortOfDepartureSelect($event)"
                             required>
                             <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->
                            </ng-multiselect-dropdown>
            
                                <div class="text-danger " *ngIf="pod.invalid &&(asncreate.submitted)">
                                    <small class=" float-left">Please Select Port of Departure</small>
                                </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="form-group">
                            <label for=""class="float-left">Lead Time (days)</label>
                            <input type="number" class="form-control"
                                name="leading_days" [(ngModel)]="leadingDays"
                                #ld="ngModel" disabled>
                           
                            <!-- <div class="text-danger " *ngIf="ld.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter
                                    Quantity</small>
                            </div> -->
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="form-group">
                            <label for=""class="float-left">Shipments Date</label>
                            <input type="date" class="form-control"
                                name="shipments_date"
                                [(ngModel)]="shipmentsDate" #date="ngModel" required>
                           
                            <div class="text-danger " *ngIf="date.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Shipments Date</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="form-group">
                            <label for=""class="float-left">ASN #</label>
                            <input type="text" class="form-control" name="asn_number" [(ngModel)]="asnNumber"
                                #asn="ngModel" required>
                           
                            <div class="text-danger " *ngIf="asn.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter ASN #</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="form-group">
                            <label for=""class="float-left">Document Date</label>
                            <input type="text" class="form-control"
                                name="document_date" 
                                [(ngModel)]="documentDate" #date="ngModel" disabled>
                            <!--<div class="text-danger " *ngIf="date.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Shipments Date</small>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="form-group">
                            <label for=""class="float-left">Packing Slip #</label>
                            <input type="text" class="form-control" name="ps_number" [(ngModel)]="psNumber"
                                #psn="ngModel" required>
                           
                            <div class="text-danger " *ngIf="psn.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Packing Slip #</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="form-group">
                            <label for=""class="float-left">Bill of Lading #</label>
                            <input type="text" class="form-control" name="bol_number" [(ngModel)]="bolNumber"
                                #bol="ngModel" required>
                           
                            <div class="text-danger " *ngIf="bol.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Bill of Lading #</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="form-group">
                            <label for=""class="float-left">Airway Bill #</label>
                            <input type="text" class="form-control" name="abnumber" [(ngModel)]="abNumber"
                                #abn="ngModel" required>
                           
                            <div class="text-danger " *ngIf="abn.invalid &&(asncreate.submitted)">
                                <small class="float-left">Please Enter Airway Bill #</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <label for=""class="float-left">Attach Bill of Lading</label>
                        <div class=" input-group input-file">
                            <input type="file" class="form-control" style="padding:3px"
                                                accept=".pdf,.docx,.doc"
                                                placeholder='Choose a file...' (change)="onChange($event)" name="fileUpload"
                                                #invoiceFullPath="ngModel" [(ngModel)]="model.fileUpload" #fileupload (click)="fileupload.value = null" value="" required/>
                                                <!-- <span class="input-group-btn">
                                                    <button (click)="reset()" class="btn btn-dark  ml-2 reset"
                                                        type="button">RESET</button>
                                                </span>, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.xls,.xlsx
                                            <span class="input-group-btn">
                                                <button (click)="onUploadFile()" class="btn btn-dark  ml-2 submit"
                                                    type="button">SUBMIT</button>
                                            </span> -->
                        </div>
                    </div>
                    
                </div>
                <div >
                    <div class="mt-5 side-heading">
                        <div class="d-flex">  <!--d-flex-->
                            <h4 >LOGISTICS</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for="" class="">Logistics Name</label>
                                <ng-multiselect-dropdown [placeholder]="Select" class= "multiselect" 
                                name="logistics_name" #lsn="ngModel" [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                                [(ngModel)]="selectedLogisticsName" [settings]="logisticsSettings" [data]="logisticsNameDropdown"
                                (onSelect)="onLogisticsNameSelect($event)"
                                 required>
                                 <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->
                                </ng-multiselect-dropdown>
                
                                    <div class="text-danger " *ngIf="lsn.invalid &&(asncreate.submitted)">
                                        <small class=" float-left">Please Select Logistics Name</small>
                                    </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for=""class="float-left">Request Pick-up Date</label>
                                <input type="date" class="form-control"
                                    name="req_date" 
                                    [(ngModel)]="requestPickDate" #reqPD="ngModel" required>
                               
                                <div class="text-danger " *ngIf="reqPD.invalid &&(asncreate.submitted)">
                                    <small class="float-left">Please Enter Request Pick-up Date</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for=""class="float-left">Request Lead Time (days)</label>
                                <input type="text" class="form-control" name="req_lead_days" [(ngModel)]="reqLeadDays"
                                    #rld="ngModel" required>
                               
                                <div class="text-danger " *ngIf="rld.invalid &&(asncreate.submitted)">
                                    <small class="float-left">Please Enter Request Lead Time (days)</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              
            <!-- </div> -->

        </div>
        <div class="justify-content-center mb-4">
            <button type="submit" class="btn btn-dark  mt-4 submit">CONFIRM SHIPMENT NOTICE</button>
        </div>
</form>
</div>

</div>
<div id="packageNav" class="overlay">
    <a href="javascript:void(0)" class="closebtn" id="closebtn" (click)="closemodal()">&times;</a>
    <div class="overlay-content" id="content">

        <div class="materialdata">
            <!-- <h4>Shipment Details</h4> -->
            <div class="side-heading">

                <div class="d-flex">

                    <h4>ADD PACKAGE INFO</h4>

                </div>

            </div>
            <div>
                <!-- <input type="text" name="" id=""> -->

                <div class="mt-4">
                    <form #addpackage="ngForm" id="addpackage" (ngSubmit)="submitPackageDetails(addpackage)">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="form-group">
                                    <label for="" class="float-left">Length: </label>
                                    <input type="text" class="form-control" name="leng" [(ngModel)]="measureLength"
                                    #lengt="ngModel" required>
                                    <div class="text-danger " *ngIf="lengt.invalid &&(addpackage.submitted)">
                                        <small class=" float-left">Please Select Length</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                    <!-- <ng-multiselect-dropdown [placeholder]="Select Measure" class= "multiselect" 
                                    name="measure" #mea="ngModel"
                                    [(ngModel)]="selectedMeasure" [settings]="measureSettings" [data]="measureDropdown"
                                    (onSelect)="onMeasureSelect($event)"
                                     required> -->
                                     <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->
                                    <!-- </ng-multiselect-dropdown> -->
                                <div class="form-group">
                                    <label for="" class="">Measure: </label>
                                    <ng-multiselect-dropdown class= "multiselect" name="measure_unit" #mea="ngModel"
                                    [(ngModel)]="selectedMeasure" [settings]="measureSettings" [data]="measureDropdown"
                                    (onSelect)="onMeasureSelect($event)" [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                                     required>
                                    </ng-multiselect-dropdown>
                                    
                                    <div class="text-danger " *ngIf="mea.invalid &&(addpackage.submitted)">
                                        <small class=" float-left">Please Select Measure</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="form-group">
                                    <label for="" class="float-left">Width</label>
                                    <input type="text" class="form-control" name="widt" [(ngModel)]="measureWidth"
                                    #wid="ngModel" required>
                                    <div class="text-danger " *ngIf="wid.invalid &&(addpackage.submitted)">
                                        <small class=" float-left">Please Select Width</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                    <!-- <ng-multiselect-dropdown [placeholder]="Select Measure" class= "multiselect" 
                                    name="measure" #mea="ngModel"
                                    [(ngModel)]="selectedMeasure" [settings]="measureSettings" [data]="measureDropdown"
                                    (onSelect)="onMeasureSelect($event)"
                                     required> -->
                                     <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->
                                    <!-- </ng-multiselect-dropdown> -->
                                <div class="form-group">
                                    <label for="" class="">Measure: </label>
                                    <ng-multiselect-dropdown class= "multiselect" name="measure_unit" #mea="ngModel"
                                    [(ngModel)]="selectedMeasure" [settings]="measureSettings" [data]="measureDropdown"
                                    (onSelect)="onMeasureSelect($event)" [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                                     required>
                                    </ng-multiselect-dropdown>
                                    
                                    <div class="text-danger " *ngIf="mea.invalid &&(addpackage.submitted)">
                                        <small class=" float-left">Please Select Measure</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="form-group">
                                    <label for="" class="float-left">Height</label>
                                    <input type="text" class="form-control" name="heigh" [(ngModel)]="measureHeight"
                                    #hei="ngModel" required>
                                    <div class="text-danger " *ngIf="hei.invalid &&(addpackage.submitted)">
                                        <small class=" float-left">Please Add Height</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                    <!-- <ng-multiselect-dropdown [placeholder]="Select Measure" class= "multiselect" 
                                    name="measure" #mea="ngModel"
                                    [(ngModel)]="selectedMeasure" [settings]="measureSettings" [data]="measureDropdown"
                                    (onSelect)="onMeasureSelect($event)"
                                     required> -->
                                     <!-- [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'" -->
                                    <!-- </ng-multiselect-dropdown> -->
                                <div class="form-group">
                                    <label for="" class="">Measure: </label>
                                    <ng-multiselect-dropdown class= "multiselect" name="measure_unit" #mea="ngModel"
                                    [(ngModel)]="selectedMeasure" [settings]="measureSettings" [data]="measureDropdown"
                                    (onSelect)="onMeasureSelect($event)" [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                                     required>
                                    </ng-multiselect-dropdown>
                                    
                                    <div class="text-danger " *ngIf="mea.invalid &&(addpackage.submitted)">
                                        <small class=" float-left">Please Select Measure</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label for="" class="">Certificate Type: </label>
                                    <ng-multiselect-dropdown class= "multiselect" name="Certificate_type" #ctype="ngModel"
                                    [(ngModel)]="selectedCertType" [settings]="certTypeSettings" [data]="certTypeDropdown"
                                    (onSelect)="onCertTypeSelect($event)" [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                                     required>
                                    </ng-multiselect-dropdown>
                                    
                                    <div class="text-danger " *ngIf="ctype.invalid &&(addpackage.submitted)">
                                        <small class=" float-left">Please Select Certificate Type</small>
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label for="" class="">Certificate Authority: </label>
                                    <ng-multiselect-dropdown class= "multiselect" name="cert_auth" #cAuth="ngModel"
                                    [(ngModel)]="selectedCertAuth" [settings]="certAuthSettings" [data]="certAuthDropdown"
                                    (onSelect)="onCertAuthoritySelect($event)" [ngClass]="isDarkTheme === 'light' ? 'multiselect':'multiselect1'"
                                     required>
                                    </ng-multiselect-dropdown>
                                    
                                    <div class="text-danger " *ngIf="cAuth.invalid &&(addpackage.submitted)">
                                        <small class=" float-left">Please Select Certificate Authority</small>
                                    </div>
                                </div>
                            </div>
                                
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="form-group">
                                    <label for="" class="">Issue Date: </label>
                                    <input type="date" class="form-control" name="iss_date" 
                                    [(ngModel)]="issueDate" #isssdate="ngModel" required>
                                    <div class="text-danger " *ngIf="isssdate.invalid &&(addpackage.submitted)">
                                        <small class=" float-left">Please Enter Issue Date</small>
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="form-group">
                                    <label for="" class="">Upload Certificate: </label>
                                    <div class=" input-group input-file">
                                        <input type="file" class="form-control" style="padding:3px"
                                                            accept=".pdf,.docx,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.xls,.xlsx"
                                                            placeholder='Choose a file...' (change)="onChange1($event)" name="fileUpload1"
                                                            #invoiceFullPath="ngModel" [(ngModel)]="uploadCert" #fileupload1 (click)="fileupload1.value = null" value="" required/>
                                    </div>
                                    
                                </div>
                            </div>
                                
                        </div>
                        <!-- <div class="row"> -->
                            <div class="row form-group">
                                <label for="loadingno" class="col-sm-2 control-label">Generate</label>
                                <div class="col-sm-10">
                                    <!-- <input type="radio"  name="bcode" value="bcode" 
                                                        [(ngModel)]="mode">Bar Code  -->
                                    <input type="radio" name="qcode" value="qcode" [(ngModel)]="mode"> Qr Code<br>
                                </div>
                            </div>
                        <!-- </div> -->
                        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                            <div class="btn-group mr-2" role="group" aria-label="First group">
                                <button type="submit" class="btn btn-primary submit mt-4" >SUBMIT</button>
                              </div>
                            <div class="btn-group mr-2" role="group" aria-label="First group">
                              <button type="button" class="btn mt-4 close-btn" (click)="closemodal()">CLOSE</button>
                            </div>
                          </div>
                    </form>
                </div>
            </div>


        </div>
    </div>
</div>
<div id="customerNav" class="overlay">
    <a href="javascript:void(0)" class="closebtn" id="closebtn" (click)="closemodal1()">&times;</a>
    <div class="overlay-content" id="content">

        <div class="materialdata">
            <!-- <h4>Shipment Details</h4> -->
            <div class="mt-4">
                <form #customerInfo="ngForm" id="addCustomerInfo" (ngSubmit)="submitCustomerDetails(customerInfo)">
                    <div class="row container">
                        <div class="side-heading">
                            <div class="d-flex">
                                <h4>CUSTOMER INFO</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Customer Code</label>
                                    <input type="text" class="form-control" name="custcode" [(ngModel)]="customerModel.customer_code"
                                        #cc="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="cc.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Customer Code</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Customer Plant Code</label>
                                    <input type="text" class="form-control" name="custplantcode" [(ngModel)]="customerModel.customer_plant_code"
                                        #cpc="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="cpc.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Customer Plant Code</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Shipment Code</label>
                                    <input type="text" class="form-control" name="shipcode" [(ngModel)]="customerModel.customer_plant_code"
                                        #sc="ngModel" disabled>
                                   
                                    <!-- <div class="text-danger " *ngIf="sc.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Shipment Code</small>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Document #</label>
                                    <input type="text" class="form-control" name="doc_num" [(ngModel)]="customerModel.document_number"
                                        #documentNumber="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="documentNumber.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Document #</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Document Date</label>
                                    <input type="date" class="form-control"
                                        name="doc_date" 
                                        [(ngModel)]="customerModel.document_date" #docdate="ngModel" required>
                                    <div class="text-danger " *ngIf="docdate.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Document Date</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Reference SD Document</label>
                                    <input type="text" class="form-control" name="refsddoc" [(ngModel)]="customerModel.ref_SDdocument"
                                        #refdoc="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="refdoc.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Reference SD Document</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ml-2">
                            <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                <div class="btn-group mr-2" role="group" aria-label="First group">
                                  <button type="button" class="btn mt-4 close-btn" (click)="openMaterialInfo()">NEXT</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showCustomer1">
                        <div class="side-heading">
                            <div class="d-flex">
                                <h4>MATERIAL INFO</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Equipment #</label>
                                    <input type="text" class="form-control" name="eqNo" [(ngModel)]="customerModel.equipment_number"
                                        #eq_no="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="eq_no.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Equipment #</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Material #</label>
                                    <input type="text" class="form-control" name="matNo" [(ngModel)]="customerModel.material_no"
                                        #mat_no="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="mat_no.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Material #</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Batch #</label>
                                    <input type="text" class="form-control" name="batchNo" [(ngModel)]="customerModel.batch_no"
                                        #batNO="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="batNO.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Batch #</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Serial #</label>
                                    <input type="text" class="form-control" name="serNo" [(ngModel)]="customerModel.serial_no"
                                        #ser_num="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="ser_num.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Serial #</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Item Name</label>
                                    <input type="text" class="form-control" name="itemName" [(ngModel)]="customerModel.itemName"
                                        #item_name="ngModel" disabled>
                                   
                                    <!-- <div class="text-danger " *ngIf="refdoc.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Reference SD Document</small>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Inspection Lot #</label>
                                    <input type="text" class="form-control" name="itemlot" [(ngModel)]="customerModel.inspectionLotNo"
                                        #item_num="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="item_num.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Inspection Lot #</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div class="row ml-2">
                        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                            <div class="btn-group mr-2" role="group" aria-label="First group">
                              <button type="button" class="btn mt-4 close-btn" (click)="openWarrantyInfo()">NEXT</button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div *ngIf="showCustomer2">
                    <div class="side-heading">
                            <div class="d-flex">
                                <h4>WARRANTY INFO</h4>
                            </div>
                        </div>
                        <div class="row">
                            
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Object (Contract) #</label>
                                    <input type="text" class="form-control" name="objNo" [(ngModel)]="customerModel.objNo"
                                        #obj_num="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="obj_num.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Object #</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Warranty Start Date</label>
                                    <input type="date" class="form-control" name="wstartdate" [(ngModel)]="customerModel.warrStartDate"
                                        #wsd="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="wsd.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Warranty Start Date</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Warranty End Date</label>
                                    <input type="date" class="form-control" name="wenddate" [(ngModel)]="customerModel.warrEndDate"
                                        #wed="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="wed.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Warranty End Date</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ml-1" >
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Service Code</label>
                                    <input type="text" class="form-control" name="serCode" [(ngModel)]="customerModel.serialCode"
                                        #ser_code="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="ser_code.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Service Code</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label for=""class="float-left">Description</label>
                                    <input type="text" class="form-control" name="despcription" [(ngModel)]="customerModel.desp"
                                        #desp="ngModel" required>
                                   
                                    <div class="text-danger " *ngIf="desp.invalid &&(customerInfo.submitted)">
                                        <small class="float-left">Please Enter Description</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ml-2">
                    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        <div class="btn-group mr-2" role="group" aria-label="First group">
                          <button type="submit" class="btn btn-primary mt-4 submit" >SUBMIT</button>
                        </div>
                    </div>
                    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        <div class="btn-group mr-2" role="group" aria-label="First group">
                          <button type="button" class="btn mt-4 close-btn" (click)="closemodal1()">CLOSE</button>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div id="imgNav" class="overlay">
    <a href="javascript:void(0)" class="closebtn" id="closebtn" (click)="closemodal2()">&times;</a>
    <div class="overlay-content" id="content">
        <div class="container">
            <img class="center" style="height: 250px;width: 250px;" src = "{{imgPath}}" alt="QRCode" >
        </div>
    </div>
</div>
    
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale" [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>