import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ConsolidatepoService } from 'src/app/services/consolidatepo.service';
import { ThemeService } from 'src/app/themes/theme.service';
import { SplitShipments } from 'src/app/classes/model-shipments'
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-consolidate-po-preview',
  templateUrl: './consolidate-po-preview.component.html',
  styleUrls: ['./consolidate-po-preview.component.scss']
})
export class ConsolidatePoPreviewComponent implements OnInit {
  @ViewChild('shipmentsSplit') myForm: NgForm;
  selectedpo: any = []
  selectedNeedByDate: any
  LoggedUserDetails: any;
  columnDefs
  defaultColDef;
  columnDefs1;
  pagination = true;
  paginationPageSize;
  rowData: any = [];
  shippingData: any = [];
  poType;
  userId;
  status1;
  domLayout: any;
  rowSelection;
  autoGroupColumnDef;
  maxBlocksInCache;
  sideBar;
  userType = "BUYER";
  isDarkTheme;
  detailCellRendererParams;
  getRowHeight;
  gridApi: any;
  poDetails: any = [];
  modelShipments: SplitShipments[] = [];
  // modelShipments=[]
  shipments: any[]
  deliverysitesettings = {}
  selectedDeliverySiteCode = []
  raiseConsolidate = []
  raiseConsolidateSettings = {}
  raiseConsolidateData = []
  siteCode = []
  siteCodeSettings = {}
  siteCodeData = []
  poNumber: any;
  respShipments = []
  respSplitShipments = []
  // currentDate : Date =new Date();
  selectedNeedByDateShow: any;
  popupdata: any;
  popupdatalineno: any;
  loggedUser;
  orgType
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService, private consolidatePOService: ConsolidatepoService, private themeService: ThemeService, private toastr: ToastrService, private spinner: NgxSpinnerService) {
    this.domLayout = 'autoHeight';

    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.rowSelection = 'multiple'

    this.autoGroupColumnDef = {
      icons: {
        menu: '<em class="fa fa-shower"/>',
        filter: '<em class="fa fa-long-arrow-alt-up"/>',
        columns: '<em class="fa fa-snowflake"/>',
        sortAscending: '<em class="fa fa-sort-alpha-up"/>',
        sortDescending: '<em class="fa fa-sort-alpha-down"/>',
      },
      headerCheckboxSelection: true,
      width: 300,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
      },
    },

      this.paginationPageSize = 10;
    this.maxBlocksInCache = 1;
    this.pagination = true;
    this.sideBar = {
      toolPanels: [

        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },


        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },


      ],

    };
  }

  ngOnInit(): void {
    this.spinner.hide()
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;
    });
    this.loggedUser = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).name;
    this.orgType = sessionStorage.getItem("orgName")
    this.raiseConsolidateSettings = {
      singleSelection: true,
      idField: 'ParticipantID',
      textField: 'ParticipantName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    };
    this.siteCodeSettings = {
      singleSelection: true,
      idField: 'siteCode',
      textField: 'siteName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    };
    this.LoggedUserDetails = this.authService.getLoginDetails()
    let routeData = this.activatedRoute.snapshot.queryParams
    this.selectedpo = JSON.parse(routeData.consolidate)
    this.selectedNeedByDate = routeData.needbydate
    this.selectedNeedByDateShow = moment(routeData.needbydate).subtract(3, 'days').format('DD-MM-YYYY')
    console.log(this.selectedNeedByDate)
    this.consolidatepos(this.selectedpo, this.selectedNeedByDate)
    this.raiseConsolidatePO()
    this.showPOs()
  }
  showPOs() {
    console.log("hi")
    this.columnDefs = [
      {
        headerName: 'SALE ORDER CREATION DATE',
        field: 'poUploadedDate',
        filter: 'agSetColumnFilter',
        // checkboxSelection:true,
        cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'SALE ORDER CREATION DATE',
        tooltipField: 'poUploadedDate',
        // checkboxSelection:true
      },
      {
        headerName: 'CUSTOMER NAME',
        field: 'customerName',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'CUSTOMER NAME',
        tooltipField: 'customerName',

      },
      {
        headerName: 'CUSTOMER SITE NAME',
        field: 'customerSiteName',
        filter: true,
        headerTooltip: 'CUSTOMER SITE NAME',
        tooltipField: 'customerSiteName',
      },

    ];
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: 'LINE No',
            field: 'lineno',
            headerTooltip: 'LINE No',
            tooltipField: 'lineno',
            onCellClicked: this.popupopen.bind(this),
            cellRenderer: (params: ICellRendererParams) => {
              return `<strong><a class="text-center d-flex" >${params.value} </a></strong>`;

            },
          },
          {
            headerName: 'ITEM NAME', field: 'itemName',
            headerTooltip: 'ITEM NAME',
            tooltipField: 'itemName',

          },

          {
            headerName: 'QUANTITY', field: 'quantity',
            headerTooltip: 'QUANTITY',
            tooltipField: 'quantity',
          },
          {
            headerName: 'UOM', field: 'primaryUom',
            headerTooltip: 'UOM',
            tooltipField: 'primaryUom',

          },
        ],
        defaultColDef: {
          flex: 1, searchable: false, floatingFilter: false, sortable: true, resizable: true, enableRangeSelection: true, popupParent: document.body
        },

      },

      getDetailRowData(params) {
        console.log(params)
        params.successCallback(params.data.lineItems);

      },

    };
    this.getRowHeight = params => {
      console.log(params, "p")
      const defRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
      if (params.node.detail) {
        const offset = 100;
        const detailRowHeight =
          params.data.lineItems.length * defRowHeight;
        var gridSizes = params.api.getSizesForCurrentTheme();
        return detailRowHeight + gridSizes.headerHeight + offset;
      } else {
        return defRowHeight;
      }
    }
    console.log(typeof (this.poDetails))
    this.rowData = this.poDetails
  }
  popupopen(data) {
    console.log(data.data)
    console.log(data.data.shipping, "data")
    this.popupdatalineno = data.data.lineno
    console.log(this.popupdatalineno, "popupdatalineno")
    this.popupdata = data.data.shipping
    console.log(this.selectedNeedByDate)
    this.shippingData = data.data.shipping;
    this.deliverysitesettings = {
      singleSelection: true,
      idField: '',
      textField: '',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    };
    this.selectedDeliverySiteCode = ['RSE-Vizag', 'WS NY INV', 'SW CA INV', 'GE PB INV', 'SM CA INV', 'RS CH INV']
    console.log(this.selectedNeedByDate)
    this.modelShipments = [{
      uom: this.shippingData[0].primaryUom,
      quantity: '',
      needbydate: moment(this.selectedNeedByDate).subtract(3, 'days').format('YYYY-MM-DD'),
      deliverysite: '',
      serialNum: 1,
    }]
    this.identifier()
    document.getElementById('myNav').style.width = '80%';
  }
  drop(e: any) {

    console.log(e)
    alert(e)
  }

  closemodal() {
    document.getElementById('myNav').style.width = '0';
    // let form= document.getElementById('splitShipments') as HTMLFormElement
    // form.reset()
    // this.modelShipments=[]
    console.log(this.poDetails, "close po")
  }

  closemodalF(form: any) {
    document.getElementById('myNav').style.width = '0';
    // let form= document.getElementById('splitShipments') as HTMLFormElement
    // form.reset()
    // this.modelShipments=[]
    console.log(this.poDetails, "close po")
    this.myForm.form.get('quantity').clearValidators();
    this.myForm.form.get('needbydate').clearValidators();
    this.myForm.form.get('deliverysite').clearValidators();
  }
  consolidatepos(selectedpos, needbydate) {
    this.spinner.show()
    let obj = {
      buyerID: this.LoggedUserDetails.email,
      buyerName: this.LoggedUserDetails.name,
      buyerOrg: this.LoggedUserDetails.userRoledcorg,
      needByDate: moment(needbydate).format('DD-MM-YYYY'),
      poSite: this.LoggedUserDetails.sitecode,
      poreferences: selectedpos
    }
    this.consolidatePOService.validateConsolidatePO(obj).subscribe((resp: any) => {
      this.spinner.hide()
      if (resp.status === 'Success') {
        console.log("success")
        this.poDetails = []
        this.poDetails.push(resp.message)
        console.log(this.poDetails)
        this.showPOs()
      } else {
        if (this.isDarkTheme === 'light') {
          Swal.fire({

            heightAuto: false,
            icon: 'error',
            text: resp.message,
            width: 500,
          });
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({

            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + resp.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',




          });
        }
        return;
      }
    }, (err) => {
      this.spinner.hide()

      if (this.isDarkTheme === 'light') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500,
        });
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + err.error.errorMessage + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
      return;
    })
  }
  identifier() {
    console.log("in")
    $('#collapseIdentifier').on('hidden.bs.collapse', () => {
      const identifierForm = document.getElementById('batchForm') as HTMLFormElement;
      identifierForm.reset();
      this.modelShipments = [{
        uom: this.shippingData[0].primaryUom,
        quantity: '',
        needbydate: moment(this.selectedNeedByDate).subtract(3, 'days').format('YYYY-MM-DD'),
        deliverysite: '',
        serialNum: 1,
      }]
    });
  }
  onDeliverySiteSelect(e: any, i: any) {
    // console.log(e)
    // this.modelShipments[i].deliverysite=e
    // console.log(this.modelShipments[i].deliverysite)
  }
  addItem(index) {
    console.log(this.modelShipments)
    this.modelShipments.push({
      uom: this.shippingData[0].primaryUom,
      quantity: '',
      needbydate: moment(this.selectedNeedByDate).subtract(3, 'days').format('YYYY-MM-DD'),
      deliverysite: '',
      serialNum: ++index,
    })
    // console.log(this.modelBranch);
    this.spinner.hide()
  }
  submitsplit(form: NgForm) {
    console.log(this.poDetails, "podetails")
    // console.log(this.modelShipments)

    if (form.invalid) {
      console.log("form", form)
      return
    }
    console.log(this.modelShipments)
    let splittingQuantity = this.modelShipments.reduce((a, b) => {
      return a + b.quantity
    }, 0)
    console.log(splittingQuantity, "sq")
    if (this.popupdata[0].quantity.toString() !== splittingQuantity.toString()) {

      if (this.isDarkTheme === 'light') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          text: 'Total quantity should be ' + this.popupdata[0].quantity,
          width: 500,
        });
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + 'Total quantity should be ' + this.popupdata[0].quantity + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
      return;
    }
    this.modelShipments.map((x: any) => {
      if (moment(x.needbydate).format('DD-MM-YYYY') > this.selectedNeedByDateShow) {

        if (this.isDarkTheme === 'light') {
          Swal.fire({

            heightAuto: false,
            icon: 'error',
            text: 'Need by date should be less than or equal to of this date : ' + this.selectedNeedByDateShow,
            width: 500,
          });
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({

            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + 'Need by date should be less than or equal to of this date : ' + this.selectedNeedByDateShow + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',




          });
        }
        return;
      }
    })
    let item = this.popupdata[0].itemName
    for (let i = 0; i < this.poDetails[0].lineItems.length; i++) {
      console.log(this.poDetails[0].lineItems[i].lineno, "2")
      if (this.poDetails[0].lineItems[i].itemName === item && this.poDetails[0].lineItems[i].lineno === this.popupdatalineno) {
        this.poDetails[0].lineItems[i].splittingData = this.modelShipments
      }
    }

    if (this.isDarkTheme === 'light') {

      Swal.fire({
        title: 'Success',
        icon: 'success',
        text: "Validated Succesfully",
        // showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          this.closemodal()
        }
      })
    } else if (this.isDarkTheme === 'dark') {

      Swal.fire({
        title: 'Success',
        icon: 'success',
        html: "<p style='color:#fff'>" + 'Validated Succesfully' + "</p>",
        // showCancelButton: true,

        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        background: '#333',
        iconColor: '#fff',
        confirmButtonColor: '#272727',
      }).then((result) => {
        if (result.isConfirmed) {
          this.closemodal()
        }
      })
    }
  }
  SubmitConsolidatePo(form: NgForm) {
    console.log(this.poDetails, "submit")
    if (form.invalid) {
      return;
    }

    if (this.isDarkTheme === 'light') {
      Swal.fire({
        title: 'Confirm..!',
        text: "Confirm to raise consolidate PO",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'CONFIRM'
      }).then((result) => {
        if (result.isConfirmed) {
          this.finalSubmitConsolidatePO()
        }
      })
    } else if (this.isDarkTheme === 'dark') {
      Swal.fire({
        title: 'Confirm..!',
        html: "<p style='color:#fff'>" + 'Confirm to raise consolidate PO' + "</p>",
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: 'CONFIRM',
        background: '#333',
        iconColor: '#fff',
        confirmButtonColor: '#272727'
      }).then((result) => {
        if (result.isConfirmed) {
          this.finalSubmitConsolidatePO()
        }
      })

    }
  }
  backRoute() {
    this.router.navigate(['consolidatePo'])
  }
  raiseConsolidatePO() {
    this.spinner.show()
    this.consolidatePOService.getManufacturers().subscribe((resp: any) => {
      this.spinner.hide()
      if (resp.status === 'Success') {
        this.raiseConsolidateData = resp.data
      } else {

        if (this.isDarkTheme === 'light') {
          Swal.fire({

            heightAuto: false,
            icon: 'error',
            text: resp.message,
            width: 500,
          });
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({

            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + resp.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',




          });
        }
      }
    }, (err) => {

      this.spinner.hide()

      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        });
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + err.error.errorMessage + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
    })
  }
  onmanufactureSelect(e: any) {
    console.log(e)
    let obj = {
      "dcOrg": e.ParticipantID
    }
    this.spinner.show()
    this.consolidatePOService.getSiteByManufacturer(obj).subscribe((resp: any) => {
      this.spinner.hide()
      if (resp.status === 'Success') {
        this.siteCodeData = resp.data
      }
      else {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            text: resp.message,
            width: 500
          });
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
  
            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + resp.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',
  
  
  
  
          });
        }
      }
    }, (err) => {
      this.spinner.hide()
      
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        });
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + err.error.errorMessage + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
    }
    )
  }
  onmanufactureDeSelect(e: any) {
    this.siteCodeData = []
    this.siteCode = []
  }
  finalSubmitConsolidatePO() {
    this.spinner.show()
    let lineitemDetails = this.poDetails[0].lineItems
    console.log(lineitemDetails)
    this.respSplitShipments = []
    this.respShipments = []
    this.respShipments = []
    for (let i = 0; i < lineitemDetails.length; i++) {
      let lineDetails = lineitemDetails[i]
      for (let j = 0; j < lineitemDetails[i].splittingData.length; j++) {
        let obj = {
          itemName: lineitemDetails[i].itemName,
          needByDate: lineitemDetails[i].splittingData[j].needbydate,
          primaryUom: lineitemDetails[i].splittingData[j].uom,
          quantity: lineitemDetails[i].splittingData[j].quantity.toString(),
          secondaryUom: lineitemDetails[i].secondaryUom,
          shiptoLocation: lineitemDetails[i].splittingData[j].deliverysite[0] === 'RSE-Vizag' ? lineitemDetails[i].splittingData[j].deliverysite[0] = 'SITE007' : lineitemDetails[i].splittingData[j].deliverysite[0]
        }
        for (let k = 0; k < lineitemDetails[i].shipping[0].shipmentNumbers.length; k++) {
          let obj2 = {
            needByDate: lineitemDetails[i].splittingData[j].needbydate,
            poRefNumber: lineDetails.shipping[0].shipmentNumbers[k].poRefNumber,
            quantity: lineitemDetails[i].splittingData[j].quantity.toString(),
            shipmenRefNumber: lineDetails.shipping[0].shipmentNumbers[k].shipmenRefNumber,
            shiptoLocation: lineitemDetails[i].splittingData[j].deliverysite[0] === 'RSE-Vizag' ? lineitemDetails[i].splittingData[j].deliverysite[0] = 'SITE007' : lineitemDetails[i].splittingData[j].deliverysite[0]
          }
          this.respShipments.push(obj2)
        }
        this.respSplitShipments.push(obj)
      }
    }
    let dataobj = {
      "buyerID": this.LoggedUserDetails.email,
      "buyerName": this.LoggedUserDetails.name,
      "buyerOrg": this.LoggedUserDetails.userRoledcorg,
      "poSite": this.LoggedUserDetails.sitecode,
      "poreferences": this.selectedpo,
      "needByDate": moment(this.selectedNeedByDate).format('DD-MM-YYYY'),
      "shipments": this.respShipments,
      "splitshipments": this.respSplitShipments,
      "posendto": {
        "manfHubName": this.raiseConsolidate[0].ParticipantName,
        "manfHubID": this.raiseConsolidate[0].ParticipantID,
        "poNumber": this.poNumber,
        "siteCode": this.siteCode[0].siteCode
      }
    }
    this.consolidatePOService.finalsubmitconsolidatepo(dataobj).subscribe((resp: any) => {
      this.spinner.hide()
      if (resp.status === 'Success') {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Success',
            html: '<strong>' + resp.message[0].message + '</strong></br><b>TRXID:</b>' + resp.message[0].transactionID,
            icon: 'success'
          }).then(() => {
            this.backRoute()
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            title: 'Success',
            html: "<p style='color:#fff'>" + resp.message[0].message + '</strong></br><b>TRXID:</b>' + resp.message[0].transactionID + "</p>",
            showCancelButton: true,
           
            background: '#333',
            iconColor: '#fff',
          }).then((result) => {
            this.backRoute()
          })
    
        }
      } else {
       
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            heightAuto: false,
            icon: 'error',
            text: resp.message,
            width: 500
          });
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
  
            heightAuto: false,
            icon: 'error',
            html: "<p style='color:#fff'>" + resp.message + "</p>",
            width: 500,
            background: '#333',
            iconColor: '#fff',
            confirmButtonColor: '#272727',
  
  
  
  
          });
        }
      }
    }, (err) => {
      this.spinner.hide()
     
      if (this.isDarkTheme === 'light') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          text: err.error.errorMessage,
          width: 500
        });
      } else if (this.isDarkTheme === 'dark') {
        Swal.fire({

          heightAuto: false,
          icon: 'error',
          html: "<p style='color:#fff'>" + err.error.errorMessage + "</p>",
          width: 500,
          background: '#333',
          iconColor: '#fff',
          confirmButtonColor: '#272727',




        });
      }
    })


  }
}
