import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { loginDetails } from 'src/app/models/login';
import { ThemeService } from 'src/app/themes/theme.service';
import { environment } from 'src/environments/environment';
import { LoginServiceService } from 'src/app/services/login-service.service'
import { AuthService } from 'src/app/services/auth.service'
declare var $: any
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  model = new loginDetails();
  Email = ''
  Password = ''
  env: any;
  emailVerified: any;
  dropdownSettings = {}
  orgData = []
  orgDetails = []
  dropdownSettingsUserType = {}
  userTypeDetails = []
  userTypeData = []
  constructor(private route: Router, private authService: AuthService, private themeService: ThemeService, private spinner: NgxSpinnerService, private toastr: ToastrService, private loginService: LoginServiceService) { }

  async ngOnInit(){
    if (sessionStorage.getItem('tokenscc')) {
      this.route.navigate(['dashboard'])
      return;
    }
    
    $(function () {
      $('[data-toggle="popover"]').popover({
        html: true,
        trigger: "hover",
        content: function () {
          return $('#popover-content').html();
        }
      })
    })
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'orgName',
      textField: 'orgName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
    };
    this.dropdownSettingsUserType = {
      singleSelection: true,
      idField: 'userTypeID',
      textField: 'userTypeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
    };
    await this.loginService.getOrganisationList().subscribe((resp: any) => {
      this.orgData = resp.data;
    })
  }
  submit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    console.log(this.orgDetails, "orgDetails")
    let x = this.orgData.filter((x: any) => x.orgName === this.orgDetails[0].orgName)
    let obj = {
      "dcOrg": x[0].dcOrg,
      "email": this.model.user,
      "passcode": this.model.password,
      "userClassification": this.userTypeDetails[0].userTypeID
    }
    this.spinner.show()
    this.loginService.loginClick(obj).subscribe((resp: any) => {
      this.spinner.hide()
      if (resp.status === 'Success') {
        let party_Type;
        this.toastr.success('Login Successful');
        if (resp.dcOrg.substring(0,2) === 'mh') {
          if (resp.userClassification === '2') {
            party_Type = 'MHSALES'
          } else if (resp.userClassification === '3') {
            party_Type = 'MHBUYER'
          } else if(resp.userClassification === '1'){
            party_Type = 'MANFACTURINGHUBADMIN'
          }else if(resp.userClassification === '4'){
            party_Type='MHFULL'
          }
        } else if (resp.dcOrg.substring(0,3) === 'rse') {
          if (resp.userClassification === '3') {
            party_Type = 'Rsebuyer'
          }else if (resp.userClassification === '1') {
            party_Type = 'RSEADMIN'
          }
        } else if (resp.dcOrg.substring(0,2) === 'cm') {
          if (resp.userClassification === '2') {
            party_Type = 'CONTRACTORMANFSALES'
          } else if (resp.userClassification === '3') {
            party_Type = 'CONTRACTORMANFBUYER'
          } else if(resp.userClassification === '4'){
            party_Type ='CONTRACTORMANFFULL'
          } else if(resp.userClassification === '1'){
            party_Type='CONTRACTORMANFADMIN'
          }
        } else if (resp.dcOrg.substring(0,4) === 'supp') {
          if (resp.userClassification === '2') {
            party_Type = 'SUPPLIERSALES'
          } else if (resp.userClassification === '4') {
            party_Type = 'SUPPLIERFULL'
          } else if(resp.userClassification === '1'){
            party_Type='SUPPLIERADMIN'
          }

        } else if (resp.dcOrg === 'supp2') {
          if (resp.userClassification === '2') {
            party_Type = 'SUPPLIERSALES'
          } else if (resp.userClassification === '4') {
            party_Type = 'SUPPLIERFULL'
          } else if(resp.userClassification === '1'){
            party_Type='SUPPLIERADMIN'
          }

        }

        let data = {
          email: resp.email,
          name: resp.name,
          orgtypeid: resp.orgTypeID,
          orgtypename: resp.orgTypeName,
          partyIDdcorg: resp.dcOrg,
          partyType: party_Type,
          sitecode: resp.siteCode,
          userRoledcorg: resp.dcOrg,

        }
        this.authService.setToken(resp.token)

        this.authService.setLoginDetails(data)
        this.route.navigate([''])
        // let x = this.authService.getLoginDetails()
        // console.log(x)
      }

    }, (err: any) => {
      this.spinner.hide()
      this.toastr.error(err.error.errorMessage);
    })
  }
  onOrganisationSelect(e: any) {
    // console.log(this.orgData)
    this.spinner.show()
    let x = this.orgData.filter((x: any) => x.orgName === e.orgName)
    let obj = {
      'orgTypeID': x[0].orgTypeID
    }
    sessionStorage.setItem("orgName",x[0].orgName)
    this.loginService.getUserTypeByOrgId(obj).subscribe((resp: any) => {
      this.spinner.hide()
      this.userTypeData = resp.data;
    })
  }
  onOrganisationDeSelect(e: any) {

  }
  onUserTypeSelect(e: any) {
    console.log(e)
  }
  onUserTypeDeSelect(e: any) {

  }
  reset() {
    this.model.user = ''
    this.model.password = ''
    this.orgDetails = []
    this.userTypeDetails = []
    this.userTypeData = []
  }
  inputChange(event) {
    let value = event.target.value.trimEnd();
    this.model.user = value.trimStart();
    sessionStorage.setItem('userName', JSON.stringify(this.model.user))

  }
  login(form: NgForm) {

  }


}
