import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { ThemeService } from 'src/app/themes/theme.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dealer-info',
  templateUrl: './dealer-info.component.html',
  styleUrls: ['./dealer-info.component.scss']
})
export class DealerInfoComponent implements OnInit {
  modaldata = {
    podate: null, poaccepteddate: null, salesorderno: null, asndetails: null, poacceptance: false,
    document: null, orderacceptance: null, asncompletedetails: null, asnnumber: null, awbnumber: null, ds: null, ps: null
  };
  columnDefs
  defaultColDef;
  columnDefs1;
  columnDefs2;
  pagination = true;
  paginationPageSize;
  rowData: any = [];
  shippingData: any = [];
  store: any = [];
  poType;
  userId;
  status1;
  domLayout: any;
  rowSelection;
  autoGroupColumnDef;
  maxBlocksInCache;
  sideBar;
  userType = "SUPPLIER";
  isDarkTheme;
  detailCellRendererParams;
  getRowHeight;
  constructor(private admin: AdminService, private router: Router,private spinner:NgxSpinnerService, private activatedRoute: ActivatedRoute, private themeService: ThemeService) {
    this.domLayout = 'autoHeight';

    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.rowSelection = 'single'

    this.autoGroupColumnDef = {
      icons: {
        menu: '<em class="fa fa-shower"/>',
        filter: '<em class="fa fa-long-arrow-alt-up"/>',
        columns: '<em class="fa fa-snowflake"/>',
        sortAscending: '<em class="fa fa-sort-alpha-up"/>',
        sortDescending: '<em class="fa fa-sort-alpha-down"/>',
      },
      headerCheckboxSelection: true,
      width: 300,
    },
      this.paginationPageSize = 10;
    this.maxBlocksInCache = 1;
    this.pagination = true;
    this.sideBar = {
      toolPanels: [

        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },


        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },


      ],

    };
  }

  ngOnInit(): void {
    this.poType = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).userRoledcorg
    this.userId = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email
    this.status1 = this.activatedRoute.snapshot.queryParamMap.get('status');
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;

    });
    // if (this.status1 === 'allorders') {
    this.allorders();
    // }
    // else if(this.status1 === 'shipmentsArrivingToday'){

    // }
  }
  shouldHide() {
    if (this.status1 === 'shipmentsmissingduedate' || this.status1 === 'allshipments') {
      return false
    }
    else {
      return true;
    }
  }
  allorders() {
    this.columnDefs = [
      {
        headerName: 'CUSTOMER NAME',
        field: 'customerName',
        filter: 'agSetColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'CUSTOMER NAME',
        tooltipField: 'customerName',

      },
      {
        headerName: 'CUSTOMER SITE',
        field: 'customerSiteName',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'CUSTOMER SITE',
        tooltipField: 'customerSiteName',

      },
      {
        headerName: 'PO DATE', field: 'poDate', filter: true, headerTooltip: 'PO DATE',
        tooltipField: 'poDate',
      },
      {
        headerName: 'PO #', field: 'poNumber', filter: true, headerTooltip: 'PO #',
        tooltipField: 'poNumber',
      },
      {
        headerName: 'DELIVERY SITE', field: 'poCurrentVersion', filter: true, headerTooltip: 'DELIVERY SITE', hide: this.shouldHide(),
        tooltipField: 'poCurrentVersion',
      },
      {
        headerName: 'PO VERSION #', field: 'poCurrentVersion', filter: true, headerTooltip: 'PO VERSION #',
        tooltipField: 'poCurrentVersion',
      },
      {
        headerName: 'STATUS', field: 'poStatus', filter: true, headerTooltip: 'STATUS', hide: this.shouldHide(),
        tooltipField: 'poStatus',
        onCellClicked: this.poStatus.bind(this),
        cellRenderer: (params: ICellRendererParams) => {
          return `<strong><a class="text-center d-flex" >${params.value} </a></strong>`;

        },
      },
    ];
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: 'LINE #', field: 'lineno',
            headerTooltip: 'LINE #',
            tooltipField: 'lineno',
            onCellClicked: this.popupopen.bind(this),
            cellRenderer: (params: ICellRendererParams) => {
              return `<strong><a class="text-center d-flex" >${params.value} </a></strong>`;

            },
          },
          {
            headerName: 'ITEM NAME', field: 'itemName',
            headerTooltip: 'ITEM NAME',
            tooltipField: 'itemName',

          },
          {
            headerName: 'UOM', field: 'primaryUom',
            headerTooltip: 'UOM',
            tooltipField: 'primaryUom',

          },
          {
            headerName: 'CANCELLATION STATUS', field: 'cs',
            headerTooltip: 'CANCELLATION STATUS',
            tooltipField: 'cs',
            cellRenderer: (params: ICellRendererParams) => {
              if (params.data.cs === undefined) {
                return 'No'
              }
            }

          },
          {
            headerName: 'LINE VERSION #', field: 'lineVersion',
            headerTooltip: 'LINE VERSION #',
            tooltipField: 'lineVersion',

          },

        ],

        defaultColDef: {
          flex: 1, searchable: false, floatingFilter: false, sortable: true, resizable: true, enableRangeSelection: true, popupParent: document.body
        },

      },

      getDetailRowData(params) {
        params.successCallback(params.data.lineItems);

      },

    };
    this.getRowHeight = params => {
      const defRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
      if (params.node.detail) {
        const offset = 100;
        const detailRowHeight =
          params.data.lineItems.length * defRowHeight;
        var gridSizes = params.api.getSizesForCurrentTheme();
        return detailRowHeight + gridSizes.headerHeight + offset;
      } else {
        return defRowHeight;
      }
    }
    if (this.status1 === 'duetoday') {
      this.spinner.show()
      this.admin.SuppadminshipmentsDueInNextNDays(this.poType, this.userId, this.userType, '0').subscribe((res: any) => {
        this.spinner.hide()
        if (res.status === 'Fail') {
         
          
          if (this.isDarkTheme === 'light') {
            Swal.fire({
              title: 'Error',
              text: res.message,
              icon: 'error'
            })
          } else if (this.isDarkTheme === 'dark') {
            Swal.fire({
              title: 'Error',
              html: '<strong>' +res['message']+ '</strong>',
              icon: 'error',
              background: '#333',
              iconColor: '#fff',
            })
            
          }
        }
        if (res.data.length === 0) {
          
          if (this.isDarkTheme === 'light') {
            Swal.fire({

              text: 'No data found',
  
            })
          } else if (this.isDarkTheme === 'dark') {
            Swal.fire({
              html: '<strong>' +'No data found'+ '</strong>',
              background: '#333',
              iconColor: '#fff',
            })
            
          }
        } else {
          this.rowData = res.data
        }
       
        console.log(this.rowData, "row data")
      })
    }
    else if (this.status1 === 'shipmentsmissingduedate') {
      this.spinner.show()
      this.admin.SuppadminshipmentsMissedDueDate(this.poType, this.userId, this.userType).subscribe((res: any) => {
        this.spinner.hide()
        this.rowData = res.data;
      })
    }
    else if (this.status1 === 'dueinnextsevendays') {
      this.spinner.show()
      this.admin.SuppadminshipmentsDueInNextNDays(this.poType, this.userId, this.userType, '7').subscribe((res: any) => {
        this.spinner.hide()
        this.rowData = res.data;
      })
    }
    else if (this.status1 === 'intransit') {
      this.spinner.show()
      this.admin.SuppadminshipmentsInTransit(this.poType, this.userId, this.userType).subscribe((res: any) => {
        this.spinner.hide()
        this.rowData = res.data;
      })
    }
    else if (this.status1 === 'allinwardspo') {
      this.spinner.show()
      this.admin.Suppadmininwardpos(this.poType, this.userId, this.userType).subscribe((res: any) => {
        this.spinner.hide()
        this.rowData = res.data;
      })
    }else{
      this.spinner.show()
      this.admin.Suppadminallshipments(this.poType, this.userId, this.userType).subscribe((res: any) => {
        this.spinner.hide()
        this.rowData = res.data;
      })
    }
  }
  popupopen(data) {
    console.log(data.data.shipping, "data")
    this.shippingData = data.data.shipping;
    this.columnDefs1 = [
      {
        headerName: 'SHIPMENT #',
        field: 'shipmentNumber',
        filter: 'agSetColumnFilter',

        chartDataType: 'category',
        headerTooltip: 'SHIPMENT #',
        tooltipField: 'shipmentNumber',

      },
      {
        headerName: 'ITEM NAME',
        field: 'itemName',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'ITEM NAME',
        tooltipField: 'itemName',

      },
      {
        headerName: 'QUANTITY', field: 'quantity', filter: true, headerTooltip: 'QUANTITY',
        tooltipField: 'quantity',
      },
      {
        headerName: 'CANCELLATION STATUS', field: 'cs', filter: true, headerTooltip: 'CANCELLATION STATUS',
        tooltipField: 'cs',
        cellRenderer: (params: ICellRendererParams) => {
          if (params.data.cs === undefined) {
            return 'No'
          }
        }
      },
      {
        headerName: 'SHIPMENT VERSION #', field: 'shipmentVersion', filter: true, headerTooltip: 'SHIPMENT VERSION #',
        tooltipField: 'shipmentVersion',
      },
      {
        headerName: 'NEED BY DATE', field: 'needByDate', filter: true, headerTooltip: 'NEED BY DATE',
        tooltipField: 'needByDate',
      },
      {
        headerName: 'DELIVERY SITE', field: 'shiptoLocation', filter: true, headerTooltip: 'DELIVERY SITE',
        tooltipField: 'shiptoLocation',
      },
    ];
    document.getElementById('myNav').style.width = '80%';
  }
  closemodal() {
    document.getElementById('myNav').style.width = '0';
  }
  closemodal1() {
    document.getElementById('myNav1').style.width = '0';
  }
  poStatus(data) {
    console.log(data.data, "datat")
    if (data.data.poAcceptance === true) {
      this.modaldata.podate = data.data.poDate;
      this.modaldata.poaccepteddate = data.data.poAccepetdDate;
      this.modaldata.salesorderno = data.data.salesOrderNumber;
    }
    this.columnDefs2 = [
      {
        headerName: 'S.NO',
        field: 'sno',
        filter: 'agSetColumnFilter',

        chartDataType: 'category',
        headerTooltip: 'S.NO',
        tooltipField: 'sno',

      },
      {
        headerName: 'ITEM NAME',
        field: 'itemname',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'ITEM NAME',
        tooltipField: 'itemname',

      },
      {
        headerName: 'AWB #', field: 'awbno', filter: true, headerTooltip: 'AWB #',
        tooltipField: 'awbno',
      },

      {
        headerName: 'ASN #', field: 'asnno', filter: true, headerTooltip: 'ASN #',
        tooltipField: 'asnno',
      },
      {
        headerName: 'PICKED UP DATE', field: 'pickupdate', filter: true, headerTooltip: 'PICKED UP DATE',
        tooltipField: 'pickupdate',
      },
      {
        headerName: 'STATUS', field: 'ds', filter: true, headerTooltip: 'STATUS',
        tooltipField: 'ds',
      },
      {
        headerName: 'DELIVERED DATE', field: 'delivereddate', filter: true, headerTooltip: 'DELIVERED DATE',
        tooltipField: 'delivereddate',
      },

    ];
    let store: any = [];
    for (var i = 0; i < data.data.lineItems.length; i++) {
      for (var j = 0; j < data.data.lineItems[i].shipping.length; j++) {

        if (data.data.lineItems[i].shipping != null) {

          store.push({
            'sno': j + 1,
            'itemname': data.data.lineItems[i].shipping[j] != null ? data.data.lineItems[i].shipping[j].itemName : 'NA',
            'awbno': data.data.lineItems[i].shipping[j] != null ? data.data.lineItems[i].shipping[j].awbno : 'NA',
            'asnno': 'NA',
            'ps': 'NA',
            'pickupdate': 'NA',
            'ds': 'NA',
            'delivereddate': 'NA'
          });
          console.log(this.store, "print");
        }
      }

    }
    this.store = store
    document.getElementById('myNav1').style.width = '80%';
  }
  back() {
    this.router.navigate([''])
  }
}
