import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { ThemeService } from 'src/app/themes/theme.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adminscreen',
  templateUrl: './adminscreen.component.html',
  styleUrls: ['./adminscreen.component.scss']
})
export class AdminscreenComponent implements OnInit {
  isDarkTheme;
  orgnamedisplay;
  ponumberdisplay;

  dropdownSettings = {};
  dropdownSettings1 = {};
  poType;
  userId;
  dealerList: any = [];
  loadPOData: any = [];
  dealerPO;
  poStatus1;
  orgname1;
  orgsite: any;
  rsePO: any;
  mhPO: any;
  cmPO: any;
  selected = null;
  selected1 = null;
  selected2 = null;
  dropdownList: any;
  linesdata: any;
  status2: any;
  sendorg1: any;
  status3: any;
  sendorg2: any;
  status4: any;
  check = false;
  check2 = false
  check3: boolean;
  check4 = false;
  poNumber
  orgnmae3;
  orgname2;
  detailCellRendererParams;
  getRowHeight;
  columnDefs
  defaultColDef;
  pagination = true;
  paginationPageSize;
  rowData: any = [];
  domLayout: any;
  rowSelection;
  autoGroupColumnDef;
  maxBlocksInCache;
  sideBar;
  loggedUser
  orgType
  constructor(private themeService: ThemeService, private admin: AdminService,private spinner:NgxSpinnerService) {
    this.domLayout = 'autoHeight';

    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.rowSelection = 'single'

    this.autoGroupColumnDef = {
      icons: {
        menu: '<em class="fa fa-shower"/>',
        filter: '<em class="fa fa-long-arrow-alt-up"/>',
        columns: '<em class="fa fa-snowflake"/>',
        sortAscending: '<em class="fa fa-sort-alpha-up"/>',
        sortDescending: '<em class="fa fa-sort-alpha-down"/>',
      },
      headerCheckboxSelection: true,
      width: 300,
    },
      this.paginationPageSize = 10;
    this.maxBlocksInCache = 1;
    this.pagination = true;
    this.sideBar = {
      toolPanels: [

        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },


        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },


      ],

    };
   }

  ngOnInit(): void {
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;
    });
    this.poType = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).userRoledcorg
    this.userId = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email;
    this.loadDealerData()
    this.loggedUser = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).name;
    this.orgType = sessionStorage.getItem("orgName")
  }

  loadDealerData() {
    this.spinner.show()
    this.admin.dealersList().subscribe((resp: any) => {
      this.spinner.hide()
      console.log(resp, "resp")
      if (resp['status'] === 'Fail') {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: resp['message'],
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            title: 'Error',
            html: '<strong>' +resp['message'] + '</strong>',
            icon: 'error',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
      }
      if (resp['data'].length === 0) {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({

            text: 'No data found'
  
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            
            html: '<strong>' +'No data found'+ '</strong>',
            
            background: '#333',
            iconColor: '#fff',
          })
          
        }
      } else {
        let newData = [...new Set(resp['data'])]
        let dealer: any = [];
        let newData1: any = [];
        // newData1.push(newData)
        console.log(newData, "new data")
        for (var i = 0; i < newData.length; i++) {
          if (newData[i] != "") {
            dealer[i] = { 'item_id': newData[i], 'item_text': newData[i] }
          }
        }
        this.dealerList = dealer
        console.log(this.dealerList, "deal")
        this.dropdownSettings = {
          singleSelection: true,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true,
          closeDropDownOnSelection: true
        };
      }
    })
  }
  onItemSelect(event) {
    console.log(event, "event")
    this.loadPo(event.item_id)
  }
  loadPo(id) {
    this.selected  = null;
    this.selected1 = null;
    this.selected2 = null;
    this.check = false;
    this.check3 = false;
    this.check2 = false;
    this.check4 = false;
    this.poNumber = null;
    this.spinner.show()
    this.admin.getDealerPO('dealer', id).subscribe((res: any) => {
      this.spinner.hide()
      this.loadPOData = res.data
      this.dropdownSettings1 = {
        singleSelection: true,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        closeDropDownOnSelection: true
      };
    })
  }
  onItemSelect1(event) {
    this.poNumber = event
    this.selected  = null;
    this.selected1 = null;
    this.selected2 = null;
    this.poStatus1 = null;
    this.check = false;
    this.check3 = false;
    this.check2 = false;
    this.check4 = false;
    console.log(event, "event")
    this.rsePo(event)
  }
  rsePo(id) {
    let dcOrg;
    if (JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).partyIDdcorg === 'rse') {
      dcOrg = 'dealer'
    }
    this.spinner.show()
    this.admin.getPOTracking(dcOrg, id).subscribe((res: any) => {
      this.spinner.hide()
      if (res['status'] == 'Fail') {
        

        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: res['message'],
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            title: 'Error',
            html: '<strong>' +res['message']+ '</strong>',
            icon: 'error',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
      }
      if (res['data']['mergedInPOsList'] === undefined) {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            text: res['data'],
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            
            html: '<strong>' +res['data']+ '</strong>',
            
            background: '#333',
            iconColor: '#fff',
          })
          
        }
      }else{
        this.dealerPO = res['data']['mergedInPOsList']
        this.poStatus1 = res['data']['poStatus']
        this.orgname1 = res['data']['orgName']
        this.orgname1 = "Regional Sales Entity"
        this.check = true;
      }
      
    })
  }
  loadRSEPO(ponum, num) {

    this.selected2 = null;
    this.selected1 = null;
    this.selected = num;
    this.status2 = null;
    this.sendorg1 = null;
    this.check3 = false;
    this.check4 = false;
    this.check2 = false;
    console.log("rsepo", ponum)
    const dis = this;
    this.spinner.show()
    this.admin.getPOTracking(JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).partyIDdcorg, ponum).subscribe(resp => {
      console.log("RSE-PO", resp)
      this.spinner.hide()
      if (resp['status'] == 'Fail') {
       
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: resp['message'],
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            title: 'Error',
            html: '<strong>' +resp['message']+ '</strong>',
            icon: 'error',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
      }
      if (resp['data']['mergedInPOsList'] === undefined) {
        
        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: resp['message'],
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            title: 'Error',
            html: '<strong>' +resp['message']+ '</strong>',
            icon: 'error',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
      }
      dis.rsePO = resp['data']['mergedInPOsList']
      dis.status2 = resp['data']['poStatus']
      dis.sendorg1 = resp['data']['toOrgID']
      dis.orgname2 = resp['data']['orgName']
      if (dis.orgname2 == 'mh1') {
        dis.orgname2 = "MANUFACTURING HUB - 1"
      } else if (dis.orgname2 == 'mh2') {
        dis.orgname2 = "MANUFACTURING HUB - 2"
      } else dis.orgname2 = resp['data']['orgName'];
      console.log(dis.orgname2, "orgname")

      this.check2 = true;

    },
    );


  }
  getLines(row, org) { 
    this.ponumberdisplay = null;
    this.orgnamedisplay = null;
    this.ponumberdisplay = row;
    this.orgnamedisplay = org
    this.getTableData()
    this.spinner.show()
    this.admin.getTrackinglines('rse',row).subscribe((res:any)=>{
      this.spinner.hide()
      this.rowData = res.data
    })
  }
  getTableData(){
    document.getElementById('myNav').style.width = '80%';
    this.columnDefs = [
      {
        headerName: 'LINE #',
        field: 'lineNum',
        filter: 'agSetColumnFilter',
        
        chartDataType: 'category',
        headerTooltip: 'LINE #',
        tooltipField: 'lineNum',

      },
      {
        headerName: 'SHIPMENT #',
        field: 'shipmentNumber',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'SHIPMENT #',
        tooltipField: 'shipmentNumber',

      },
      {
        headerName: 'ITEM NAME', field: 'itemName', filter: true, headerTooltip: 'ITEM NAME',
        tooltipField: 'itemName',
      },
      {
        headerName: 'NEED BY DATE', field: 'needByDate', filter: true, headerTooltip: 'NEED BY DATE',
        tooltipField: 'needByDate',
      },
      {
        headerName: 'ASN STATUS', field: 'asnStatus', filter: true, headerTooltip: 'ASN STATUS', 
        tooltipField: 'asnStatus',
      },
      {
        headerName: 'ASN DATE', field: 'asnCreatedDate', filter: true, headerTooltip: 'ASN DATE',
        tooltipField: 'asnCreatedDate',
      },
      {
        headerName: 'ASN #', field: 'asnNumber', filter: true, headerTooltip: 'ASN #',
        tooltipField: 'poCurrentVasnNumberersion',
      },
      {
        headerName: 'TRACKING #', field: 'awbNumber', filter: true, headerTooltip: 'TRACKING #',
        tooltipField: 'awbNumber',
      },
      {
        headerName: 'EXPECTED DATE OF ARRIVAL', field: 'ExpectedDateOfArrival', filter: true, headerTooltip: 'EXPECTED DATE OF ARRIVAL',
        tooltipField: 'ExpectedDateOfArrival',
      },
      {
        headerName: 'DELIVERY STATUS', field: 'deliveryStatus', filter: true, headerTooltip: 'DELIVERY STATUS',
        tooltipField: 'deliveryStatus',
      },
      {
        headerName: 'DELIVERY DATE', field: 'deliveryDate', filter: true, headerTooltip: 'DELIVERY DATE',
        tooltipField: 'deliveryDate',
      },
    ];
  }

  isClassVisible (num) {
    return this.selected == num;
  }
  isClassVisible1 (num) {
    return this.selected1 == num;
  }
  isClassVisible2 (num) {
    return this.selected2 == num;
  }
  getLines_MH(row,org){
    this.ponumberdisplay = null;
    this.orgnamedisplay = null;
    this.ponumberdisplay = row;
    this.orgnamedisplay = org;
    this.getTableData()
    this.spinner.show()
    this.admin.getTrackinglines(this.sendorg1,row).subscribe((res:any)=>{
      this.spinner.hide()
      this.rowData= res.data
    })
  }
  getLines_CM(row,org){
    this.ponumberdisplay = null;
    this.orgnamedisplay = null;
    this.ponumberdisplay = row;
    this.orgnamedisplay = org;
    this.getTableData();
    this.spinner.show()
    this.admin.getTrackinglines(this.sendorg2,row).subscribe((res:any)=>{
      this.spinner.hide()
      this.rowData = res.data
    })
  }

  loadMH_PO(ponum, num){
    this.check3 = false;
    this.check4 = false;
    this.selected1 = num;
    this.status3 = null;
    this.sendorg2 = null;
    console.log(this.selected1);
  
    
    console.log("mhpo", ponum)
    const dis = this;
    console.log("Sending values-MH-PO", dis.sendorg1, ponum)
    this.spinner.show()
    this.admin.getPOTracking(dis.sendorg1, ponum).subscribe(resp => {
      this.spinner.hide()
      console.log("MH-PO", resp)
      if (resp['status'] == 'Fail'){
        

        if (this.isDarkTheme === 'light') {
          Swal.fire({
            title: 'Error',
            text: resp['message'],
            icon: 'error'
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            title: 'Error',
            html: '<strong>' +resp['message']+ '</strong>',
            icon: 'error',
            background: '#333',
            iconColor: '#fff',
          })
          
        }
      }
      
      if (resp['data']['mergedInPOsList'] === undefined )
      {
       
        if (this.isDarkTheme === 'light') {
          Swal.fire({
       
            text: resp['data'],
            
          })
        } else if (this.isDarkTheme === 'dark') {
          Swal.fire({
            
            html: '<strong>' +resp['data']+ '</strong>',
            
            background: '#333',
            iconColor: '#fff',
          })
          
        }
      }else{
        dis.mhPO = resp['data']['mergedInPOsList'];
        dis.status3 = resp['data']['poStatus']
        dis.sendorg2 = resp['data']['toOrgID']
        dis.orgnmae3 = resp['data']['orgName']
        if ( dis.orgnmae3 == 'cm1'){
          dis.orgnmae3 = "Tier1 Supplier - 1"
        } else if ( dis.orgnmae3 == 'cm2'){
          dis.orgnmae3 = "Tier1 Supplier - 2"
        } else  dis.orgnmae3 = resp['data']['orgName'];

        this.check3 = true;
      }
        
       
    },
    );
  }
  deselectList(){
    this.selected  = null;
    this.selected1 = null;
    this.selected2 = null;
    this.check = false;
    this.check3 = false;
    this.check2 = false;
    this.check4 = false;
    this.poNumber = null;
    console.log("deselected")
  }
  deselectdealer()
{
  this.selected  = null;
  this.selected1 = null;
  this.selected2 = null;
  this.check = false;
  this.check3 = false;
  this.check2 = false;
  this.check4 = false;

}
closemodal() {
  document.getElementById('myNav').style.width = '0';
}
}
