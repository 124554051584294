export class LoggedUser {
    constructor(
      // role
        public partyType: string,
        // dcorg
        public userRoledcorg: string,
        // dcorg
        public partyIDdcorg: string,

        public orgtypeid:string,
        public orgtypename:string,
        public email:string,
        public name:string,
        public sitecode:string,
        ) {}

}