import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService extends BaseService{

  constructor(private http:HttpClient) {
    super()
    }
  getOrganisationList():any{
    let url= "/misc/orgs";
    return this.http.get(url)
  }
  getUserTypeByOrgId(obj:any):any{
    let url = this.baseUrl+'misc/orgs/users'
    return this.http.get(url,{params:obj})
  }
  loginClick(obj:any){
    let url= this.baseUrl +'auth/login';
    return this.http.post(url,obj)
  }
}
