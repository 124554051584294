import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouteConfigLoadEnd, Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { ThemeService } from 'src/app/themes/theme.service';

@Component({
  selector: 'app-buyerinfo',
  templateUrl: './buyerinfo.component.html',
  styleUrls: ['./buyerinfo.component.scss']
})
export class BuyerinfoComponent implements OnInit {
  modaldata = {
    podate: null, poaccepteddate: null, salesorderno: null, asndetails: null, poacceptance: false,
    document: null, orderacceptance: null, asncompletedetails: null, asnnumber: null, awbnumber: null, ds: null, ps: null
  };
  columnDefs
  defaultColDef;
  columnDefs1;
  columnDefs2;
  pagination = true;
  paginationPageSize;
  rowData: any = [];
  shippingData: any = [];
  store: any = [];
  poType;
  userId;
  status1;
  domLayout: any;
  rowSelection;
  autoGroupColumnDef;
  maxBlocksInCache;
  sideBar;
  userType = "BUYER";
  isDarkTheme;
  detailCellRendererParams;
  getRowHeight;
  loggedUser
  orgType
  rounded;
  constructor(private admin: AdminService, private router: Router, private spinner: NgxSpinnerService, private activatedRoute: ActivatedRoute, private themeService: ThemeService) {
    this.domLayout = 'autoHeight';

    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.rowSelection = 'single'

    this.autoGroupColumnDef = {
      icons: {
        menu: '<em class="fa fa-shower"/>',
        filter: '<em class="fa fa-long-arrow-alt-up"/>',
        columns: '<em class="fa fa-snowflake"/>',
        sortAscending: '<em class="fa fa-sort-alpha-up"/>',
        sortDescending: '<em class="fa fa-sort-alpha-down"/>',
      },
      headerCheckboxSelection: true,
      width: 300,
    },
      this.paginationPageSize = 10;
    this.maxBlocksInCache = 1;
    this.pagination = true;
    this.sideBar = {
      toolPanels: [

        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },


        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },


      ],

    };
  }

  ngOnInit(): void {
    if (JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'mh1-buyer@rvbl.com') {
      this.loggedUser = 'MH1 BUYER'
    } else if (JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'mh2-buyer@rvbl.com') {
      this.loggedUser = 'MH2 BUYER'
    } else if (JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'buyer@rse.rvbl.com') {
      this.loggedUser = 'BUYER'
    } else if (JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'cm1-buyer@rvbl.com') {
      this.loggedUser = 'TIER1 SUPPLIER1 BUYER'
    }
    this.orgType = sessionStorage.getItem("orgName")
    this.poType = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).userRoledcorg
    this.userId = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email
    this.status1 = this.activatedRoute.snapshot.queryParamMap.get('status');
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;

    });
    // if (this.status1 === 'allorders') {
    this.allorders();
    // }
    // else if(this.status1 === 'shipmentsArrivingToday'){

    // }
  }
  shouldHide() {
    if (this.status1 === 'allorders' || this.status1 === 'shipmentsToBeNotified' || this.status1 === 'shipmentsMissedDueDate') {
      return false
    }
    else {
      return true;
    }
  }
  shouldHide1() {
    if (this.status1 === 'shipmentsMissedDueDate') {
      return true
    } else {
      return false
    }
  }
  allorders() {
    this.columnDefs = [

      {
        headerName: 'CUSTOMER SITE',
        field: 'customerSiteName',
        filter: 'agSetColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'CUSTOMER SITE',
        tooltipField: 'customerSiteName',

      },
      {
        headerName: 'CUSTOMER NAME',
        field: 'customerName',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'CUSTOMER NAME',
        tooltipField: 'customerName',
        hide: this.shouldHide1()
      },
      {
        headerName: 'PO DATE', field: 'poDate', filter: true, headerTooltip: 'PO DATE',
        tooltipField: 'poDate',
      },
      {
        headerName: 'PO #', field: 'poNumber', filter: true, headerTooltip: 'PO #',
        tooltipField: 'poNumber',
        hide: this.shouldHide()
      },
      {
        headerName: 'DELIVERY SITE', field: 'deliverySite', filter: true, headerTooltip: 'DELIVERY SITE', hide: this.shouldHide(),
        tooltipField: 'deliverySite',
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value === undefined) {
            return 'DPLN'
          }

        },
      },
      {
        headerName: 'PO VERSION #', field: 'poCurrentVersion', filter: true, headerTooltip: 'PO VERSION #',
        tooltipField: 'poCurrentVersion',
        hide: this.shouldHide1()
      },
      {
        headerName: 'STATUS', field: 'poStatus', filter: true, headerTooltip: 'STATUS', hide: this.shouldHide(),
        tooltipField: 'poStatus',
        onCellClicked: this.poStatus.bind(this),
        cellRenderer: (params: ICellRendererParams) => {
          return `<strong><a class="text-center d-flex" >${params.value} </a></strong>`;

        },
      },
    ];
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: 'LINE #', field: 'lineno',
            headerTooltip: 'LINE #',
            tooltipField: 'lineno',
            // onCellClicked: this.popupopen.bind(this),
            // cellRenderer: (params: ICellRendererParams) => {
            //   return `<strong><a class="text-center d-flex" >${params.value} </a></strong>`;

            // },
          },
          {
            headerName: 'LINE VERSION #', field: 'lineVersion',
            headerTooltip: 'LINE VERSION #',
            tooltipField: 'lineVersion',

          },
          {
            headerName: 'SHIPMENT #',
            field: 'shipmentNumber',
            filter: 'agSetColumnFilter',

            chartDataType: 'category',
            headerTooltip: 'SHIPMENT #',
            tooltipField: 'shipmentNumber',

          },
          {
            headerName: 'ITEM NAME', field: 'itemName',
            headerTooltip: 'ITEM NAME',
            tooltipField: 'itemName',

          },
          {
            headerName: 'QUANTITY', field: 'quantity', filter: true, headerTooltip: 'QUANTITY',
            tooltipField: 'quantity',
          },
          {
            headerName: 'UOM', field: 'primaryUom',
            headerTooltip: 'UOM',
            tooltipField: 'primaryUom',

          },
          {
            headerName: 'NEED BY DATE', field: 'needByDate', filter: true, headerTooltip: 'NEED BY DATE',
            tooltipField: 'needByDate',

          },
          {
            headerName: 'EXPECTED DELIVERY DATE', field: 'expectedDate', filter: true, headerTooltip: 'EXPECTED DELIVERY DATE',
            tooltipField: 'expectedDate',
            // cellRenderer: (params: ICellRendererParams) => {
            //   if (params.value === undefined) {
            //     let mathValue = Math.random() * 10;
            //     this.rounded = Math.round(mathValue);
            //     console.log(params.data.needByDate, this.rounded, "rounded")

            //     let expectd = moment(params.data.needByDate, 'DD-MM-YYYY').add(this.rounded, 'days').format('DD-MM-YYYY')

            //     return expectd
            //   }
            // }
          },
          {
            headerName: 'DELAY (IN DAYS)', field: 'days', filter: true, headerTooltip: 'DELAY (IN DAYS)',
            tooltipField: 'days',
            // cellRenderer: (params: ICellRendererParams) => {
            //   if (params.value === undefined) {
            //     console.log(this.rounded, "ro")
            //     return this.rounded
            //   }
            // }
          },
          {
            headerName: 'DELIVERY SITE', field: 'shiptoLocation', filter: true, headerTooltip: 'DELIVERY SITE',
            tooltipField: 'shiptoLocation',
          },
          {
            headerName: 'CANCELLATION STATUS', field: 'cs',
            headerTooltip: 'CANCELLATION STATUS',
            tooltipField: 'cs',
            cellRenderer: (params: ICellRendererParams) => {
              if (params.data.cs === undefined) {
                return 'No'
              }
            }

          },

        ],

        defaultColDef: {
          flex: 1, searchable: false, floatingFilter: false, sortable: true, resizable: true, enableRangeSelection: true, popupParent: document.body
        },
        paginationPageSize: 5,
        pagination: true
      },

      getDetailRowData(params) {
        params.data.dummydata = []
        let dummydata = [];
        let rounded:any
        let expectd;
        for (let i = 0; i < params.data.lineItems.length; i++) {
          for (let j = 0; j < params.data.lineItems[i].shipping.length; j++) {
            if(params.data.lineItems[i].shipping[j] != null){
              if(params.data.lineItems[i].shipping[j].expectedDate === undefined){
                let mathValue= Math.random()*10;
                  rounded = Math.round(mathValue);
                  console.log(rounded,"rounded")
                  
                 expectd = moment(params.data.lineItems[i].shipping[j].needByDate,'DD-MM-YYYY').add(rounded,'days').format('DD-MM-YYYY')
                
              }
            }
            
            // if (k === 0) {
            params.data.dummydata.push(
              {
                "lineno": params.data.lineItems[i].lineno,
                "lineVersion": params.data.lineItems[i].lineVersion,
                "shipmentNumber": params.data.lineItems[i].shipping[j] != null ? params.data.lineItems[i].shipping[j].shipmentNumber : 'NA',
                "itemName": params.data.lineItems[i].shipping[j] != null ? params.data.lineItems[i].shipping[j].itemName : 'NA',
                "quantity": params.data.lineItems[i].shipping[j] != null ? params.data.lineItems[i].shipping[j].quantity : 'NA',
                "primaryUom": params.data.lineItems[i].shipping[j] != null ? params.data.lineItems[i].shipping[j].primaryUom : 'NA',
                "needByDate": params.data.lineItems[i].shipping[j] != null ? params.data.lineItems[i].shipping[j].needByDate : 'NA',
                "shiptoLocation": params.data.lineItems[i].shipping[j] != null ? params.data.lineItems[i].shipping[j].shiptoLocation : 'NA',
                "expectedDate":params.data.lineItems[i].shipping[j] != null ? expectd : 'NA',
                "days":params.data.lineItems[i].shipping[j] != null ? rounded : 'NA'
              })
            // }
            console.log(params.data.dummydata, "dummy data")
            // k++
          }
        }

        params.successCallback(params.data.dummydata);

      },

    };

    this.getRowHeight = params => {
      const defRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
      if (params.node.detail) {
        const offset = 100;
        const detailRowHeight =
          params.data.lineItems.length * defRowHeight;
        var gridSizes = params.api.getSizesForCurrentTheme();
        return detailRowHeight + gridSizes.headerHeight + offset;
      } else {
        return defRowHeight;
      }
    }
    if (this.status1 === 'allorders') {
      this.spinner.show()
      this.admin.BuyerallShipmentsCount(this.poType, this.userId, this.userType).subscribe((res: any) => {
        this.spinner.hide()

        this.rowData = res.data
        console.log(this.rowData, "row data")
      })
    }
    else if (this.status1 === 'shipmentsArrivingToday') {
      this.spinner.show()
      this.admin.BuyershipmentsArrivingToday(this.poType, this.userId, this.userType).subscribe((res: any) => {
        this.spinner.hide()
        this.rowData = res.data;
      })
    }
    else if (this.status1 === 'shipmentsToBeNotified') {
      this.spinner.show()
      this.admin.BuyershipmentsToBeNotified(this.poType, this.userId, this.userType).subscribe((res: any) => {
        this.spinner.hide()
        this.rowData = res.data;
      })
    }
    else if (this.status1 === 'shipmentsInTransit') {
      this.spinner.show()
      this.admin.BuyershipmentIntransit(this.poType, this.userId, this.userType).subscribe((res: any) => {
        this.spinner.hide()
        this.rowData = res.data;
      })
    }
    else if (this.status1 === 'shipmentsMissedDueDate') {
      this.spinner.show()
      this.admin.BuyershipmentsMissedDueDate(this.poType, this.userId, this.userType).subscribe((res: any) => {
        this.spinner.hide()

        this.rowData = res.data;
        console.log(this.rowData, "rowdata")
      })
    }
  }
  popupopen(data) {
    console.log(data.data.shipping, "data")
    this.shippingData = data.data.shipping;
    this.columnDefs1 = [
      {
        headerName: 'SHIPMENT #',
        field: 'shipmentNumber',
        filter: 'agSetColumnFilter',

        chartDataType: 'category',
        headerTooltip: 'SHIPMENT #',
        tooltipField: 'shipmentNumber',

      },
      {
        headerName: 'ITEM NAME',
        field: 'itemName',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'ITEM NAME',
        tooltipField: 'itemName',

      },
      {
        headerName: 'QUANTITY', field: 'quantity', filter: true, headerTooltip: 'QUANTITY',
        tooltipField: 'quantity',
      },
      {
        headerName: 'CANCELLATION STATUS', field: 'cs', filter: true, headerTooltip: 'CANCELLATION STATUS',
        tooltipField: 'cs',
        cellRenderer: (params: ICellRendererParams) => {
          if (params.data.cs === undefined) {
            return 'No'
          }
        }
      },
      {
        headerName: 'SHIPMENT VERSION #', field: 'shipmentVersion', filter: true, headerTooltip: 'SHIPMENT VERSION #',
        tooltipField: 'shipmentVersion',
      },
      {
        headerName: 'NEED BY DATE', field: 'needByDate', filter: true, headerTooltip: 'NEED BY DATE',
        tooltipField: 'needByDate',

      },
      {
        headerName: 'EXPECTED DELIVERY DATE', field: 'expectedDate', filter: true, headerTooltip: 'EXPECTED DELIVERY DATE',
        tooltipField: 'expectedDate',
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value === undefined) {
            let mathValue = Math.random() * 10;
            this.rounded = Math.round(mathValue);
            console.log(params.data.needByDate, this.rounded, "rounded")

            let expectd = moment(params.data.needByDate, 'DD-MM-YYYY').add(this.rounded, 'days').format('DD-MM-YYYY')

            return expectd
          }
        }
      },
      {
        headerName: 'DELAY (IN DAYS)', field: 'days', filter: true, headerTooltip: 'DELAY (IN DAYS)',
        tooltipField: 'days',
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value === undefined) {
            console.log(this.rounded, "ro")
            return this.rounded
          }
        }
      },
      {
        headerName: 'DELIVERY SITE', field: 'shiptoLocation', filter: true, headerTooltip: 'DELIVERY SITE',
        tooltipField: 'shiptoLocation',
      },
    ];

    document.getElementById('myNav').style.width = '80%';
  }
  closemodal() {
    document.getElementById('myNav').style.width = '0';
  }
  closemodal1() {
    document.getElementById('myNav1').style.width = '0';
  }
  poStatus(data) {
    console.log(data.data, "datat")
    if (data.data.poAcceptance === true) {
      this.modaldata.podate = data.data.poDate;
      this.modaldata.poaccepteddate = data.data.poAccepetdDate;
      this.modaldata.salesorderno = data.data.salesOrderNumber;
    }
    this.columnDefs2 = [
      {
        headerName: 'S.NO',
        field: 'sno',
        filter: 'agSetColumnFilter',

        chartDataType: 'category',
        headerTooltip: 'S.NO',
        tooltipField: 'sno',

      },
      {
        headerName: 'ITEM NAME',
        field: 'itemname',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'ITEM NAME',
        tooltipField: 'itemname',

      },
      {
        headerName: 'AWB #', field: 'awbno', filter: true, headerTooltip: 'AWB #',
        tooltipField: 'awbno',
      },

      {
        headerName: 'ASN #', field: 'asnno', filter: true, headerTooltip: 'ASN #',
        tooltipField: 'asnno',
      },
      {
        headerName: 'PICKED UP DATE', field: 'pickupdate', filter: true, headerTooltip: 'PICKED UP DATE',
        tooltipField: 'pickupdate',
      },
      {
        headerName: 'STATUS', field: 'ds', filter: true, headerTooltip: 'STATUS',
        tooltipField: 'ds',
      },
      {
        headerName: 'DELIVERED DATE', field: 'delivereddate', filter: true, headerTooltip: 'DELIVERED DATE',
        tooltipField: 'delivereddate',
      },

    ];
    let store: any = [];
    for (var i = 0; i < data.data.lineItems.length; i++) {
      for (var j = 0; j < data.data.lineItems[i].shipping.length; j++) {

        if (data.data.lineItems[i].shipping != null) {

          store.push({
            'sno': j + 1,
            'itemname': data.data.lineItems[i].shipping[j] != null ? data.data.lineItems[i].shipping[j].itemName : 'NA',
            'awbno': data.data.lineItems[i].shipping[j] != null ? data.data.lineItems[i].shipping[j].awbno : 'NA',
            'asnno': 'NA',
            'ps': 'NA',
            'pickupdate': 'NA',
            'ds': 'NA',
            'delivereddate': 'NA'
          });
          console.log(this.store, "print");
        }
      }

    }
    this.store = store
    document.getElementById('myNav1').style.width = '80%';
  }
  back() {
    this.router.navigate([''])
  }
}
