export enum Role {
    RSEADMIN = 'RSEADMIN',
    RSEBUYER='Rsebuyer',
    CONTRACTORMANFADMIN = 'CONTRACTORMANFADMIN',
    CONTRACTORMANFSALES='CONTRACTORMANFSALES',
    CONTRACTORMANFBUYER='CONTRACTORMANFBUYER',
    CONTRACTORMANFFULL='CONTRACTORMANFFULL',

    DEALERADMIN = 'DEALERADMIN',
    DEALERBUYER='Dealerbuyer',
 
   
  

MANFACTURINGHUBADMIN = 'MANFACTURINGHUBADMIN',
MHBUYER='MHBUYER',
MHSALES='MHSALES',
MHFULL='MHFULL',

SUPPLIERSALES='SUPPLIERSALES',
SUPPLIERADMIN = 'SUPPLIERADMIN',
SUPPLIERFULL='SUPPLIERFULL',
// pendingroles
// TPWWAREHOUSE='TPWAREHOUSE',
// LOGISTICPLANNER='LOGISTICPLANNER'
// TPWADMIN = 'TPWADMIN',
// LOGISTICSADMIN = 'LOGISTICSADMIN',
// ADMIN = 'Admin',

}
