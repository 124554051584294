<div class="container">
    <div class="row justify-content-center mt-4">
        <div class="col">
            <div class="row">
                
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                           <div class="col">
                            <span>SHIPMENTS</span>
                            <h2>ARRIVING TODAY</h2>
                           </div>
                           <!-- <div class="col-2 icon">
                            <img src="../../../assets/images/All Shipments.png" class="user-image" height="45" alt="User Image">
                        </div> -->
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                    {{arrivingtoday}}
                                </h1>
                            </div>

                        </div>
                        <a routerLink="sales-info" [queryParams]="{'status': 'shipmentsArrivingToday'}" class="small-box-footer"  *ngIf="arrivingtoday != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                           <div class="col">
                            <span>SHIPMENTS</span>
                            <h2>MISSING DUE DATE</h2>
                           </div>
                            <!-- <div class="col-2 icon">
                                <img src="../../../assets/images/All Shipments.png" class="user-image" height="45" alt="User Image">
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                   {{shipmentsmissedduedate}}
                                </h1>
                            </div>

                        </div>
                        <a routerLink="sales-info" [queryParams]="{'status': 'missingduedate'}" class="small-box-footer" *ngIf="shipmentsmissedduedate != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                            <div class="col">
                                <span>SHIPMENTS</span>
                                <h2>IN TRANSIT</h2>
                            </div>
                            <!-- <div class="col-2 icon">
                                <img src="../../../assets/images/All Shipments.png" class="user-image" height="45" alt="User Image">
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info  col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                    {{shipmentsintransit}}
                                </h1>
                            </div>

                        </div>
                        <a routerLink="sales-info" [queryParams]="{'status': 'shipmentsInTransit'}" class="small-box-footer" *ngIf="shipmentsintransit != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                            <div class="col">
                                <span>ALL</span>
                                <h2>PURCHASE ORDERS</h2>
                            </div>
                            <!-- <div class="col-2 icon">
                                <img src="../../../assets/images/All Shipments.png" class="user-image" height="45" alt="User Image">
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info  col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                    {{allorders}}
                                </h1>
                            </div>

                        </div>
                        <a routerLink="sales-info" [queryParams]="{'status': 'allorders'}" class="small-box-footer" *ngIf="allorders != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
            </div>
            <div class="row mt-3">
                
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                            <div class="col">
                                <span>SHIPMENTS</span>
                                <h2>TO BE NOTIFIED</h2>
                            </div>
                            <!-- <div class="col-2 icon">
                                <img src="../../../assets/images/All Shipments.png" class="user-image" height="45" alt="User Image">
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info  col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                   {{tobenotified}}
                                </h1>
                            </div>
 
                        </div>
                        <a routerLink="sales-info" [queryParams]="{'status': 'shipmentsToBeNotified'}" class="small-box-footer" *ngIf="tobenotified != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                            <div class="col">
                                <span>SHIPMENTS</span>
                                <h2>POS NOT ACKNOWLEDGED</h2>
                            </div>
                            <!-- <div class="col-2 icon">
                                <img src="../../../assets/images/All Shipments.png" class="user-image" height="45" alt="User Image">
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info  col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                   {{posnotacknowledeged}}
                                </h1>
                            </div>
 
                        </div>
                        <a routerLink="sales-info" [queryParams]="{'status': 'posNotAcknowledged'}" class="small-box-footer" *ngIf="posnotacknowledeged != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
                <div class="col">
                    <div class="card align-items-left card-shadow" style="height: 150px;">
                        <div class="dashboard-info row">
                            <div class="col">
                                <span>SHIPMENTS</span>
                                <h2>ALL INWARDS PO'S</h2>
                            </div>
                            <!-- <div class="col-2 icon">
                                <img src="../../../assets/images/All Shipments.png" class="user-image" height="45" alt="User Image">
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="dashboard-count-info  col">

                                <h1 style="font-size: 35px; font-weight: bold;">
                                   {{allinwardspo}}
                                </h1>
                            </div>
 
                        </div>
                        <a routerLink="sales-info" [queryParams]="{'status': 'allInwardPOs'}" class="small-box-footer" *ngIf="allinwardspo != undefined">More
                            info <i class="fa fa-arrow-circle-right"></i></a>

                    </div>
                </div>
            </div>
            <div class="mt-4  order_performance">
               <div class="d-flex">
                   <h3 class="order">ORDER PERFORMANCE</h3>
               </div>
               <div class="col">
                <div id="main" style="width: auto;height: 300px;" class="mt-3" ></div>
               </div>
            </div>
        </div>
    </div>
</div> 
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale"
    [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>
