import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from 'src/app/themes/theme.service';
import Swal from 'sweetalert2';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';

@Component({
  selector: 'app-mh-sales-notification',
  templateUrl: './mh-sales-notification.component.html',
  styleUrls: ['./mh-sales-notification.component.scss']
})
export class MhSalesNotificationComponent implements OnInit {
  columnDefs
  defaultColDef;
  columnDefs1;
  pagination = true;
  paginationPageSize;
  rowData: any = [];
  shippingData: any = [];
  poType;
  userId;
  status1;
  domLayout: any;
  rowSelection;
  autoGroupColumnDef;
  maxBlocksInCache;
  sideBar;
  isDarkTheme;
  detailCellRendererParams;
  getRowHeight;
  user: any;
  orgName;
  frameworkComponents: any;
  loggedUser;
  orgType
  constructor(private spinner: NgxSpinnerService, private admin: AdminService, private themeService: ThemeService, private authService: AuthService) {
    this.domLayout = 'autoHeight';

    this.defaultColDef = {
      flex: 1, floatingFilter: true, searchable: true, resizable: true, sortable: true, filter: true, domLayout: "autoHeight"
    };
    this.rowSelection = 'single'

    this.autoGroupColumnDef = {
      icons: {
        menu: '<em class="fa fa-shower"/>',
        filter: '<em class="fa fa-long-arrow-alt-up"/>',
        columns: '<em class="fa fa-snowflake"/>',
        sortAscending: '<em class="fa fa-sort-alpha-up"/>',
        sortDescending: '<em class="fa fa-sort-alpha-down"/>',
      },
      headerCheckboxSelection: true,
      width: 300,
    },
      this.paginationPageSize = 10;
    this.maxBlocksInCache = 1;
    this.pagination = true;
    this.frameworkComponents = {
      buttonRenderer: CustomTooltipComponent,
    }
    this.sideBar = {
      toolPanels: [

        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },


        },
        {
          id: 'filters',
          labelKey: 'filters',
          labelDefault: 'Filters',
          iconKey: 'menu',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          }
        },


      ],

    };
    this.columnDefs = [
      {
        headerName: 'CUSTOMER NAME',
        field: 'customerName',
        filter: 'agSetColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'CUSTOMER NAME',
        tooltipField: 'customerName',

      },
      {
        headerName: 'CUSTOMER SITE',
        field: 'customerSiteName',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'CUSTOMER SITE',
        tooltipField: 'customerSiteName',

      },
      {
        headerName: 'PO DATE', field: 'poDate', filter: true, headerTooltip: 'PO DATE',
        tooltipField: 'poDate',
      },
      {
        headerName: 'PO #', field: 'poNumber', filter: true, headerTooltip: 'PO #',
        tooltipField: 'poNumber',
      },
      {
        headerName: 'PO VERSION #', field: 'poCurrentVersion', filter: true, headerTooltip: 'PO VERSION #',
        tooltipField: 'poCurrentVersion',
      },
      {
        headerName: 'ACCEPT', field: '', tooltipField: 'ACCEPT', cellStyle: { textAlign: 'center' },
        onCellClicked: this.onAccept.bind(this),
        cellRenderer: (params: ICellRendererParams) => {
          return `<button style="background: #007009;border: 0px;color: #fff;text-align: center;padding: 0px 10px;font-weight: bold;color: var(--button-text-color);">ACCEPT</button>`
        },
      },
      {
        headerName: 'REJECT', field: '', tooltipField: 'REJECT',
        onCellClicked: this.onReject.bind(this),
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params: ICellRendererParams) => {
          return `<button style="background:#cf0a0a;border: 0px;color: #fff;text-align: center;padding: 0px 10px;font-weight: bold;color: var(--button-text-color);">REJECT</button>`;
        },
      },
      // {
      //   headerName: 'ACTION',field:'',tooltipField:'ACTION',
      //   cellRenderer: 'buttonRenderer',
      // cellRenderer: (params: ICellRendererParams) => {
      //   return `<button class="btn btn-primary" (onclick)="onAccept(params)">ACCEPT</button> <button class="btn btn-primary" (click)="onReject()">REJECT</button>`;

      // },
      // }
    ];
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: 'LINE #', field: 'lineno',
            headerTooltip: 'LINE #',
            tooltipField: 'lineno',
            onCellClicked: this.popupopen.bind(this),
            cellRenderer: (params: ICellRendererParams) => {
              return `<strong><a class="text-center d-flex" >${params.value} </a></strong>`;

            },
          },
          // {
          //   headerName: 'ITEM NAME', field: 'itemName',
          //   headerTooltip: 'ITEM NAME',
          //   tooltipField: 'itemName',

          // },
          {
            headerName: 'UOM', field: 'primaryUom',
            headerTooltip: 'UOM',
            tooltipField: 'primaryUom',

          },
          // {
          //   headerName: 'CANCELLATION STATUS', field: 'cs',
          //   headerTooltip: 'CANCELLATION STATUS',
          //   tooltipField: 'cs',
          //   cellRenderer: (params: ICellRendererParams) => {
          //     if (params.data.cs === undefined) {
          //       return 'No'
          //     }
          //   }

          // },
          {
            headerName: 'LINE VERSION #', field: 'lineVersion',
            headerTooltip: 'LINE VERSION #',
            tooltipField: 'lineVersion',

          },

        ],

        defaultColDef: {
          flex: 1, searchable: false, floatingFilter: false, sortable: true, resizable: true, enableRangeSelection: true, popupParent: document.body
        },

      },

      getDetailRowData(params) {
        params.successCallback(params.data.lineItems);

      },

    };
    this.getRowHeight = params => {
      const defRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
      if (params.node.detail) {
        const offset = 100;
        const detailRowHeight =
          params.data.lineItems.length * defRowHeight;
        var gridSizes = params.api.getSizesForCurrentTheme();
        return detailRowHeight + gridSizes.headerHeight + offset;
      } else {
        return defRowHeight;
      }
    }
  }

  ngOnInit(): void {
    this.user = this.authService.getLoginDetails();
    this.orgName = this.user.userRoledcorg
    this.themeService.themeChanged.subscribe((res: any) => {
      this.isDarkTheme = res;
    });
    this.onLoad();
    if(JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'cm1-sales@rvbl.com'){
      this.loggedUser = 'TIER1 SUPPLIER1 SALES'
    }else if(JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'cm2-sales@rvbl.com'){
      this.loggedUser = 'TIER1 SUPPLIER2 SALES'
    }else if(JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'supp2-sales@rvbl.com'){
      this.loggedUser = 'TIER2 SUPPLIER2 SALES'
    }else if(JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).email === 'supp1-sales@rvbl.com'){
      this.loggedUser = 'TIER2 SUPPLIER1 SALES'
    }
    else{
      this.loggedUser = JSON.parse(sessionStorage.getItem("_UserLoggedDatascc")).name
    }
    this.orgType = sessionStorage.getItem("orgName")
  }
  onLoad() {
    if (this.user.partyType === 'CONTRACTORMANFSALES') {
      this.rowData = []
      this.spinner.show()
      this.admin.CMSalesNotificationTable('false', this.orgName).subscribe((res: any) => {
        this.spinner.hide();
        if (res.status === 'Success') {
          this.rowData = res.data
        }
        else {
          if (this.isDarkTheme === 'light') {
            Swal.fire({
              title: 'ERROR',
              text: res.message,
              icon: 'error'
            })
          } else if (this.isDarkTheme === 'dark') {
            Swal.fire({

              heightAuto: false,
              icon: 'error',
              html: "<p style='color:#fff'>" + res.message + "</p>",
              width: 500,
              background: '#333',
              iconColor: '#fff',
              confirmButtonColor: '#272727',




            });
          }

        }
      })
    }
    else if (this.user.partyType === 'MHSALES') {
      this.rowData = []
      this.spinner.show()
      this.admin.MHSalesNotificationTable('false', this.orgName).subscribe((res: any) => {
        this.spinner.hide();
        if (res.status === 'Success') {
          this.rowData = res.data
        }
        else {

          if (this.isDarkTheme === 'light') {
            Swal.fire({
              title: 'ERROR',
              text: res.message,
              icon: 'error'
            })
          } else if (this.isDarkTheme === 'dark') {
            Swal.fire({

              heightAuto: false,
              icon: 'error',
              html: "<p style='color:#fff'>" + res.message + "</p>",
              width: 500,
              background: '#333',
              iconColor: '#fff',
              confirmButtonColor: '#272727',
            });
          }
        }
      })
    }
    else if (this.user.partyType === 'SUPPLIERSALES') {
      this.rowData = []
      this.spinner.show()
      this.admin.SuppSalesNotificationTable('false', this.orgName).subscribe((res: any) => {
        this.spinner.hide();
        if (res.status === 'Success') {
          this.rowData = res.data
        }
        else {

          if (this.isDarkTheme === 'light') {
            Swal.fire({
              title: 'ERROR',
              text: res.message,
              icon: 'error'
            })
          } else if (this.isDarkTheme === 'dark') {
            Swal.fire({

              heightAuto: false,
              icon: 'error',
              html: "<p style='color:#fff'>" + res.message + "</p>",
              width: 500,
              background: '#333',
              iconColor: '#fff',
              confirmButtonColor: '#272727',
            });
          }
        }
      })
    }

  }
  onAccept(params: any) {
    console.log(this.isDarkTheme, "theme")
    if (this.isDarkTheme === 'light') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You Want to accept?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ACCEPT',
        cancelButtonText: 'CANCEL',
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.user.partyType === 'MHSALES') {
            this.spinner.show();
            this.admin.MHSalesAcceptReject('true', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
                
                if (this.isDarkTheme === 'light') {
                  Swal.fire({
                    title: 'Success',
                    html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                    icon: 'success'
                  })
                } else if (this.isDarkTheme === 'dark') {
                  Swal.fire({
      
                    heightAuto: false,
                    icon: 'success',
                    html: "<p style='color:#fff'>" + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID + "</p>",
                    width: 500,
                    background: '#333',
                    iconColor: '#fff',
                    confirmButtonColor: '#272727',
                  });
                }
                this.onLoad();
              }
              else {
                
                if (this.isDarkTheme === 'light') {
                  Swal.fire({
                    title: 'Error',
                    text: res.message,
                    icon: 'error'
                  })
                } else if (this.isDarkTheme === 'dark') {
                  Swal.fire({
      
                    heightAuto: false,
                    icon: 'error',
                    html: "<p style='color:#fff'>" + res.message + "</p>",
                    width: 500,
                    background: '#333',
                    iconColor: '#fff',
                    confirmButtonColor: '#272727',
                  });
                }
              }
            })
          }
          else if (this.user.partyType === 'CONTRACTORMANFSALES') {
            this.spinner.show();
            this.admin.CMSalesAcceptReject('true', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
               
                if (this.isDarkTheme === 'light') {
                  Swal.fire({
                    title: 'Success',
                    html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                    icon: 'success'
                  })

                } else if (this.isDarkTheme === 'dark') {
                  Swal.fire({
      
                    heightAuto: false,
                    icon: 'success',
                    html: "<p style='color:#fff'>" + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID+ "</p>",
                    width: 500,
                    background: '#333',
                    iconColor: '#fff',
                    confirmButtonColor: '#272727',
                  });
                }
                this.onLoad();
              }
              else {
                
                if (this.isDarkTheme === 'light') {
                  Swal.fire({
                    title: 'Error',
                    text: res.message,
                    icon: 'error'
                  })
                } else if (this.isDarkTheme === 'dark') {
                  Swal.fire({
      
                    heightAuto: false,
                    icon: 'error',
                    html: "<p style='color:#fff'>" + res.message + "</p>",
                    width: 500,
                    background: '#333',
                    iconColor: '#fff',
                    confirmButtonColor: '#272727',
                  });
                }
              }
            })
          }
          else if (this.user.partyType === 'SUPPLIERSALES') {
            this.spinner.show();
            this.admin.SuppSalesAcceptReject('true', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
               
                if (this.isDarkTheme === 'light') {
                  Swal.fire({
                    title: 'Success',
                    html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                    icon: 'success'
                  })
                } else if (this.isDarkTheme === 'dark') {
                  Swal.fire({
      
                    heightAuto: false,
                    icon: 'success',
                    html: "<p style='color:#fff'>" + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID + "</p>",
                    width: 500,
                    background: '#333',
                    iconColor: '#fff',
                    confirmButtonColor: '#272727',
                  });
                }
                this.onLoad();
              }
              else {
                
                if (this.isDarkTheme === 'light') {
                  Swal.fire({
                    title: 'Error',
                    text: res.message,
                    icon: 'error'
                  })
                } else if (this.isDarkTheme === 'dark') {
                  Swal.fire({
      
                    heightAuto: false,
                    icon: 'error',
                    html: "<p style='color:#fff'>" + res.message + "</p>",
                    width: 500,
                    background: '#333',
                    iconColor: '#fff',
                    confirmButtonColor: '#272727',
                  });
                }
              }
            })
          }
        } else {

        }
      })
    } else if (this.isDarkTheme === 'dark') {
      Swal.fire({
        title: '<h1 style="color:#fff">Are you sure?</h1>',
        html: "<p style='color:#fff'>You Want to Accept?</p>",
        heightAuto: false,
        icon: 'warning',
        showCancelButton: true,
        background: '#333',
        iconColor: '#fff',
        confirmButtonColor: '#272727',
        cancelButtonColor: '#272727',
        confirmButtonText: 'ACCEPT',
        cancelButtonText: 'CANCEL',
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.user.partyType === 'MHSALES') {
            this.spinner.show();
            this.admin.MHSalesAcceptReject('true', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
                Swal.fire({
                  title: 'Success',
                  html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                  icon: 'success',
                  background: '#333',
                  iconColor: '#fff',
                })
                this.onLoad();
              }
              else {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  icon: 'error',
                  background: '#333',
                  iconColor: '#fff',
                })
              }
            })
          }
          else if (this.user.partyType === 'CONTRACTORMANFSALES') {
            this.spinner.show();
            this.admin.CMSalesAcceptReject('true', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
                Swal.fire({
                  title: 'Success',
                  html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                  icon: 'success',
                  background: '#333',
                  iconColor: '#fff',
                })
                this.onLoad();
              }
              else {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  icon: 'error',
                  background: '#333',
                  iconColor: '#fff',
                })
              }
            })
          }
          else if (this.user.partyType === 'SUPPLIERSALES') {
            this.spinner.show();
            this.admin.SuppSalesAcceptReject('true', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
                Swal.fire({
                  title: 'Success',
                  html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                  icon: 'success',
                  background: '#333',
                  iconColor: '#fff',
                })
                this.onLoad();
              }
              else {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  icon: 'error',
                  background: '#333',
                  iconColor: '#fff',
                })
              }
            })
          }
        } else {

        }
      })
    }
  }
  onReject(params: any) {
    if (this.isDarkTheme === 'light') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You Want to Reject?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'REJECT',
        cancelButtonText: 'CANCEL',
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.user.partyType === 'MHSALES') {
            this.spinner.show()
            this.admin.MHSalesAcceptReject('false', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
                Swal.fire({
                  title: 'Success',
                  html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                  icon: 'success'
                })
                this.onLoad();
              }
              else {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  icon: 'error'
                })
              }
            })
          }
          else if (this.user.partyType === 'CONTRACTORMANFSALES') {
            this.spinner.show()
            this.admin.CMSalesAcceptReject('false', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
                Swal.fire({
                  title: 'Success',
                  html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                  icon: 'success'
                })
                this.onLoad();
              }
              else {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  icon: 'error'
                })
              }
            })
          }
          else if (this.user.partyType === 'SUPPLIERSALES') {
            this.spinner.show()
            this.admin.SuppSalesAcceptReject('false', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
                Swal.fire({
                  title: 'Success',
                  html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                  icon: 'success'
                })
                this.onLoad();
              }
              else {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  icon: 'error'
                })
              }
            })
          }
        }
        else {

        }
      })
    } else if (this.isDarkTheme === 'dark') {
      Swal.fire({
        title: '<h1 style="color:#fff">Are you sure?</h1>',
        html: "<p style='color:#fff'>You Want to Reject?</p>",
        heightAuto: false,
        icon: 'warning',
        showCancelButton: true,
        background: '#333',
        iconColor: '#fff',
        confirmButtonColor: '#272727',
        cancelButtonColor: '#272727',
        confirmButtonText: 'REJECT',
        cancelButtonText: 'CANCEL',
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.user.partyType === 'MHSALES') {
            this.spinner.show()
            this.admin.MHSalesAcceptReject('false', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
                Swal.fire({
                  title: 'Success',
                  html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                  icon: 'success',
                  background: '#333',
                  iconColor: '#fff',
                })
                this.onLoad();
              }
              else {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  icon: 'error',
                  background: '#333',
                  iconColor: '#fff',
                })
              }
            })
          }
          else if (this.user.partyType === 'CONTRACTORMANFSALES') {
            this.spinner.show()
            this.admin.CMSalesAcceptReject('false', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
                Swal.fire({
                  title: 'Success',
                  html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                  icon: 'success',
                  background: '#333',
                  iconColor: '#fff',
                })
                this.onLoad();
              }
              else {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  icon: 'error',
                  background: '#333',
                  iconColor: '#fff',
                })
              }
            })
          }
          else if (this.user.partyType === 'SUPPLIERSALES') {
            this.spinner.show()
            this.admin.SuppSalesAcceptReject('false', this.user.email, this.user.sitecode, this.orgName, params.data.poRefNumber).subscribe((res: any) => {
              this.spinner.hide();
              if (res.status === 'Success') {
                Swal.fire({
                  title: 'Success',
                  html: '<strong>' + res.data.message + '</strong></br><b>TRXID:</b>' + res.data.transactionID,
                  icon: 'success',
                  background: '#333',
                  iconColor: '#fff',
                })
                this.onLoad();
              }
              else {
                Swal.fire({
                  title: 'Error',
                  text: res.message,
                  icon: 'error',
                  background: '#333',
                  iconColor: '#fff',
                })
              }
            })
          }
        } else {

        }
      })
    }
  }
  popupopen(data) {
    console.log(data.data.shipping, "data")
    this.shippingData = data.data.shipping;
    this.columnDefs1 = [
      {
        headerName: 'SHIPMENT #',
        field: 'shipmentNumber',
        filter: 'agSetColumnFilter',

        chartDataType: 'category',
        headerTooltip: 'SHIPMENT #',
        tooltipField: 'shipmentNumber',

      },
      {
        headerName: 'ITEM NAME',
        field: 'itemName',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'agGroupCellRenderer',
        chartDataType: 'category',
        headerTooltip: 'ITEM NAME',
        tooltipField: 'itemName',

      },
      {
        headerName: 'QUANTITY', field: 'quantity', filter: true, headerTooltip: 'QUANTITY',
        tooltipField: 'quantity',
      },
      // {
      //   headerName: 'CANCELLATION STATUS', field: 'cs', filter: true, headerTooltip: 'CANCELLATION STATUS',
      //   tooltipField: 'cs',
      //   cellRenderer: (params: ICellRendererParams) => {
      //     if (params.data.cs === undefined) {
      //       return 'No'
      //     }
      //   }
      // },
      {
        headerName: 'SHIPMENT VERSION #', field: 'shipmentVersion', filter: true, headerTooltip: 'SHIPMENT VERSION #',
        tooltipField: 'shipmentVersion',
      },
      {
        headerName: 'NEED BY DATE', field: 'needByDate', filter: true, headerTooltip: 'NEED BY DATE',
        tooltipField: 'needByDate',
      },
      {
        headerName: 'DELIVERY SITE', field: 'shiptoLocation', filter: true, headerTooltip: 'DELIVERY SITE',
        tooltipField: 'shiptoLocation',
      },
    ];
    document.getElementById('myNav').style.width = '80%';
  }
  closemodal() {
    document.getElementById('myNav').style.width = '0';
  }
}
