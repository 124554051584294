export class customerDetails {

    customer_code:any;
    customer_plant_code:any;
    shipment_code:any;
    document_number:any;
    document_date:any;
    ref_SDdocument:any;
    equipment_number:any;
    material_no:any;
    batch_no:any;
    serial_no:any;
    itemName:any;
    inspectionLotNo:any;
    objNo:any;
    warrStartDate:any;
    warrEndDate:any;
    serialCode:any;
    desp:any; 
  }