export class RsePoDetails {
fileUpload:any;
customer_name:any;
customer_site_name:any;
po_date:any;
po_num:any;
po_version_num:any;
line_no:any;
item_name:any;
primary_uom:any;
secondary_uom:any;
qunatity:any;
unit_price:any;
shipment_num:any;
cancel_status:any;
need_by_date:any;
ship_to_location:any;
mrp_requisition:any;
shipped_by_self:any;
certificateType:any;
certificateAuthority:any;
orgName:any;
fullfillmentEmpID:any;
poRefNumber:any;
shipmentRefNumber:any;
issuedOn:any;
reader:any;
tpmName:any;
requestPickDate:any;
reqStorageVolume:any;
reqStorageDays:any;
estimatedarrival:any
}
export class packageInfo{
    length:any;
    width:any;
    height:any
}
export class itemDimensions{
    length:any;
    width:any;
    height:any
}
export class addShipments {
    constructor(
        public poRefNumber: string,
        public lineno: string,
        public shipmentNumber: string,
        public orgID: string,
      

        ) {}
}
